import React, { useState, useEffect } from 'react';

import { useHistory, Link } from 'react-router-dom';
import UIKit from 'uikit';
import MDUIKit from 'md-uikit';
import { FormattedMessage } from 'react-intl';

//Components 
import CartItem from '../../components/cart-item';

// Context Actions
import CartActions from '../../providers/cart/cart.actions'

import settings from '../../settings.js';
import UIkit from 'uikit';
import TotalLabel from './components/TotalLabel';
import { Box, Typography } from '@material-ui/core';


const Cart = ({ modalCart }) => {
  const cart = CartActions();
  const history = useHistory();

  useEffect(() => {
    MDUIKit.components();
    console.log("Init Cart");
  }, [])

  const deleteAll = (event) => {
    event.preventDefault();
    cart.setList([]);
  }

  const onClickToCheckout = () => {
    UIkit.offcanvas(modalCart.current).hide();
    setTimeout(function () {
      history.push("/checkout");
    }, 500);
  }

  return (
    <div ref={modalCart} data-uk-offcanvas="flip: true; overlay: true">
      <div className="uk-offcanvas-bar md-bg-white uk-padding-small">
        <button className="uk-offcanvas-close" type="button" data-uk-close></button>
        <Box p={2} align="center">
          <Typography variant="h6" style={{ fontWeight: 700, color: "black" }}>
            <i className='material-icons md-color-grey-900'>shopping_cart</i>
            <FormattedMessage
              id="component_cart_title"
              defaultMessage=" Shopping Cart"
            />
          </Typography>
        </Box>
        <div className="uk-grid-small uk-margin-bottom uk-text-center" data-uk-grid>
          {cart.cartItems.length ? (
            <>
              {cart.cartItems.map((item, index) => (
                <CartItem item={item} key={index} />
              ))}
              <div className="uk-width-1-1 uk-text-center">
                <a onClick={deleteAll} className="md-btn md-btn-icon md-btn-outlined md-btn-primary ripple-surface ripple-surface--primary">
                  <i className="material-icons">delete</i>
                  <FormattedMessage
                    id="component_button_delete"
                    defaultMessage="Remove all"
                  /></a>
              </div>
            </>
          ) : (
            <div className="uk-width-1-1 uk-padding uk-text-center">
              <h5 className='md-color-grey-900'>
                <FormattedMessage
                  id="component_no_products"
                  defaultMessage="No products added"
                />
              </h5>
            </div>
          )
          }
          {/* <div className="uk-width-1-1">
            <a className="md-btn md-btn-outlined md-btn-primary md-btn-wave-light uk-margin-right">Guardar como lista <i className="material-icons">add</i></a>
          </div> */}

          <div>&nbsp;</div>

          <div className="uk-width-1-1 cart-footer">
            <div className="checkout">

              <div className="uk-grid-small" data-uk-grid >
                <div className='uk-width-2-5 uk-text-left'>
                  <small className="md-color-blue-grey-900">Total:</small>
                  <TotalLabel />
                </div>
                <div className='uk-width-3-5 uk-text-right'>
                  {cart.cartItems.length ?
                    <button onClick={onClickToCheckout} className="md-btn md-btn-primary ripple-surface">Checkout <i className="material-icons">keyboard_arrow_right</i></button>
                    :
                    <a className="md-btn disabled" disabled>Checkout <i className="material-icons">keyboard_arrow_right</i></a>
                  }
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Cart;