
import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import { Box, Divider, Drawer, Hidden, Icon, List, ListItem, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, Typography, Button } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    iconMenu: {
        marginRight: theme.spacing(1),
    },
}));

const OrderByButton = ({ menu, sortBy, dispatchFromSort, icon = "more_vert", size = "medium", title = false }) => {

    const classes = useStyles();

    const [showDrawer, setShowDrawer] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleMenuClose = (value, type) => () => {
        //if (action) { action(item); }
        dispatchFromSort(value);
        if (type == "menu")
            setAnchorEl(null);
        if (type == "drawer")
            setShowDrawer(false)
    };

    return (
        <>
            <Hidden smDown>
                {/* <IconButton size={size} aria-label="more options" onClick={handleProfileMenuOpen}>
                    <Icon>{icon}</Icon>
                </IconButton> */}
                <Button
                    variant="text"
                    color="primary"
                    startIcon={<Icon>sort</Icon>}
                    onClick={handleProfileMenuOpen}
                >
                    Ordenar por
                </Button>
            </Hidden>
            <Hidden mdUp>
                <IconButton size={size} aria-label="more options" onClick={() => setShowDrawer(true)}>
                    <Icon>sort</Icon>
                </IconButton>
            </Hidden>
            <Menu
                anchorEl={anchorEl}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                keepMounted
                open={isMenuOpen}
                onClose={() => setAnchorEl(null)}
            >
                {menu.map((item, index) => (
                    <MenuItem onClick={handleMenuClose(item.value, "menu")} key={index}>
                        {item.value === sortBy ?
                            <Icon className={classes.iconMenu} fontSize="small">done</Icon>
                            : <Box width={20} mr={1}></Box>
                        }
                        <Typography variant="inherit">{item.title}</Typography>
                    </MenuItem>
                ))}
            </Menu>
            <Drawer anchor={'bottom'} open={showDrawer} onClose={() => setShowDrawer(false)}>
                {title &&
                    <Box p={1}>
                        <Typography variant="h6" color="initial">
                            {title}
                        </Typography>
                    </Box>
                }
                <List>
                    {menu.map((item, index) => (
                        <ListItem button key={index} onClick={handleMenuClose(item.value, "drawer")}>
                            {item.value === sortBy ?
                                <ListItemIcon><Icon>done</Icon></ListItemIcon>
                                : <ListItemIcon><Icon></Icon></ListItemIcon>
                            }
                            <ListItemText primary={item.title} />
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </>
    )
}

export default OrderByButton
