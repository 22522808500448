const INITIAL_STATE = {
  settings: [],
  loading: false,
  errorMessage: undefined
};

const webSettingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_START_WEBSETTINGS":
      return {
        ...state,
        loading: true
      };
    case "FETCH_SUCCESS_WEBSETTINGS":
      return {
        ...state,
        loading: false
      };
    case "GET_SETTINGS":
      return {
        ...state,
        settings: action.payload,
        loading: false
      };
    case "FETCH_FAILURE_WEBSETTINGS":
      return {
        ...state,
        errorMessage: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default webSettingsReducer;