import React, { useState, useEffect, createRef } from 'react';
import { useHistory } from "react-router-dom";

import MDUIKit from 'md-uikit';
import UIkit from 'uikit';
import { useDispatch, useSelector } from "react-redux";

import Typography from '@material-ui/core/Typography'
//Components
import HeaderCart from './HeaderCart';
/* import GoogleMap from './GoogleMap.js'; */

// Context Actions
import CartActions from '../../providers/cart/cart.actions';
//Assets
import CheckoutStep1 from './pages/CheckoutStep1';
import CheckoutTotal from './pages/CheckoutTotal';
import CheckoutStep3 from './pages/CheckoutStep3';
import { Box, Grid, } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import CheckoutStep2 from './pages/CheckoutStep2';
import WhatsappFab from './components/WhatsappFab';
import settings from '../../settings.js';
import LoadingPaymentModal from './components/checkout/LoadingPaymentModal';

const useStyles = makeStyles((theme) => ({
  root: {
  },
}));

const Checkout = () => {

  const [voucherInfo, setVoucherInfo] = useState({
    visible: false,
    code: ""
  });
  const [cardInfo, setCardInfo] = useState({
    email: "",
    number: "",
    cvv: "",
    exp_month: "",
    exp_year: ""
  });
  const [voucherValidation, setVoucherValidation] = useState({
    valid: null,
    voucherInfo: { voucherId: 0 }
  })
  const [selectedDeliveryOption, setSelectedDeliveryOption] = useState({
    deliveryZoneId: 0,
    fee: 0
  });
  const [activeStep, setActiveStep] = useState(0);
  // Totals
  const [discount, setDiscount] = useState(0);
  const [total, setTotal] = useState(0);
  // Address
  const [selectedAddress, setSelectedAddress] = useState({
    addressId: 0
  });
  const [addressDetails, setAddressDetails] = useState({
    address_street: "",
    reference: "",
    address_street_2: ""
  });

  const [selectedPayment, setSelectedPayment] = useState(false);

  const [deliveryType, setDeliveryType] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);

  const [Steps, setSteps] = useState([{
    step: 1,
    verified: false
  }, {
    step: 2,
    verified: false
  }, {
    step: 3,
    verified: false
  }]);

  const cart = CartActions();
  const history = useHistory();
  const classes = useStyles();

  const placeOrderButton = createRef();
  const userReducer = useSelector(state => state.userReducer);
  const [orderNotes, setOrderNotes] = useState("")
  const [loadingModalInfo, setLoadingModalInfo] = useState({
    loading: false,
    message: "",
    showModal: false
  })

  useEffect(() => {
    MDUIKit.components();
    // Culqi keys
    window.Culqi.publicKey = settings.CulqiPublicKey;
    window.Culqi.init();
  }, [])

  useEffect(() => {

    setTotals();
  }, [discount]);

  useEffect(() => {
    if (cart.cartItems.length == 0) {
      history.push("/shop/1");
    }
    setVoucherValidation({
      valid: null,
      voucherInfo: { voucherId: 0 }
    })
    setVoucherInfo({ ...voucherInfo, code: "" });
    setDiscount(0);
    setTotals();

  }, [cart.cartItems, selectedDeliveryOption]);

  function setTotals() {
    let subTotal = cart.subTotal != null ? cart.subTotal : 0;
    let total = (parseFloat(selectedDeliveryOption.fee) + subTotal) - parseFloat(discount);
    setTotal(total.toFixed(2));
  }

  async function culqiResponse(tokenToBuy) {
    console.log("culqiResponse");
    setLoadingModalInfo({ ...loadingModalInfo, loading: true, showModal: true });
    window.Culqi.close();

    const resultCulqi = await validateCulqi(tokenToBuy);
    console.log();
    if (resultCulqi) {
      const result = await createOrder(2);
      if (result) {
        cart.setList([]);
        cart.setCreatedOrder(result.newId);
        history.push("/checkout/order-confirmation")
      }
    }
  }

  function culqiResponseFail(message) {
    console.log("culqiResponseFail");
    setLoadingModalInfo({ ...loadingModalInfo, loading: false, message: message, showModal: true });
  }

  function loadCulqi() {

    // Setting references for teh response
    window.culqiResponse = culqiResponse;
    window.culqiResponseFail = culqiResponseFail;


    var valorTotal = parseFloat(total).toFixed(2);
    valorTotal = valorTotal.replace('.', '');
    window.Culqi.settings({
      title: 'Suelo Sagrado Perú',
      currency: 'PEN',  // Este parámetro es requerido para realizar pagos yape
      amount: valorTotal,  // Este parámetro es requerido para realizar pagos yape
      /* order: 'ord_live_0CjjdWhFpEAZlxlz' */ // Este parámetro es requerido para realizar pagos con pagoEfectivo, billeteras y Cuotéalo
    });

    window.Culqi.options({
      lang: "auto",
      installments: false, // Habilitar o deshabilitar el campo de cuotas
      paymentMethods: {
        tarjeta: true,
        yape: true,
        bancaMovil: true,
        agente: true,
        billetera: true,
        cuotealo: false,
      },
    });

    window.Culqi.options({
      style: {
        logo: 'https://suelosagradoperu.com/static/media/main_logo.82f44772.png',
        bannerColor: '', // hexadecimal
        buttonBackground: '', // hexadecimal
        menuColor: '', // hexadecimal
        linksColor: '', // hexadecimal
        buttonText: '', // texto que tomará el botón
        buttonTextColor: '', // hexadecimal
        priceColor: '' // hexadecimal
      }
    });

  }
  const saveStep3 = async e => {
    console.log("saveStep3");
    console.log(Steps);
    let flag = true;
    if (selectedPayment === false || Steps[1].verified === false) {
      flag = false;
    }
    if (flag) {
      console.log(selectedPayment);
      if (selectedPayment == "2") {
        console.log(cardInfo);
        //TODO: implement new culqi payment
        loadCulqi();
        console.log("opening culqi");
        window.Culqi.open();

        // If incorrect show wrong info message
        /* if (cardInfo.cvv === "" || cardInfo.number === "" ||
          cardInfo.exp_month === "" || cardInfo.exp_year === "") {
          let message = "Please complete the payment details";
          if (userReducer.language === "es") {
            message = "Porfavor complete los detalles de pago.";
          }
          alert(message);
        } else {
          loadCulqi();
        } */
      }
      else {
        const result = await createOrder(1);
        if (result) {
          cart.setList([]);
          cart.setCreatedOrder(result.newId);
          history.push("/checkout/order-confirmation")
        }
      }

    } else {
      let message = "Complete the information to continue.";
      if (userReducer.language === "es") {
        message = "Complete el formulario para continuar.";
      }
      alert(message)
    }
  }
  async function validateCulqi(tokenToBuy) {

    var valorTotal = parseFloat(total).toFixed(2);
    valorTotal = valorTotal.replace('.', '');

    try {
      const req = {
        token: tokenToBuy,
        amount: valorTotal,
        description: "New order",
        email: userReducer.currentUserFront.email,
      };
      console.log("validateCulqi");
      console.log(req);
      const response = await fetch(`${settings.connectionString}/api/payments/culqi`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + userReducer.currentUserFront.token
        },
        method: 'POST',
        body: JSON.stringify(req)
      });

      const res = await response.json();
      console.log(res);
      if (res.object == "error") {
        setLoadingModalInfo({ ...loadingModalInfo, loading: false, message: res.user_message, showModal: true });
        return false;
      }
      if (
        res.outcome != null &&
        res.outcome.type == "venta_exitosa"
      ) {
        console.log("Exito!");
        return true;
      }
    } catch (error) {
      setLoadingModalInfo({ ...loadingModalInfo, loading: false, message: "Something went wrong. Please try later.", showModal: true });
      console.log(error);
    }
    return false;
  }
  async function createAddress() {
    try {
      const req = {
        "address_street": addressDetails.address_street,
        "address_street_2": addressDetails.address_street_2,
        "reference": addressDetails.reference,
        "city": "",
        "region": "",
        "country": "Peru",
        "zipcode": "",
        "latitude": "",
        "longitude": "",
        "delivery_zone_id": selectedDeliveryOption.deliveryZoneId
      };
      console.log("createAddress");
      console.log(req);
      const response = await fetch(`${settings.connectionString}/api/addresses`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + userReducer.currentUserFront.token
        },
        method: 'POST',
        body: JSON.stringify(req)
      });
      if (response.status >= 400 && response.status < 500) {
        UIkit.notification('Information error.');
        //setBoolNewAddress(true);
        throw "Error" + response.status;
      }
      if (response.status >= 500 && response.status < 600) {
        UIkit.notification('Error. Please try later.');
      }

      const res = await response.json();
      console.log(res);
      console.log("res.newAddressId");
      return res.newAddressId;
    } catch (error) {
      console.log(error);
    }
    return false;
  }
  async function createOrder(stateId) {
    let newAddressId = 0;

    setLoadingModalInfo({ ...loadingModalInfo, loading: true, showModal: true });
    if (deliveryType === "1") {
      console.log("create order verify");
      console.log(selectedAddress);
      if (selectedAddress.addressId === "") {
        newAddressId = await createAddress();
      } else {
        newAddressId = selectedAddress.addressId;
      }
    }

    try {
      const req = {
        deliveryZoneId: selectedDeliveryOption.deliveryZoneId,
        addressId: newAddressId,
        stateId: stateId, // Recibido
        totalPrice: total,
        deliveryFee: selectedDeliveryOption.fee,
        items: cart.cartItems,
        voucherId: voucherValidation.voucherInfo.voucherId,
        comments: "",
        paymentId: selectedPayment,
        orderNotes: orderNotes,
        language: userReducer.language
      };

      console.log("createOrder");
      console.log(req);

      const response = await fetch(`${settings.connectionString}/api/orders`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + userReducer.currentUserFront.token
        },
        method: 'POST',
        body: JSON.stringify(req)
      });
      if (response.status >= 400 && response.status < 500) {
        UIkit.notification('Information error.');
        //setBoolNewAddress(true);
        throw "Error" + response.status;
      }
      if (response.status >= 500 && response.status < 600) {
        UIkit.notification('Error. Please try later.');
        throw "Error" + response.status;
      }
      /* const text = await response.text();
      console.log(text); */
      const res = await response.json();
      console.log(res);
      setLoadingModalInfo({ ...loadingModalInfo, loading: false, showModal: false });
      return res;
    } catch (error) {
      setLoadingModalInfo({ ...loadingModalInfo, loading: false, message: "Something went wrong. Please try later.", showModal: true });
      console.log(error);
    }
    return false;
  }
  /*   console.log("voucherValidation");
    console.log(voucherValidation); */
  return (
    <>
      <HeaderCart />
      <Box mb={20} mt={2} className={classes.root} minHeight="65%">
        <div className="uk-container ">
          <Grid container spacing={2}>
            <Grid item xs={12}>

            </Grid>
            <Grid item xs={12} sm={12} md={7} >
              <Grid container spacing={2}>
                {/* <Grid item xs={12}>
                  <Stepper color='secondary' style={{ backgroundColor: "transparent" }} activeStep={currentStep - 1} alternativeLabel >
                    {[<FormattedMessage
                      id="checkout_step1_title"
                      defaultMessage="How would you like to receive your groceries?"
                    />,
                    <FormattedMessage
                      id="checkout_step2_title"
                      defaultMessage="Review your items"
                    />
                      ,
                    <FormattedMessage
                      id="checkout_step3_payment_title"
                      defaultMessage="Payment options"
                    />
                    ].map((label, index) => (
                      <Step key={index}>
                        <StepButton onClick={() => setCurrentStep(index + 1)}>{label}</StepButton>
                      </Step>
                    ))}
                  </Stepper>
                </Grid> 
                <Grid item xs={12}>
                  {currentStep === 1 &&
                    <CheckoutStep1
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      addressDetails={addressDetails}
                      setAddressDetails={setAddressDetails}
                      setSelectedDeliveryOption={setSelectedDeliveryOption}
                      selectedDeliveryOption={selectedDeliveryOption}
                      Steps={Steps}
                      setSteps={setSteps}
                      selectedAddress={selectedAddress}
                      setSelectedAddress={setSelectedAddress}
                      deliveryType={deliveryType}
                      setDeliveryType={setDeliveryType}
                    />
                  }
                  {currentStep === 2 &&
                    <CheckoutStep2
                      Steps={Steps}
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      selectedDeliveryOption={selectedDeliveryOption}
                      setSteps={setSteps} />
                  }
                  {currentStep === 3 &&
                    <CheckoutStep3
                      Steps={Steps}
                      setCurrentStep={setCurrentStep}
                      selectedDeliveryOption={selectedDeliveryOption}
                      selectedAddress={selectedAddress}
                      deliveryType={deliveryType}
                      total={total}
                      voucherValidation={voucherValidation}
                      addressDetails={addressDetails}
                      currentStep={currentStep}
                      setSelectedPayment={setSelectedPayment}
                      selectedPayment={selectedPayment}
                      cardInfo={cardInfo}
                      setCardInfo={setCardInfo}
                    />
                  }
                </Grid> */}
                <Grid item xs={12}>
                  <CheckoutStep1
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    addressDetails={addressDetails}
                    setAddressDetails={setAddressDetails}
                    setSelectedDeliveryOption={setSelectedDeliveryOption}
                    selectedDeliveryOption={selectedDeliveryOption}
                    Steps={Steps}
                    setSteps={setSteps}
                    selectedAddress={selectedAddress}
                    setSelectedAddress={setSelectedAddress}
                    deliveryType={deliveryType}
                    setDeliveryType={setDeliveryType}
                  />
                </Grid>
                <Grid item xs={12}>
                  <CheckoutStep2
                    Steps={Steps}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    selectedDeliveryOption={selectedDeliveryOption}
                    setSteps={setSteps} />
                </Grid>
                <Grid item xs={12}>
                  <CheckoutStep3
                    Steps={Steps}
                    setCurrentStep={setCurrentStep}
                    selectedDeliveryOption={selectedDeliveryOption}
                    selectedAddress={selectedAddress}
                    deliveryType={deliveryType}
                    total={total}
                    voucherValidation={voucherValidation}
                    addressDetails={addressDetails}
                    currentStep={currentStep}
                    setSelectedPayment={setSelectedPayment}
                    selectedPayment={selectedPayment}
                    cardInfo={cardInfo}
                    setCardInfo={setCardInfo}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={5} >
              <CheckoutTotal
                currentStep={currentStep}
                total={total}
                selectedDeliveryOption={selectedDeliveryOption}
                subTotal={cart.subTotal}
                voucherInfo={voucherInfo}
                setVoucherInfo={setVoucherInfo}
                voucherValidation={voucherValidation}
                setVoucherValidation={setVoucherValidation}
                discount={discount}
                setDiscount={setDiscount}
                placeOrderButton={placeOrderButton}
                saveStep3={saveStep3}
                orderNotes={orderNotes}
                setOrderNotes={setOrderNotes}
              />
            </Grid>
          </Grid>
        </div >
      </Box>
      <Box p={2} style={{ backgroundColor: "#819164" }}>
        <Grid container spacing={2}>
          {/* <Grid xs={12} sm={4} item >
            <span style={{ marginRight: "20px" }}>
              <a href="https://www.instagram.com/suelosagrado_initiative/"><i className="fab fa-instagram md-color-white"></i></a>
            </span>
            <a href="https://www.facebook.com/SueloSagradoInitiative"><i className="fab fa-facebook-f md-color-white"></i></a>

          </Grid> */}
          <Grid xs={12} sm={8} item>
            <Typography variant="body2" style={{ color: "#47563A", fontWeight: 700 }}>Valle Sagrado © 2021. All Rights Reserved.</Typography>

          </Grid>
        </Grid>
      </Box>
      <WhatsappFab />
      <LoadingPaymentModal loadingModalInfo={loadingModalInfo} setLoadingModalInfo={setLoadingModalInfo} />
    </ >
  )
}
export default Checkout;