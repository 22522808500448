import settings from '../../settings.js';

import { setAlert } from '../alert/alert.actions';

export const fetchStart = () => ({
    type: "FETCH_START_WEBSETTINGS"
});
export const fetchSuccess = () => ({
    type: "FETCH_SUCCESS_WEBSETTINGS"
});
export const fetchFailure = errorMessage => ({
    type: "FETCH_FAILURE_WEBSETTINGS",
    payload: errorMessage
});


const getWebSettings = () => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    
    try {
        const response = await fetch(`${settings.connectionString}/api/websettings`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'GET'
        });
        const res = await response.json();
        dispatch({
            type: 'GET_SETTINGS',
            payload: res
        });

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
};

const getWebSetting = (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    try {
        
        const response = await fetch(`${settings.connectionString}/api/websettings/${id}`, {
            method: 'GET'
        });

        const res = await response.json();
        res.status = res.status == 1 ? true : false;
        dispatch(fetchSuccess());
        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const updateWebSetting = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("updatewebsettings");
    try {
        const response = await fetch(`${settings.connectionString}/api/websettings`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'PUT',
            body: JSON.stringify(body)
        });
        console.log(response);
        const res = await response.json();
        console.log(res);
        dispatch(fetchSuccess());
        if (res) {
            dispatch(setAlert({ msg: "Información actualizada" }));
        }
        return true;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo actualizar" }));
    }
    return false;
}


export default {
    getWebSettings,
    getWebSetting,
    updateWebSetting
}