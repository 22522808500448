import React, { useContext, useEffect, useState } from 'react';
/* import UIkit from 'uikit'; */
import { HashRouter, Link, Route } from "react-router-dom";
import Logo from "../../assets/img/main_logo.png";

import Routes from './routes.js';

import { useSelector } from "react-redux";

import { Box, Collapse, Drawer, Icon, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';

const drawerWidth = 256;
const useStyles = makeStyles((theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    boxSizing: 'border-box',
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    marginTop:theme.spacing(3),
    marginBottom:theme.spacing(1),
    padding: theme.spacing(1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'center',
  },
  listItemContainer: {
    margin: theme.spacing(1),
    width: "initial",
    borderRadius: theme.spacing(0.5)
  },
  nested: {
    paddingLeft: theme.spacing(9),
  },
}));

const ListItemDrawer = withStyles((theme) => ({
  root: {
    margin: theme.spacing(1),
    width: "initial",
    borderRadius: theme.spacing(0.5),
    "&$selected": {
      color: theme.palette.primary.main,
      backgroundColor: lighten(theme.palette.primary.light, 0.87),
    },
    "&$selected:hover": {
      color: theme.palette.primary.main,
      backgroundColor: lighten(theme.palette.primary.light, 0.80),
    },
    /* "&:hover": {
      backgroundColor: "blue",
      color: "white"
    } */
  },
  selected: {}
}))(ListItem);

const SubItemSideBar = ({ route, onClickCloseMenu }) => {

  const classes = useStyles();
  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemDrawer button onClick={handleClick}
      >
        <ListItemIcon >
          <Icon>{route.icon}</Icon>
        </ListItemIcon>
        <ListItemText primary={route.name} />
        {open ? <Icon>expand_less</Icon> : <Icon>expand_more</Icon>}
      </ListItemDrawer>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List dense component="div" disablePadding>
          {route.submenu.map((inner_route, inner_key) =>
            <Route
              key={"sub_" + inner_key}
              path={inner_route.path}
              children={({ match }) => (
                <ListItemDrawer
                  component={Link} to={inner_route.path}
                  button className={classes.nested}
                  onClick={onClickCloseMenu}
                  {...(match && { selected: true })}>
                  {/* <ListItemIcon >
                                        <Icon>add</Icon>
                                      </ListItemIcon> */}
                  <ListItemText primary={inner_route.name} />
                </ListItemDrawer>
              )}
            />
          )}
        </List>
      </Collapse>
    </>
  )
}

const Sidebar = ({ openDrawer, setOpenDrawer, isMobile }) => {

  const classes = useStyles();
  const userReducer = useSelector(state => state.userReducer);


  const onClickCloseMenu = () => {
    if (isMobile) {
      setOpenDrawer(false);
    }
  }
  return (
      <Drawer
        className={classes.drawer}
        {...(!isMobile && { variant: "persistent" })}
        anchor="left"
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        classes={{
          paper: classes.drawerPaper,
        }}

        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
      >
        <div className={classes.drawerHeader}>
          <img src={Logo} width="100px" />
        </div>
        <List>
          {Routes.map((route, key) => (
            route.rol == userReducer.currentUser.rol && (
              <Box key={key}>
                <Route
                  path={route.path}
                  children={({ match }) => (
                    <>
                      {route.submenu ?
                        <>
                          <SubItemSideBar onClickCloseMenu={onClickCloseMenu} route={route} />
                        </>
                        :
                        <ListItemDrawer button component={Link}
                          to={route.path}
                          onClick={onClickCloseMenu}
                          {...(match && { selected: true })}
                        >
                          <ListItemIcon >
                            <Icon>{route.icon}</Icon>
                          </ListItemIcon>
                          <ListItemText primary={route.name} />
                        </ListItemDrawer>
                      }
                    </>
                  )}
                />
              </Box>
            )))}
        </List>
      </Drawer>
  )
}

export default Sidebar;