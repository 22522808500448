import {
    Fab, Icon, Paper, Grid, Box, Divider,
    Typography, CircularProgress, Avatar
} from '@material-ui/core';

import { Link, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react'
import ContentBefore from './components/ContentBefore';

import { useDispatch, useSelector } from "react-redux";
import discountsActions from '../../redux/automatic_discounts/automatic_discounts.actions';

import settings from '../../settings.js';
import GeneralContainer from './components/GeneralContainer';
import FAB from './components/utils/FAB';
import GeneralRow from './components/utils/GeneralRow';
import ResponsiveMenuButton from './components/utils/ResponsiveMenuButton';
import DeleteModal from './components/modals/DeleteModal';

const Discounts = () => {

    const automaticDiscountsReducer = useSelector(state => state.automaticDiscountsReducer);
    const dispatch = useDispatch();

    const history = useHistory();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({
        orderId: 0
    });

    /* const classes = useStyles(); */

    useEffect(() => {
        dispatch(discountsActions.getDiscounts());
    }, []);

    const menu = [
        {
            title: "Ver/Editar",
            icon: "visibility",
            action: e => onClickView(e)
        },
        {
            title: "Eliminar",
            icon: "delete",
            action: e => onClickDelete(e)
        },
    ]

    const onClickView = item => {
        console.log("onClickView");
        history.push(`/admin/autodiscount_detail/${item.autoId}`)
    }

    const onClickDelete = item => {
        setSelectedItem(item);
        setShowDeleteModal(true);
    }
    const deleteProgram = item => {
        const result = dispatch(discountsActions.deleteDiscount(selectedItem.autoId));
        if (result) {
            setShowDeleteModal(false);
        }
    }

    return (
        <>
            <ContentBefore title="Descuentos automáticos" />
            <GeneralContainer>
                <Paper >
                    {automaticDiscountsReducer.loading ?
                        <CircularProgress />
                        :
                        <>
                            {automaticDiscountsReducer.discounts.length > 0 && automaticDiscountsReducer.discounts.map((item, index) => (
                                <>
                                    <GeneralRow>
                                        <Box display="flex" alignItems="center">
                                            <Box flexGrow={1}>
                                                <Typography component={Link} to={`/admin/autodiscount_detail/${item.autoId}`} variant="h6" color="initial">
                                                    {item.name}
                                                </Typography>
                                                {item.table_name === "category" &&
                                                    <Typography component="span" variant="body1" color="initial">
                                                        {(item.parentId ? " - Subcategoria" : " - Categoria")}
                                                    </Typography>
                                                }
                                                {item.table_name === "product" &&
                                                    <Typography component="span" variant="body1" color="initial">
                                                         {" - Producto"}
                                                </Typography>
                                                }
                                                <Typography component="span" variant="body1" color="initial">
                                                    {item.status == "1" ?
                                                        <Box fontWeight="fontWeightBold" color="success.main">Activo</Box>
                                                        :
                                                        <Box fontWeight="fontWeightBold" color="text.secondary">Inactivo</Box>
                                                    }
                                                </Typography>
                                            </Box>
                                            <Box >
                                                <ResponsiveMenuButton menu={menu} item={item}
                                                    title={item.name} />
                                            </Box>
                                        </Box>
                                    </GeneralRow>
                                </>
                            ))}
                        </>
                    }
                </Paper>
            </GeneralContainer>
            <DeleteModal showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                title={selectedItem.name}
                action={deleteProgram}
            />
            <FAB component={Link} to="/admin/autodiscount_detail/0">
                <Icon>add</Icon>
            </FAB>
        </>
    )
}

export default Discounts
