import settings from '../../settings.js';

import { setAlert } from '../alert/alert.actions';

export const fetchStart = () => ({
    type: "FETCH_START"
});
export const fetchSuccess = () => ({
    type: "FETCH_SUCCESS"
});
export const fetchFailure = errorMessage => ({
    type: "FETCH_FAILURE",
    payload: errorMessage
});

const getPayments = () => async (dispatch, getState) => {
    
    const state = getState();
    dispatch(fetchStart());

    try {
        const response = await fetch(`${settings.connectionString}/api/payments/private`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'GET'
        });
        const res = await response.json();
        dispatch({
            type: 'GET_PAYMENTS',
            payload: res
        });

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
};

const getPaymentsFront = () => async dispatch => {
    dispatch(fetchStart());

    try {
        const response = await fetch(`${settings.connectionString}/api/payments/public`, {
            method: 'GET'
        });
        const res = await response.json();
        dispatch({
            type: 'GET_PAYMENTS_FRONT',
            payload: res
        });

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
};
const getPayment = (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    try {
        const response = await fetch(`${settings.connectionString}/api/payments/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'GET'
        });
        const res = await response.json();
        res.status = res.status == 1 ? true : false;
        dispatch(fetchSuccess());
        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const insertPayment = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("addPayment");
    console.log(body);
    try {
        const response = await fetch(`${settings.connectionString}/api/payments`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        const res = await response.json();
        dispatch(fetchSuccess());
        if (res) {
            dispatch(setAlert({ msg: "Payment creado" }));
        }
        return true;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo crear el Payment" }));
    }
    return false;
}

const updatePayment = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("updatePayment");
    try {
        const response = await fetch(`${settings.connectionString}/api/payments`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'PUT',
            body: JSON.stringify(body)
        });
        console.log(response);
        const res = await response.json();
        console.log(res);
        dispatch(fetchSuccess());
        if (res) {
            dispatch(setAlert({ msg: "Payment actualizado" }));
        }
        return true;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo actualizar el Payment" }));
    }
    return false;
}

const deletePayment = (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("deletePayment");
    try {
        const response = await fetch(`${settings.connectionString}/api/payments/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'DELETE'
        });
        const res = await response.json();
        console.log("deletePayment");
        console.log(res);
        if (res) {
            dispatch(getPayments());
            dispatch(setAlert({ msg: "Payment eliminado" }));
            return true;
        }
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "Error al eliminar" }));
    }
    return false;
}

export default {
    getPayments,
    getPaymentsFront,
    insertPayment,
    getPayment,
    updatePayment,
    deletePayment
}