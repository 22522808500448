import settings from '../../settings.js';

import { setAlert } from '../alert/alert.actions';

export const fetchStart = () => ({
    type: "FETCH_START_PRODUCTS"
});
export const fetchSuccess = () => ({
    type: "FETCH_SUCCESS_PRODUCTS"
});
export const fetchFailure = errorMessage => ({
    type: "FETCH_FAILURE_PRODUCTS",
    payload: errorMessage
});

const getProducts = (type, querySearch, page, sort) => async (dispatch, getState) => {
    const state = getState();

    dispatch(fetchStart());
    const query = `?q=${querySearch}`;
    const pageOut = `&page=${page}`;
    const pageSize = `&pageSize=4`;
    const sortBy = `&sortBy=${sort}`;

    try {
        console.log(`${settings.connectionString}/api/product/private${query}${pageOut}${sortBy}`);
        const response = await fetch(`${settings.connectionString}/api/product/private${query}${pageOut}${sortBy}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'GET'
        });
        const res = await response.json();
        console.log("set alert");
        /* dispatch(setAlert({ msg: "hola" })); */
        dispatch({
            type: type,
            payload: res
        });

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
};

const getProductsByCategory = (type, id, querySearch, page, sort) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());

    const pageOut = `?page=${page}`;
    const sortBy = `&sortBy=${sort}`;
    const query = `&q=${querySearch}`;

    try {
        const url = `${settings.connectionString}/api/product/byCategoryId/${id}${pageOut}${sortBy}${query}`;
        console.log(url);
        const response = await fetch(url, {
            method: 'GET'
        });
        const res = await response.json();
        console.log("getProductsByCategory");
        console.log(res);

        res.forEach(element => {
            element.stock_active = element.stock_active === "1" ? true : false;
            element.alt_price_active = element.alt_price_active === "1" ? true : false;
            element.product_status = element.product_status === "1" ? true : false;
            element.commentsFlag = element.commentsFlag === "1" ? true : false;
        });

        dispatch({
            type: type,
            payload: res
        });

    } catch (error) {
        dispatch({
            type: type,
            payload: []
        });
        dispatch(fetchFailure(error.message));
    }
};

const getProduct = (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    try {
        const response = await fetch(`${settings.connectionString}/api/product/${id}`, {
            method: 'GET',
        });

        const res = await response.json();
        res.stock_active = res.stock_active === "1" ? true : false;
        res.product_status = res.product_status === "1" ? true : false;
        res.alt_price_active = res.alt_price_active === "1" ? true : false;
        res.commentsFlag = res.commentsFlag === "1" ? true : false;

        dispatch(fetchSuccess());
        return res;

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const getCategories = (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    try {
        const response = await fetch(`${settings.connectionString}/api/categories/byProductId/${id}`, {
            method: 'GET'
        });

        const res = await response.json();
        console.log("getCategories by productId")
        console.log(res)
        dispatch(fetchSuccess());
        return res;

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const getZones = (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    try {
        const response = await fetch(`${settings.connectionString}/api/deliveryzones/byProductId/${id}`, {
            method: 'GET'
        });

        /* const text = await response.text();
        console.log(text) */
        const res = await response.json();
        console.log("getZones by productId")
        console.log(res)
        dispatch(fetchSuccess());
        return res;

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const updateCategories = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("updateCategories")
    console.log(body)

    try {
        const response = await fetch(`${settings.connectionString}/api/categories/byProductId`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'PUT',
            body: JSON.stringify(body)
        });
        console.log(response);
        const res = await response.json();
        dispatch(fetchSuccess());
        return res;

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
    return false;
}

const updateZones = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("updateZones")
    console.log(body)

    try {
        const response = await fetch(`${settings.connectionString}/api/deliveryzones/byProductId`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'PUT',
            body: JSON.stringify(body)
        });
        console.log(response);
        /* const text = await response.text();
        console.log(text); */
        const res = await response.json();
        dispatch(fetchSuccess());
        return res;

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
    return false;
}

const updateProduct = (data) => async (dispatch, getState) => {
    const state = getState();
    console.log("updateProduct");
    dispatch(fetchStart());
    try {
        const body = {
            ...data,
            jwt: state.userReducer.currentUser.token
        }
        const response = await fetch(`${settings.connectionString}/api/product`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'PUT',
            body: JSON.stringify(body)
        });
        console.log(response);
        const res = await response.json();
        dispatch(fetchSuccess());
        if (res) {
            dispatch(setAlert({ msg: "Producto actualizado" }));
        }
        return true;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo actualizar el producto" }));
    }
    return false;
}

const insertProduct = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("insertProduct");
    try {
        const response = await fetch(`${settings.connectionString}/api/product`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });

        const res = await response.json();
        if (res) {
            dispatch(setAlert({ msg: "Producto creado" }));
        }

        return res;

    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "Error al crear" }));
    }
    return false;
}
const deleteProduct = (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("deleteProduct");
    try {

        const response = await fetch(`${settings.connectionString}/api/product/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'DELETE'
        });
        if (response.status >= 400 && response.status < 500) {
            throw "Error" + response.status;
        }
        if (response.status >= 500 && response.status < 600) {
        }
        console.log(response);
        const res = await response.json();
        if (res) {
            dispatch(setAlert({ msg: "Producto eliminado" }));
        }

        return true;

    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "Error al eliminar" }));
    }
    return false;
}

export default {
    getProductsByCategory,
    insertProduct,
    getProducts,
    updateZones,
    getProduct,
    updateProduct,
    deleteProduct,
    getCategories,
    updateCategories,
    getZones
}