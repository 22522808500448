const INITIAL_STATE = {
  vouchers: [],
  loading: false,
  errorMessage: undefined
};

const vouchersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_START_VOUCHERS":
      return {
        ...state,
        loading: true
      };
    case "FETCH_SUCCESS_VOUCHERS":
      return {
        ...state,
        loading: false
      };
    case "GET_VOUCHERS":
      return {
        ...state,
        vouchers: action.payload,
        loading: false
      };
    case "GET_VOUCHERS_FRONT":
      return {
        ...state,
        vouchers: action.payload,
        loading: false
      };
    case "FETCH_FAILURE_VOUCHERS":
      return {
        ...state,
        errorMessage: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default vouchersReducer;