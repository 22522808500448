import React, { useState, useEffect } from 'react';

import { Link, useHistory } from "react-router-dom";
import ContentBefore from './components/ContentBefore';
import GeneralContainer from './components/GeneralContainer';

import { Box, CircularProgress, Paper, Grid, InputBase, InputAdornment, Icon, Typography, Avatar } from '@material-ui/core';
import GeneralRow from './components/utils/GeneralRow';
import ResponsiveMenuButton from './components/utils/ResponsiveMenuButton';

import { useDispatch, useSelector } from "react-redux";
import zonesActions from '../../redux/delivery_zone/delivery_zone.actions';
import { amber, blue, blueGrey, green } from '@material-ui/core/colors';
import FAB from './components/utils/FAB';
import DeleteModal from './components/modals/DeleteModal';

const ApplicantHistoryPage = () => {

    const zonesReducer = useSelector(state => state.zonesReducer);
    const dispatch = useDispatch();

    const history = useHistory();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({
        orderId: 0
    });
    //const classes = useStyles();

    useEffect(() => {
        dispatch(zonesActions.getZones());
    }, []);


    const menu = [
        {
            title: "Ver/Editar",
            icon: "visibility",
            action: e => onClickView(e)
        },
        {
            title: "Eliminar tipo de pago",
            icon: "delete",
            action: e => onClickDelete(e)
        },
    ]
    const onClickView = item => {
        console.log("onClickView");
        history.push(`/admin/deliveryzone_detail/${item.deliveryZoneId}`)
    }

    const onClickDelete = item => {
        setSelectedItem(item);
        setShowDeleteModal(true);
    }
    const deleteProgram = item => {
        const result = dispatch(zonesActions.deleteZone(selectedItem.deliveryZoneId));
        if (result) {
            setShowDeleteModal(false);
        }
    }

    return (
        <>
            <ContentBefore title="Métodos de envio y recojo" />
            <GeneralContainer>
                {zonesReducer.loading ?
                    <CircularProgress />
                    :
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper>
                                {zonesReducer.zones.length > 0 ?
                                    zonesReducer.zones.map((item, index) => (
                                        <GeneralRow key={index}>
                                            <Box display="flex" alignItems="center">
                                                <Box flexGrow={1}>
                                                    <Box display="flex" alignItems="center">
                                                        <Box mr={1}>
                                                            {item.deliveryZoneCategoryId === "1" &&
                                                                <i className="fas fa-truck md-color-primary"></i>
                                                            }
                                                            {item.deliveryZoneCategoryId === "2" &&
                                                                <i className="fas fa-shopping-bag"></i>
                                                            }
                                                        </Box>
                                                        <Box>
                                                            <Typography component={Link} to={`/admin/deliveryzone_detail/${item.deliveryZoneId}`} variant="h6">
                                                                <Box color="text.primary">{item.description}</Box>
                                                            </Typography>
                                                            <Typography component="span" variant="body1" color="initial">
                                                                {item.status == "1" ?
                                                                    <Box fontWeight="fontWeightBold" color="success.main">Activo</Box>
                                                                    :
                                                                    <Box fontWeight="fontWeightBold" color="text.secondary">Inactivo</Box>
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box >
                                                    <ResponsiveMenuButton menu={menu} item={item} title={item.description} />
                                                </Box>
                                            </Box>
                                        </GeneralRow>
                                    )) :
                                    <Box>No existen tipos de pagos</Box>
                                }
                            </Paper>
                        </Grid>
                    </Grid>

                }
            </GeneralContainer>
            <DeleteModal showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                title={selectedItem.description}
                action={deleteProgram}
            />
            <FAB component={Link} to="/admin/deliveryzone_detail/0">
                <Icon>add</Icon>
            </FAB>
        </>
    );
}
export default ApplicantHistoryPage;