import React, { useState, useRef, lazy, Suspense, useEffect } from 'react';
import { HashRouter, Route, Link, useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Fab, Icon } from '@material-ui/core';
import ReactGA from 'react-ga';

import WhatsappFab from './components/WhatsappFab';

//Components
import FrontHeader from './Header';
import FrontFooter from './Footer';
import Cart from './Cart';


import MenuSidebar from './components/MenuSidebar';

const Login = lazy(() => import('./Login'));
const ForgotPassword = lazy(() => import('./ForgotPassword'));
const ResetPassword = lazy(() => import('./ResetPassword'));
const Account = lazy(() => import('./Account'));
const Signup = lazy(() => import('./Signup'));
const ActivateAccount = lazy(() => import('./ActivateAccount'));
const Home = lazy(() => import('./Home'));

const About = lazy(() => import('./pages/About'));
const ResultPage = lazy(() => import('./pages/ResultPage'));
const Steps = lazy(() => import('./pages/Steps'));
const Shop = lazy(() => import('./pages/Shop'));
const Producers = lazy(() => import('./pages/Producers'));
const Recipes = lazy(() => import('./pages/Recipes'));
const RecipeDetail = lazy(() => import('./pages/RecipeDetail'));
const ProductDetails = lazy(() => import('./pages/ProductDetails'));
const ProducerDetail = lazy(() => import('./pages/ProducerDetail'));
const FAQ = lazy(() => import('./pages/FAQ'));
const Privacy = lazy(() => import('./pages/Privacy'));
const Terms = lazy(() => import('./pages/Terms'));

//UA-85208552-10 original
const trackingId = "UA-85208552-10"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

const MainContainer = () => {

    const modalCart = useRef();
    const [openDrawer, setOpenDrawer] = useState(false)
    let location = useLocation();

    useEffect(() => {
        ReactGA.pageview(location.pathname);
    }, [location])
    
    return (
        <div>
            <MenuSidebar setOpenDrawer={setOpenDrawer} openDrawer={openDrawer} />
            <FrontHeader modalCart={modalCart} setOpenDrawer={setOpenDrawer} />
            <Cart modalCart={modalCart} />
            <Suspense fallback={<Box display="flex" justifyContent="center" p={5}>
                <CircularProgress />
            </Box>}>
                <Route exact path='/' component={Home} />
                <Route path='/signup' component={Signup} />
                <Route path='/faq' component={FAQ} />
                <Route path='/privacy' component={Privacy} />
                <Route path='/terms' component={Terms} />
                <Route path='/forgot-password' component={ForgotPassword} />
                <Route path='/reset-password/:token' component={ResetPassword} />
                <Route path='/activate-account/:token' component={ActivateAccount} />
                <Route path='/login' component={Login} />
                <Route path='/account' component={Account} />
                <Route path='/search/:query' component={ResultPage} />
                <Route path='/about' component={About} />
                <Route path='/steps' component={Steps} />
                <Route path='/producers' component={Producers} />
                <Route path='/producer_detail/:id' component={ProducerDetail} />
                <Route path='/recipes' component={Recipes} />
                <Route path='/recipe_detail/:id' component={RecipeDetail} />
                <Route path='/shop/:id' component={Shop} />
                <Route path='/product_detail/:id' component={ProductDetails} />
            </Suspense>
            <FrontFooter />
            <WhatsappFab />
        </div>
    )
}
export default MainContainer;