import { Icon, InputAdornment, InputBase } from '@material-ui/core'
import React, { useState, useEffect, useRef } from 'react'

import { useDispatch, useSelector } from "react-redux";
import ordersActions from '../../../../redux/orders/orders.actions';

const SearchInput = React.memo(({ placeholder = "", querySearch, setQuerySearch, dispatchFromSearch }) => {

    const inputSearchRef = useRef();

    useEffect(() => {

        const timer = setTimeout(async () => {
            if (querySearch == inputSearchRef.current.value) {
                //console.log("change search");
                dispatchFromSearch();

            } else {
                console.log("not match");
            }
        }, 500);
        return () => {
            clearTimeout(timer);
        }
    }, [querySearch, inputSearchRef])

    const handleKeyPress = async (event) => {
        if (event.key === 'Enter') {

            /*   const query = querySearch.length > 0 ? `?q=${querySearch}` : "";
              const orders = await dispatch(ordersActions.getOrders(query));
              console.log(orders);
              onChangeOrders(orders); */
        }
    }
    console.log("RENDERING SEARCH");
    const handleChange = e => {
        e.preventDefault()
        const { name, value } = e.target;
        setQuerySearch(value);
    }
    return (
        <InputBase
            inputRef={inputSearchRef}
            fullWidth
            placeholder={placeholder}
            variant="outlined"
            size="small"
            value={querySearch}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            startAdornment={
                <InputAdornment position="start">
                    <Icon>search</Icon>
                </InputAdornment>}
        />
    )
});

export default SearchInput
