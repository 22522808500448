import settings from '../../settings.js';

import { setAlert } from '../alert/alert.actions';

export const fetchStart = () => ({
    type: "FETCH_START"
});
export const fetchSuccess = () => ({
    type: "FETCH_SUCCESS"
});
export const fetchFailure = errorMessage => ({
    type: "FETCH_FAILURE",
    payload: errorMessage
});

const getProducers = () => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());

    try {
        const response = await fetch(`${settings.connectionString}/api/producers/private`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'GET'
        });
        if (response.status >= 400 && response.status < 500) {
            throw "Error" + response.status;
        }
        if (response.status >= 500 && response.status < 600) {
        }
        const res = await response.json();
        console.log("set alert");
        /* dispatch(setAlert({ msg: "hola" })); */
        dispatch({
            type: 'GET_PRODUCERS',
            payload: res
        });

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
};

const getProducersFront = () => async dispatch => {
    dispatch(fetchStart());

    try {
        const response = await fetch(`${settings.connectionString}/api/producers/public`, {
            method: 'GET'
        });
        const res = await response.json();
        dispatch({
            type: 'GET_PRODUCERS_FRONT',
            payload: res
        });

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
};

const getProducer = (id) => async (dispatch, getState) => {
    dispatch(fetchStart());
    try {

        const response = await fetch(`${settings.connectionString}/api/producers/${id}`, {
            method: 'GET'
        });
        const res = await response.json();
        console.log("getproducer response");
        console.log(res);
        res.status = res.status === "1" ? true : false;
        dispatch(fetchSuccess());
        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const addProducer = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("addProducer");
    try {
        console.log(body);
        const response = await fetch(`${settings.connectionString}/api/producers`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        console.log(response);
        const res = await response.json();
        if (res) {
            dispatch(fetchSuccess());
            dispatch(setAlert({ msg: "Productor creado" }));
        }

        return res;

    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo crear el Productor" }));
    }
    return false;
}

const updateProducer = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("updateProducer");
    try {
        const response = await fetch(`${settings.connectionString}/api/producers`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'PUT',
            body: JSON.stringify(body)
        });
        if (response.status >= 400 && response.status < 500) {
            throw "Error" + response.status;
        }
        if (response.status >= 500 && response.status < 600) {
        }
        console.log(response);
        const res = await response.json();
        if (res) {
            dispatch(fetchSuccess());
            dispatch(setAlert({ msg: "Productor actualizado" }));
        }
        return true;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo actualizar el Productor" }));
    }
    return false;
}

const deleteProducer = (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("deleteProducer");
    try {
        const response = await fetch(`${settings.connectionString}/api/producers/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'DELETE'
        });

        console.log(response);
        const res = await response.json();
        if (res) {
            dispatch(getProducers());
            dispatch(setAlert({ msg: "Productor eliminado" }));
        }

        return true;

    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "Error al eliminar" }));
    }
    return false;
}

export default {
    getProducers,
    getProducersFront,
    addProducer,
    getProducer,
    updateProducer,
    deleteProducer
}