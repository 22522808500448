import settings from '../../settings.js';

import { setAlert } from '../alert/alert.actions';

export const fetchStart = () => ({
    type: "FETCH_START_REPORTS"
});
export const fetchSuccess = () => ({
    type: "FETCH_SUCCESS_REPORTS"
});
export const fetchFailure = errorMessage => ({
    type: "FETCH_FAILURE_REPORTS",
    payload: errorMessage
});


const getQuantityProducts = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("updateRecipe");
    try {
        const response = await fetch(`${settings.connectionString}/api/reports/readQuantityProducts`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        /* const text = await response.text();
        console.log(text); */
        const res = await response.json();
        dispatch(fetchSuccess());
        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}
const getMonthlyOrderProducts = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("getMonthlyOrderProducts");
    try {
        const response = await fetch(`${settings.connectionString}/api/reports/monthlyOrderProducts`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        /* const text = await response.text();
        console.log(text); */
        const res = await response.json();
        console.log(res)
        dispatch(fetchSuccess());
        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const getReportBestSellers = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("updateRecipe");
    try {
        const response = await fetch(`${settings.connectionString}/api/reports/readBestSellers`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        const res = await response.json();
        dispatch(fetchSuccess());
        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const getOrders = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("getReportClientsAddresses");
    try {
        const response = await fetch(`${settings.connectionString}/api/reports/readOrders`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        const res = await response.json();
        dispatch(fetchSuccess());
        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const getProducts = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("getReportClientsAddresses");
    try {
        const response = await fetch(`${settings.connectionString}/api/reports/readProducts`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        const res = await response.json();
        dispatch(fetchSuccess());
        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}
const getReportClientsAddresses = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("getReportClientsAddresses");
    try {
        const response = await fetch(`${settings.connectionString}/api/reports/orderAddresses`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        const res = await response.json();
        dispatch(fetchSuccess());
        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const getClients = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("getClients");
    try {
        const response = await fetch(`${settings.connectionString}/api/users/readClients`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'GET'
        });
        console.log(response);
        const res = await response.json();
        console.log("getClients res");
        console.log(res);
        dispatch(fetchSuccess());
        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const savePdf = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("savePdf");
    console.log(body);

    let token = "";
    if (state.userReducer.currentUser) {
        token = state.userReducer.currentUser.token;
    } else {
        token = state.userReducer.currentUserFront.token;
    }

    try {
        const url = `${settings.connectionString}/api/utils/getPDFReport`;
        console.log(url)
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        console.log(response);
        /* const text = await response.text();
        console.log(text); */
        const blob = await response.blob();
        var link = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = link;
        let fileName = "productReport.pdf";
        const { refNumber, firstName, lastName } = body.data;
        if (refNumber) {
            fileName = refNumber + "_" + firstName + "_" + lastName + ".pdf";
        }
        a.download = fileName;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        dispatch(fetchSuccess());
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}
const getExcelReport = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("getExcelReport");
    try {
        const response = await fetch(`${settings.connectionString}/api/utils/getExcelReport`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'GET',
            /* body: JSON.stringify(body) */
        });
        console.log(response);
        const blob = await response.blob();
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = "file.xlsx";
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove();
        dispatch(fetchSuccess());
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}
const reportsActions = {
    getQuantityProducts,
    getMonthlyOrderProducts,
    getReportBestSellers,
    savePdf,
    getExcelReport,
    getReportClientsAddresses,
    getProducts,
    getOrders,
    getClients
}
export default reportsActions;