import React, { useState } from 'react';

import settings from '../settings.js';

import CartActions from '../providers/cart/cart.actions'
import PriceInfo from '../views/website/components/PriceInfo.js';
import { Avatar, Box, makeStyles, Paper, Typography } from '@material-ui/core';
import PriceDiscounts from '../views/website/components/PriceDiscounts.js';
import { amber } from '@material-ui/core/colors';

const useStyles = makeStyles(theme => ({
    alertBackground: {
        backgroundColor: amber[50]
    }
}))

const ProductCard = ({ item }) => {
    //console.log(item);
    const cart = CartActions();
    const classes = useStyles();
    const removeItem = (itemtoRemove) => {
        const newItems = cart.cartItems.filter(item => item.productId !== itemtoRemove.productId);
        cart.setList(newItems);
    }

    return (
        <div className='uk-width-1-1'>
            <div className='uk-card uk-card-body'>
                <div className="uk-grid-small" data-uk-grid>
                    <div className='uk-width-1-1'>
                        <i onClick={() => removeItem(item)} className="material-icons md-icon md-icon-close">close</i>
                    </div>
                    <div className='uk-width-1-1'>
                        <div className='uk-flex uk-flex-middle'>
                            <div className=''>
                                {item.image !== "" ?
                                    <Avatar variant="rounded" src={`${settings.connectionString}/files/products/${item.image}`}></Avatar>
                                    :
                                    <Avatar src={`${settings.connectionString}/files/products/general_image.jpg`}></Avatar>
                                }
                            </div>
                            <div style={{ marginLeft: "8px" }}>
                                <h6 className=" uk-text-left md-color-blue-grey-900 uk-margin-top">{item.name}</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="uk-grid-small" data-uk-grid>
                    <div className='uk-width-1-2'>
                        {item && item.quantity > 0 ? (
                            <div className='md-bg-grey-200 card-button'>
                                <span><button onClick={() => cart.removeItem(item.productId)} className="md-btn md-btn-primary md-btn-wave-light card-button-left">-</button></span>
                                <span className='card-button-center md-color-blue-grey-900'>{item.quantity}</span>
                                <span><button onClick={() => cart.addItem(item)} className="md-btn md-btn-primary md-btn-wave-light card-button-right">+</button></span>
                            </div>
                        ) : (
                            <a onClick={() => cart.addItem(item)} className="md-btn md-btn-primary md-btn-wave-light"><i className='material-icons'>shopping_cart</i> Agregar</a>
                        )}
                    </div>
                    <div className='uk-width-1-2 '>
                        <PriceInfo item={item} showUnitPrice={false} />
                    </div>
                    <div className='uk-width-1-1'>
                        {item.auto_discounts.length > 0 &&
                            <Paper variant="outlined" className={classes.alertBackground}>
                                <Box p={1}>
                                    <Typography variant="subtitle2" color="initial">
                                        Compra más y ahorra
                                    </Typography>
                                    <PriceDiscounts item={item} />
                                </Box>
                            </Paper>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ProductCard;