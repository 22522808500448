const INITIAL_STATE = {
  recipes: [],
  loading: false,
  errorMessage: undefined
};

const recipesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_START":
      return {
        ...state,
        loading: true
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        loading: false
      };
    case "GET_RECIPES":
      return {
        ...state,
        recipes: action.payload,
        loading: false
      };
    case "GET_RECIPES_FRONT":
      return {
        ...state,
        recipes: action.payload,
        loading: false
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        errorMessage: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default recipesReducer;