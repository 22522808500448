import React, { useEffect } from 'react'
import UIkit from 'uikit';
import MDUIkit from "md-uikit";
import { FormattedMessage } from 'react-intl';

import CartActions from '../../../providers/cart/cart.actions'

import { useDispatch, useSelector } from "react-redux";
import { setAlert } from '../../../redux/alert/alert.actions';

const AddToCardButton = ({ item }) => {

    useEffect(() => {
        MDUIkit.components();
    }, [])

    const cart = CartActions();
    const dispatch = useDispatch();
    const userReducer = useSelector(state => state.userReducer);

    const onClickAdd = () => {
        let message = " was added.";
        if (userReducer.language === "es") {
            message = " fue agregado.";
        }
        dispatch(setAlert({ msg: item.name + message }));
        cart.addItem(item);
    }
    const selectedItem = cart.cartItems.find(obj => obj.productId == item.productId)

    const renderButton = () => {
        let disabled = false;
        if (item.stock_active && parseInt(item.stock_number) <= 0) {
            disabled = true;
        }
        return (
            <button onClick={onClickAdd}
                className="md-btn md-btn-primary ripple-surface md-btn-icon"
                disabled={disabled}>
                <i className='material-icons'>shopping_cart</i>
                {disabled ?
                    <FormattedMessage
                        id="component_button_outStock"
                        defaultMessage="OUT OF STOCK"
                    />
                    :
                    <FormattedMessage
                        id="component_button_addToCart"
                        defaultMessage="ADD TO CART"
                    />}

            </button>
        );
    }

    const addItem = item => e => {
        console.log("addItem "+item.stock_number+" "+selectedItem.quantity);
        console.log(item);
        e.preventDefault();
        if (item.stock_active) {
            if (selectedItem.quantity < parseInt(item.stock_number)) {
                cart.addItem(item);
            } else {
                let message = "Sorry we don't have more " + item.name + " in stock.";
                if (userReducer.language === "es") {
                    message = "Lo sentimos no tenemos más " + item.name + " en stock.";
                }
                dispatch(setAlert({ msg: message }));
            }
        } else {
            cart.addItem(item);
        }
    }
    return (
        <>
            {selectedItem && selectedItem.quantity > 0 ?
                <div className='md-bg-grey-200 card-button '>
                    <span><button onClick={() => cart.removeItem(item.productId)} className="md-btn md-btn-primary md-btn-wave-light card-button-left">-</button></span>
                    <span className='card-button-center uk-text-center'>{selectedItem.quantity}</span>
                    <span><button onClick={addItem(item)} className="md-btn md-btn-primary md-btn-wave-light card-button-right">+</button></span>
                </div>
                : renderButton()
            }
        </>
    )
}

export default AddToCardButton
