import { Box, Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import reportsActions from '../../../../redux/reports/reports.actions';

import settings from '../../../../settings.js';

const useStyles = makeStyles({
    table: {
        /* minWidth: 650, */
    },
});

const MonthlyProductsReport = ({ dataResult, dateFrom, dateTo }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const onSavePDF = async () => {
        console.log("onSavePDF")
        const data = {
            data: {
                data: dataResult,
                dateFrom: dateFrom.toLocaleString('en-US'),
                dateTo: dateTo.toLocaleString('en-US')
            },
            type: "monthly_orders_report"
        }
        await dispatch(reportsActions.savePdf(data));
    }
    return (
        <>
             <Box pb={1} pt={2} display="flex" justifyContent="flex-end">
                <Button size="small" variant="outlined" color="primary" href={`${settings.connectionString}/reports/monthlyOrderProducts.php?dateFrom=${dateFrom.toLocaleString('en-US')}&dateTo=${dateTo.toLocaleString('en-US')}`}>
                    Exportar a excel</Button>
                <Box ml={1}>
                    <Button size="small" variant="outlined" color="primary" onClick={onSavePDF}>
                        Exportar a pdf</Button>
                </Box>
            </Box>
            <Box py={1}>
                <Typography variant="body1" color="initial">Solo se muestran las ordenes en estado "Confirmado" y "Entregado" </Typography>
            </Box>
            
            <Paper variant="outlined" >
                <Box overflow="auto">
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Zona</TableCell>
                                <TableCell>Producto</TableCell>
                                <TableCell align="right">Cantidad</TableCell>
                                <TableCell align="right">Costo</TableCell>
                                <TableCell align="right">Precio</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataResult.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell >{row.Zone}</TableCell>
                                    <TableCell >{row.name}</TableCell>
                                    <TableCell align="right">{row.quantity}</TableCell>
                                    <TableCell align="right">{row.cost}</TableCell>
                                    <TableCell align="right">{row.price}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </Paper>
        </>
    )
}

export default MonthlyProductsReport
