import settings from '../../settings.js';

import { setAlert } from '../alert/alert.actions';

export const fetchStart = () => ({
    type: "FETCH_START_DISCOUNTS"
});
export const fetchSuccess = () => ({
    type: "FETCH_SUCCESS_DISCOUNTS"
});
export const fetchFailure = errorMessage => ({
    type: "FETCH_FAILURE_DISCOUNTS",
    payload: errorMessage
});

const getDiscounts = () => async (dispatch, getState) => {

    const state = getState();
    dispatch(fetchStart());

    try {
        const response = await fetch(`${settings.connectionString}/api/autodiscounts/private`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'GET'
        });
        if (response.status >= 400 && response.status < 500) {
            throw "Error" + response.status;
        }
        if (response.status >= 500 && response.status < 600) {
        }
        /* const text = await response.text();
        console.log(text); */
        const res = await response.json();
        console.log("getDiscounts");
        console.log(res);
        /* dispatch(setAlert({ msg: "hola" })); */
        dispatch({
            type: 'GET_DISCOUNTS',
            payload: res
        });

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
};

/* const getDiscountsFront = () => async dispatch => {
    dispatch(fetchStart());

    try {
        const response = await fetch(`${settings.connectionString}/api/auto_discount_read_frontend.php`, {
            method: 'POST'
        });
        const res = await response.json();
        dispatch({
            type: 'GET_DISCOUNTS_FRONT',
            payload: res
        });

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}; */

const getDiscount = (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("getDiscount");
    try {

        const response = await fetch(`${settings.connectionString}/api/autodiscounts/${id}`, {
            method: 'GET'
        });
        const res = await response.json();

        console.log("getDiscount response");
        console.log(res);
        res.status = res.status === "1" ? true : false;
        dispatch(fetchSuccess());

        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const addDiscount = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("addDiscount");
    try {
        const response = await fetch(`${settings.connectionString}/api/autodiscounts`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        console.log(response);
        const res = await response.json();
        if (res) {
            dispatch(setAlert({ msg: "Producto creado" }));
        }

        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo crear el Discount" }));
    }
    return false;
}

const updateDiscount = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("updateDiscount");
    try {
        const response = await fetch(`${settings.connectionString}/api/autodiscounts`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'PUT',
            body: JSON.stringify(body)
        });
        if (response.status >= 400 && response.status < 500) {
            throw "Error" + response.status;
        }
        if (response.status >= 500 && response.status < 600) {
        }
        console.log(response);
        const res = await response.json();
        dispatch(fetchSuccess());
        if (res) {
            dispatch(fetchSuccess());
            dispatch(setAlert({ msg: "Descuento actualizada" }));
        }
        return true;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo actualizar el Discount" }));
    }
    return false;
}

const deleteDiscount = (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("deleteDiscount");
    try {
        const response = await fetch(`${settings.connectionString}/api/autodiscounts/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'DELETE',
        });

        console.log(response);
        const res = await response.json();
        if (res) {
            dispatch(getDiscounts());
            dispatch(setAlert({ msg: "Descuento eliminado" }));
        }

        return true;

    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "Error al eliminar" }));
    }
    return false;
}
const discountsActions = {
    getDiscounts,
    /* getDiscountsFront, */
    addDiscount,
    getDiscount,
    updateDiscount,
    deleteDiscount
}
export default discountsActions;