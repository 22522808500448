import React, { useState } from 'react';

import Main from './MainContent.js';
import Header from './Header.js';
import Sidebar from './Sidebar.js';
import { createMuiTheme, makeStyles, responsiveFontSizes, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import SnackBar from './components/utils/SnackBar.js';

const drawerWidth = 256;
const useStyles = makeStyles((theme) => ({

  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const AdminPanel = () => {

  const [openDrawer, setOpenDrawer] = useState(false)
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Header setOpenDrawer={setOpenDrawer} openDrawer={!isMobile ? openDrawer : false} classAppBarShift={classes.appBarShift} />
      <Sidebar setOpenDrawer={setOpenDrawer} openDrawer={openDrawer} isMobile={isMobile}/>
      <Main openDrawer={!isMobile ? openDrawer : false} />
      {/* <SnackBar /> */}
    </div>
  )
}
export default AdminPanel;