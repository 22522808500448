import settings from '../../settings.js';

import { setAlert } from '../alert/alert.actions';

export const fetchStart = () => ({
    type: "FETCH_START"
});
export const fetchSuccess = () => ({
    type: "FETCH_SUCCESS"
});
export const fetchFailure = errorMessage => ({
    type: "FETCH_FAILURE",
    payload: errorMessage
});

const getCategoriesZones = () => async dispatch => {

    //dispatch(fetchStart());

    console.log("getCategoriesZones");
    try {
        const status = 1;
        const response = await fetch(`${settings.connectionString}/api/deliveryzones/getCategories/${status}`, {
            method: 'GET'
        });
        if (response.status >= 400 && response.status < 500) {
            throw "Error" + response.status;
        }
        if (response.status >= 500 && response.status < 600) {
        }
        const res = await response.json();
        dispatch(fetchSuccess());
        console.log(res);
        return res;

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
};
const getZones = () => async (dispatch, getState) => {
    const state = getState();
    //dispatch(fetchStart());

    try {
        const response = await fetch(`${settings.connectionString}/api/deliveryzones/private`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'GET'
        });
        if (response.status >= 400 && response.status < 500) {
            throw "Error" + response.status;
        }
        if (response.status >= 500 && response.status < 600) {
        }
        const res = await response.json();
        console.log("set alert");
        /* dispatch(setAlert({ msg: "hola" })); */
        dispatch({
            type: 'GET_ZONES',
            payload: res
        });

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
};

const getZonesFront = () => async dispatch => {
    dispatch(fetchStart());

    try {
        
        const response = await fetch(`${settings.connectionString}/api/deliveryzones/public`, {
            method: 'GET'
        });
        const res = await response.json();
        dispatch({
            type: 'GET_ZONES_FRONT',
            payload: res
        });

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
};

const getZone = (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("getZone");
    try {
        const response = await fetch(`${settings.connectionString}/api/deliveryzones/${id}`, {
            
            method: 'GET'
        });
        const res = await response.json();

        console.log("getdelivery_zone response");
        console.log(res);
        res.status = res.status === "1" ? true : false;
        dispatch(fetchSuccess());

        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const addZone = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("addZone");
    try {
        const response = await fetch(`${settings.connectionString}/api/deliveryzones`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        console.log(response);
        const res = await response.json();
        if (res) {
            dispatch(setAlert({ msg: "Creado" }));
        }

        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo crear el delivery_zone" }));
    }
    return false;
}

const updateZone = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("updateZone");
    try {
        const response = await fetch(`${settings.connectionString}/api/deliveryzones`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'PUT',
            body: JSON.stringify(body)
        });
        console.log(response);
        const res = await response.json();
        dispatch(fetchSuccess());
        if (res) {
            dispatch(fetchSuccess());
            dispatch(setAlert({ msg: "Actualizado" }));
        }
        return true;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo actualizar" }));
    }
    return false;
}

const deleteZone = (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("deleteZone");
    try {
        const response = await fetch(`${settings.connectionString}/api/deliveryzones/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'DELETE'
        });

        console.log(response);
        const res = await response.json();
        if (res) {
            dispatch(getZones());
            dispatch(setAlert({ msg: "Eliminado" }));
        }

        return true;

    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "Error al eliminar" }));
    }
    return false;
}

export default {
    getZones,
    getZonesFront,
    addZone,
    getZone,
    updateZone,
    deleteZone,
    getCategoriesZones
}