const INITIAL_STATE = {
  categories: [],
  loading: false,
  errorMessage: undefined
};

const productsCategoriesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_START":
      return {
        ...state,
        loading: true
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        loading: false
      };
    case "GET_CATEGORIES":
      return {
        ...state,
        categories: action.payload,
        loading: false
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        errorMessage: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default productsCategoriesReducer;