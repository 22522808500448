import { Box, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles(theme => ({
    mainBackground: {
        backgroundColor: theme.palette.action.hover
    }
}));
const PriceDiscounts = ({ item }) => {
    const classes = useStyles();
    return (
        <Box mb={2}>
            {item.auto_discounts && item.auto_discounts.map((item,index) => (
                <Box key={index} p={0.5} m={0.5} className={classes.mainBackground}>{item.if_amount} x S/.{(parseFloat(item.if_amount) * parseFloat(item.new_price)).toFixed(2)} </Box>
            ))}
        </Box>
    )
}

export default PriceDiscounts
