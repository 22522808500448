import {
    Fab, Icon, Paper, Grid, Box, Divider,
    Typography, CircularProgress, Avatar
} from '@material-ui/core';

import { Link, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react'
import ContentBefore from './components/ContentBefore';

import { useDispatch, useSelector } from "react-redux";
import usersActions from '../../redux/user/user.actions';

import settings from '../../settings.js';
import GeneralContainer from './components/GeneralContainer';
import FAB from './components/utils/FAB';
import GeneralRow from './components/utils/GeneralRow';
import ResponsiveMenuButton from './components/utils/ResponsiveMenuButton';
import DeleteModal from './components/modals/DeleteModal';

const UserAdmins = () => {

    const userReducer = useSelector(state => state.userReducer);
    const dispatch = useDispatch();

    const history = useHistory();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({
        orderId: 0
    });

    /* const classes = useStyles(); */

    useEffect(() => {
        dispatch(usersActions.getUsersByRol('admin'));
    }, []);

    const menu = [
        {
            title: "Ver/Editar",
            icon: "visibility",
            action: e => onClickView(e)
        },
        {
            title: "Eliminar",
            icon: "delete",
            action: e => onClickDelete(e)
        },
    ]

    const onClickView = item => {
        console.log("onClickView");
        history.push(`/admin/user_detail/${item.userId}`)
    }

    const onClickDelete = item => {
        setSelectedItem(item);
        setShowDeleteModal(true);
    }
    const deleteProgram = item => {
        const result = dispatch(usersActions.deleteUser(selectedItem.userId));
        if (result) {
            setShowDeleteModal(false);
        }
    }

    return (
        <>
            <ContentBefore title="Usuarios" />
            <GeneralContainer>
                <Paper >
                    {userReducer.loading ?
                        <CircularProgress />
                        :
                        <>
                            {userReducer.users.length > 0 && userReducer.users.map((item, index) => (
                                <>
                                    <GeneralRow>
                                        <Box display="flex" alignItems="center">
                                            <Box mr={1}>
                                                <Avatar style={{ width: "80px", height: "80px" }} variant="rounded" />
                                            </Box>
                                            <Box flexGrow={1}>
                                                <Typography component={Link} to={`/admin/user_detail/${item.userId}`} variant="h6" color="initial">
                                                    {item.firstName} {item.lastName}
                                                </Typography>
                                                <Typography component="span" variant="body1" color="initial">
                                                    {item.status == "1" ?
                                                        <Box fontWeight="fontWeightBold" color="success.main">Activo</Box>
                                                        :
                                                        <Box fontWeight="fontWeightBold" color="text.secondary">Inactivo</Box>
                                                    }
                                                </Typography>
                                            </Box>
                                            <Box >
                                                <ResponsiveMenuButton menu={menu}
                                                    item={item} title={item.firstName + " " + item.lastName} />
                                            </Box>
                                        </Box>
                                    </GeneralRow>
                                </>
                            ))}
                        </>
                    }
                </Paper>
            </GeneralContainer>
            <DeleteModal showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                title={selectedItem.firstName + " " + selectedItem.lastName}
                action={deleteProgram}
            />
            <FAB component={Link} to="/admin/user_detail/0">
                <Icon>add</Icon>
            </FAB>
        </>
    )
}

export default UserAdmins
