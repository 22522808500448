import { FormControlLabel, Grid, Typography, Switch, TextField, CircularProgress, Box, MenuItem, Button, Icon, Snackbar, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import catActions from '../../redux/product_categories/product_categories.actions';

import { setAlert } from '../../redux/alert/alert.actions';
import SubCategoryForm from './components/blocks/SubCategoryForm';

const ProductCategoriesInner = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const loading = useSelector(state => state.productsCategoriesReducer.loading);
    const categories = useSelector(state => state.productsCategoriesReducer.categories);

    const [categoryInfo, setCategoryInfo] = useState({
        name: "",
        name_en: "",
        status: false,
    })
    const [addSubcategory, setAddSubcategory] = useState(false)
    const { categoryId, parentId } = useParams();

    useEffect(() => {
        getCategoryInfo();

    }, [categoryId])

    async function getCategoryInfo () {
        console.log("getCategoryInfo...")
        if (categoryId !== "0") {
            const data = await dispatch(catActions.getCategory(categoryId));
            console.log(data)
            setCategoryInfo(data);
        } else {
            setCategoryInfo({
                name: "",
                name_en: "",
                status: true,
            })
        }
    }
    async function insertCategory() {
        const req = {
            "name": categoryInfo.name,
            "name_en": categoryInfo.name_en,
            "status": categoryInfo.status ? 1 : 0,
            "parentId": parentId
        };
        const response = await dispatch(catActions.addCategory(req));
        if (response) {
            if (categoryInfo.parentId === "0") {
                dispatch(setAlert({ msg: "Categoria creada" }));
                history.push("/admin/products_categories");
            }
        }
    }
    async function updateCategory() {
        const req = {
            "categoryId": categoryId,
            "name": categoryInfo.name,
            "name_en": categoryInfo.name_en,
            "status": categoryInfo.status ? 1 : 0,
            "parentId": categoryInfo.parentId
        };
        await dispatch(catActions.updateCategory(req));

    }
    const handleChange = event => {
        /*  event.preventDefault(); */
        const { value, name } = event.target;
        console.log(name);
        console.log(name + " " + value);
        setCategoryInfo({ ...categoryInfo, [name]: value });
    }
    const handleSwitch = event => {
        event.preventDefault();
        const { checked, name } = event.target;
        setCategoryInfo({ ...categoryInfo, [name]: checked });
    }

    const onClickSave = async event => {
        event.preventDefault();
        let flagWhites = true;
        let flagGeneral = true;
        if (categoryInfo.name === "" || categoryInfo.name_en === "") {
            flagWhites = false;
        }
        if (!flagWhites) {
            flagGeneral = false;
            dispatch(setAlert({ msg: 'Complete la información para continuar' }));
        }
        console.log(flagGeneral);
        if (flagGeneral) {
            if (categoryId !== "0") {
                updateCategory();
            } else {
                insertCategory();
            }
        }
    }
    const deleteProduct = categoryId => async (e) => {
        e.preventDefault();

        if (window.confirm('Estas Seguro?')) {
            await dispatch(catActions.deleteCategory(categoryId, parentId));
            history.push("/admin/products_categories");
        }
    }
    console.log(categoryInfo);
    return (
        <>
            {loading && categoryInfo.name === "" ?
                <Box width="100%" align="center"><CircularProgress /></Box>
                :
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" color="initial">
                            {parentId === "0" ?
                                "Categoría" : "Subcategoría"
                            }
                        </Typography>
                        <Typography variant="body1" color="initial">
                            {categoryId === "0" ?
                                "Agregar nueva" : categoryInfo.name
                            }
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name="name"
                            value={categoryInfo.name}
                            onChange={handleChange}
                            variant="outlined"
                            label="Nombre"
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name="name_en"
                            value={categoryInfo.name_en}
                            onChange={handleChange}
                            variant="outlined"
                            label="Nombre Ingles"
                        />
                    </Grid>
                    {parentId !== "0" &&
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                select
                                label="Categoría Padre"
                                name="parentId"
                                value={categoryInfo.parentId}
                                onChange={handleChange}
                                variant="outlined"
                            >
                                {categories.map(item => (
                                    <MenuItem value={item.categoryId}>{item.name}</MenuItem>
                                ))}
                            </TextField>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={categoryInfo.status}
                                    onChange={handleSwitch}
                                    name="status"
                                    color="primary"
                                />
                            }
                            label={categoryInfo.status ? "Categoría activa" : "Categoría inactiva"}
                        />
                    </Grid>
                    {categoryInfo.parentId === "0" &&
                        <Grid item xs={12}>
                            <Typography variant="body1" color="initial">
                                Subcategorias
                            </Typography>
                            <List dense>
                                {categoryInfo.subCategories && categoryInfo.subCategories.map((innerItem, index) => (
                                    <>
                                        <ListItem key={index}>
                                            <ListItemText primary={innerItem.name} />
                                            {/* <ListItemSecondaryAction>
                                                <IconButton onClick={deleteProduct(innerItem.categoryId)}>
                                                    <Icon>delete</Icon>
                                                </IconButton>
                                            </ListItemSecondaryAction> */}
                                        </ListItem>
                                    </>
                                ))}
                            </List>
                            {addSubcategory ?
                                <SubCategoryForm getCategoryInfo={getCategoryInfo} parentId={categoryId} setAddSubcategory={setAddSubcategory} />
                                :
                                <Button size="small" onClick={() => setAddSubcategory(true)} variant="text" startIcon={<Icon>add</Icon>} color="primary">
                                    agregar subcategoria
                        </Button>
                            }
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                            <Box flexGrow={1}>
                                {categoryId !== "0" &&
                                    <Button onClick={deleteProduct(categoryId)} variant="text"  >
                                        <Box /* color="error.main" */>Eliminar Categoría</Box>
                                    </Button>
                                }
                            </Box>
                            <Box>
                                <Button onClick={onClickSave}
                                    variant="outlined" startIcon={<Icon>save</Icon>}
                                    color="primary"
                                    disabled={loading}>
                                    Guardar información
                        </Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>

            }
        </>
    )
}

export default ProductCategoriesInner
