import React, { Component } from 'react';
import { Link } from "react-router-dom";
// Images
/* import AiSurLogo from '../../../assets/img/aisur.jpg'; */

import SueloVerde from '../../assets/img/SueloVerde.png';
import { Box, MenuItem, TextField, Typography, Grid, Hidden } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { withStyles } from '@material-ui/core/styles';
import userActions from "../../redux/user/user.actions";
import { useDispatch, useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles((theme) => ({
  Javatages: {
    fontFamily: "Javatages",
    color: "#47563A"
  },
  darkGreen: {
    color: "#47563A"
  },
}))

const CssTextField = withStyles({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: 'green',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: 'white',
      },
      '&:hover fieldset': {
        borderColor: 'grey',
      },/*
      '&.Mui-focused fieldset': {
        borderColor: 'green',
      }, */
    },
    '& .MuiSelect-root': {
      color: "white"
    },
    '& .MuiSvgIcon-root': {
      color: "white"
    },
  },
})(TextField);

const Footer = () => {

  const dispatch = useDispatch();
  const userReducer = useSelector(state => state.userReducer);
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = e => {
    const { name, value } = e.target;
    /* console.log(value); */
    dispatch(userActions.setLanguage(value));
  }

  return (
    <div id="footer" className="uk-section-secondary" style={{ backgroundColor: "#819164" }}>
      <div className="uk-container" style={{ paddingTop: "20px" }}>
        <Box p={3} pb={6}>
          <Grid container spacing={3}>
            <Grid item xs={12} align="center">
              <Typography component="a" target="_blank" href="https://www.instagram.com/suelosagrado_initiative/" variant="h4" className={`${classes.Javatages}`} style={{ color: "#47563A" }} >
                <i className="fab fa-instagram" style={{ color: "#47563A", paddingRight: "8px" }} />
                @suelosagrado_initiative</Typography>
            </Grid>
            <Grid item xs={6} md={4}>
              <img width='150px' src={SueloVerde} alt="" />

            </Grid>
            <Grid item xs={6} md={4} {...(isMobile ? { align: "right" } : { align: "left" })}>
              <Box mb={3}>
                <Typography variant="h5" color="initial" style={{ fontWeight: "700" }}>
                  <FormattedMessage
                    id="footer_title_us"
                    defaultMessage="NOSOTROS"
                  />
                </Typography>
              </Box>
              <Box /* pr={1} */>
                <Typography variant="body1" color="initial">
                  <FormattedMessage
                    id="footer_us_description"
                    defaultMessage="Suelo Sagrado is an initiative that aims to transform the agriculture in the Sacred Valley of Cusco
                    "
                  />
                </Typography>
              </Box>
            </Grid>
            {/* <Hidden smUp> */}
            <Hidden mdUp>
              <Grid item xs={6} md={4}>

                <Box mb={3}>
                  <Typography variant="h5" color="initial" style={{ fontWeight: "700" }}>
                    <FormattedMessage
                      id="footer_language"
                      defaultMessage="LANGUAGE"
                    />
                  </Typography>
                </Box>
                <CssTextField
                  fullWidth
                  select
                  name="language"
                  value={userReducer.language}
                  onChange={handleChange}
                  variant="outlined"
                >
                  <MenuItem value={"es"}>Español</MenuItem>
                  <MenuItem value={"en"}>English</MenuItem>
                </CssTextField>
              </Grid>
            </Hidden>
            <Grid item xs={6} md={4}>
              <Box {...isMobile ? { align: "right" } : { align: "left" }}>
                <Box mb={3} >
                  <Typography variant="h5" color="initial" style={{ fontWeight: "700" }}>
                    <FormattedMessage
                      id="footer_more_info"
                      defaultMessage="More info"
                    />
                  </Typography>
                </Box>
                <Box>
                  <Typography component={Link} to="/" variant="body1" color="initial" >
                    <FormattedMessage
                      id="footer_faq"
                      defaultMessage="FAQ"
                    />
                  </Typography>
                </Box>
                <Box>
                  <Typography component={Link} to="/" variant="body1" color="initial" >
                    <FormattedMessage
                      id="footer_terms"
                      defaultMessage="TERMS AND CONDITIONS"
                    />
                  </Typography>
                </Box>
                <Box>
                  <Typography component={Link} to="/" variant="body1" color="initial" >
                    <FormattedMessage
                      id="footer_privacy"
                      defaultMessage="PRIVACY POLICY"
                    />
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid container spacing={3} alignItems="flex-end">
              <Grid item xs={6} md={4} >
                <Typography variant="body1" color="initial" className={classes.darkGreen} style={{ fontWeight: "700" }}>
                  Suelo Sagrado © 2021. All Rights Reserved.
              </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Typography variant="body1" color="initial" className={classes.darkGreen} style={{ fontWeight: "700" }}>
                  Design by Ardea Creative, Development by Mangata Tech
              </Typography>
              </Grid>
              <Grid item xs={6} md={4}>
                <Box mb={3}>
                  <Typography variant="h5" color="initial" style={{ fontWeight: "700" }}>
                    <FormattedMessage
                      id="footer_language"
                      defaultMessage="LANGUAGE"
                    />
                  </Typography>
                </Box>
                <CssTextField
                  fullWidth
                  select
                  name="language"
                  value={userReducer.language}
                  onChange={handleChange}
                  variant="outlined"
                >
                  <MenuItem value={"es"}>Español</MenuItem>
                  <MenuItem value={"en"}>English</MenuItem>
                </CssTextField>
              </Grid>
            </Grid>
          </Hidden>
          <Hidden mdUp>
            <Grid container spacing={3} >
              <Hidden smDown>
                <Grid item xs={6}>
                  <Box mb={3}>
                    <Typography variant="h5" color="initial" style={{ fontWeight: "700" }}>
                      <FormattedMessage
                        id="footer_language"
                        defaultMessage="LANGUAGE"
                      />
                    </Typography>
                  </Box>
                  <CssTextField
                    fullWidth
                    select
                    name="language"
                    value={userReducer.language}
                    onChange={handleChange}
                    variant="outlined"
                  >
                    <MenuItem value={"es"}>Español</MenuItem>
                    <MenuItem value={"en"}>English</MenuItem>
                  </CssTextField>
                </Grid>
              </Hidden>
              <Grid item xs={12}>
                <Box {...isMobile ? { pt: 4 } : { pt: 0 }}>
                  <Typography variant="body1" color="initial" className={classes.darkGreen} style={{ fontWeight: "700" }}>
                    Design by Ardea Creative, Development by Mangata Tech
              </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} >
                <Typography variant="body1" color="initial" className={classes.darkGreen} style={{ fontWeight: "700" }}>
                  Suelo Sagrado © 2021. All Rights Reserved.
              </Typography>
              </Grid>
            </Grid>
          </Hidden>
        </Box>
      </div>
    </div>
  );
}
export default Footer;