const INITIAL_STATE = {
  currentUser: JSON.parse(sessionStorage.getItem("currentUser")),
  currentUserFront: JSON.parse(sessionStorage.getItem("currentUserFront")),
  isAuthenticated: false,
  users: [],
  loading: false,
  language: localStorage.language != null ? localStorage.language : 'es',
  errorMessage: undefined
};

const userReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_START_USER":
      return {
        ...state,
        loading: true
      };
    case "FETCH_SUCCESS_USER":
      return {
        ...state,
        loading: false
      };
    case "LOGIN_SUCCESS":
      sessionStorage.setItem('currentUser', JSON.stringify(action.payload));
      return {
        ...state,
        currentUser: action.payload,
        loading: false
      };
    case "LOGIN_USER_SUCCESS":
      sessionStorage.setItem('currentUserFront', JSON.stringify(action.payload));
      return {
        ...state,
        currentUserFront: action.payload,
        loading: false
      };
    case "FETCH_FAILURE_USER":
      return {
        ...state,
        errorMessage: action.payload,
        loading: false
      };
    case "LOGOUT":
      sessionStorage.removeItem('currentUser');
      return {
        ...state,
        currentUser: null,
        loading: false
      };
    case "LOGOUT_FRONT":
      sessionStorage.removeItem('currentUserFront');
      return {
        ...state,
        currentUserFront: null,
        loading: false
      };
    case "SET_LANGUAGE":
      localStorage.setItem("language", action.payload);
      return {
        ...state,
        language: action.payload
      };
    case "GET_USERS":
      return {
        ...state,
        users: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default userReducer;