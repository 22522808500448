import React, { useState, useEffect } from 'react'
import {
    Checkbox,
    ListItemSecondaryAction, ListItemText, ListItemIcon, ListItem, List,
    Icon, IconButton, Typography, Box, Divider, makeStyles
} from '@material-ui/core'
import { Link } from "react-router-dom";
const useStates = makeStyles((theme) => ({
    pointer: {
        cursor: "pointer",
        "&:hover": {
            color: theme.palette.primary.main
        }
    }
}));
const CategoriesListItem = ({ item }) => {
    const classes = useStates();
    const [open, setOpen] = useState(false)
    /* const [item, setItem] = useState({
        registered: false
    }) */

    useEffect(() => {
        /*  const index = values.findIndex(inner => inner.categoryId == selectedId);
         const newObj = [...values];
         setItem(newObj[index]);
         if (newObj[index].registered) {
             console.log(newObj[index].name)
             setOpen(true);
         }
         let hasActiveChildren;
         if (newObj[index].subCategories) {
             hasActiveChildren = newObj[index].subCategories.find(item => item.registered === true);
         }
         if (hasActiveChildren) {
             setOpen(true);
         } */

    }, [])
    /* const () =>onClickCategory = (item) => e => {
        e.preventDefault();
        console.log("onClickCategory" + item.categoryId)
        onClickCategory(item);
    } */
    return (
        <>
            {item.subCategories ?
                <>
                    <ListItem  >
                        <ListItemText primary={
                            <Box display="flex" alignItems="center">
                                <IconButton size="small" onClick={() => setOpen(!open)}>
                                    {open ? <Icon fontSize="small">expand_less</Icon> : <Icon fontSize="small">expand_more</Icon>}
                                </IconButton>
                                <Box ml={1} component={Link} to={`/admin/products_categories/${item.categoryId}/0`} className={classes.pointer} >
                                    <Typography variant="body2" color="initial">{item.name}</Typography>
                                </Box>
                            </Box>
                        } />
                    </ListItem>
                    {open &&
                        <Box ml={6}>
                            <List dense>
                                {item.subCategories.map((innerItem, index) => (
                                    <Box key={index}>
                                        <ListItem className={classes.pointer} component={Link} to={`/admin/products_categories/${innerItem.categoryId}/${innerItem.parentId}/`}>
                                            <ListItemText primary={innerItem.name} />
                                        </ListItem>
                                    </Box>
                                ))}
                            </List>
                        </Box>
                    }
                    <Divider />
                </>
                :
                <>
                    <ListItem >
                        <Box ml={4} className={classes.pointer} component={Link} to={`/admin/products_categories/${item.categoryId}/0`}>
                            <ListItemText primary={
                                <Typography variant="body2" color="initial">{item.name}</Typography>
                            } />
                        </Box>
                    </ListItem>
                    <Divider />
                </>
            }
        </>
    )
}

export default CategoriesListItem
