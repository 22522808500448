import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

// Images
import logo from '../../assets/img/main_logo_yellow.png';



import CartActions from '../../providers/cart/cart.actions'
import UIkit from 'uikit';
import { FormattedMessage } from 'react-intl';
import Button from '@material-ui/core/Button'
import { AppBar, Box, Icon, Toolbar } from '@material-ui/core';


const HeaderCart = () => {
  const cart = CartActions();
  let history = useHistory();
  const toggleCart = () => {
    console.log(document.querySelector("#cart_container"));
    UIkit.notification("welcome");
    UIkit.offcanvas("#cart_container").show();
  }
  function renderTotal() {
    console.log(cart.cartItems);
    let total = 0;
    for (let index = 0; index < cart.cartItems.length; index++) {
      const element = cart.cartItems[index];
      var suma = (parseFloat(element.price) * element.quantity);
      total += suma;
    }
    return (
      <p className="uk-margin-remove uk-text-left"><strong>S/. {total.toFixed(2)}</strong></p>
    )
  }
  return (
    <AppBar
      elevation={4}
      position="sticky"
      style={{ backgroundColor: "white" }}
    >
      <Toolbar>
        <Box>
          <Box ml={2}>
            <Button onClick={() => history.push("/shop/1")} variant="outlined" color="primary"
              startIcon={<Icon>navigate_before</Icon>}>
              <FormattedMessage
                id="checkout_header_button"
                defaultMessage="Continue shopping"
              />
            </Button></Box>

        </Box>
        <Box className="uk-navbar-center uk-visible@m">
          <div className="">
            <a href="#">
              <img src={logo} width="50px" />
            </a>
          </div>
        </Box>
        <Box className="uk-navbar-right uk-hidden@m">
          <a href="#">
            <img src={logo} className="uk-margin-right" width="50px" />
          </a>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
export default HeaderCart;