import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import React from 'react'

import settings from '../../../../settings.js';

const BestSellersReport = ({ dataResult, dateFrom, dateTo }) => {
    return (
        <>
            <Box pb={1} pt={2} display="flex" justifyContent="flex-end">
                <Button size="small" variant="outlined" color="primary" href={`${settings.connectionString}/reports/bestSellers.php?dateFrom=${dateFrom.toLocaleString('en-US')}&dateTo=${dateTo.toLocaleString('en-US')}`}>
                    Exportar a excel</Button>
                
            </Box>
            <Table /* className={classes.table} */ aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Producto</TableCell>
                        <TableCell align="right">Nro de pedidos</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataResult.map((row) => (
                        <TableRow key={row.name}>
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="right">{row.total}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    )
}

export default BestSellersReport
