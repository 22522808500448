import { Paper, Grid, Typography, FormControlLabel, Box, Button, Icon, Fab, makeStyles, CircularProgress } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { Route, Switch } from 'react-router';
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import productsCategoriesActions from '../../redux/product_categories/product_categories.actions';

import ProductCategoriesInner from './ProductCategoriesInner';
import GeneralContainer from './components/GeneralContainer';
import ProductCategoriesList from './components/blocks/ProductCategoriesList'
import ContentBefore from './components/ContentBefore'

const useStyles = makeStyles((theme) => ({
    media: {
        height: 140,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        zIndex: 2,
    },
    hoverColor: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover
        },
        borderBottom: "1px solid #B5B5B5",

    }
}));
const ProductsCategories = () => {

    const categories = useSelector(state => state.productsCategoriesReducer.categories);
    const loading = useSelector(state => state.productsCategoriesReducer.loading);
    const dispatch = useDispatch();

    const classes = useStyles();

    useEffect(() => {
        dispatch(productsCategoriesActions.getProductCategories());
    }, [])

    return (
        <>
            <ContentBefore title="Categorías" />
            <GeneralContainer>
                <Box mb={2}>
                    {/* <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Icon>add</Icon>}
                >
                  Agregar categoría
                </Button> */}
                </Box>
                <Paper style={{ padding: "16px" }} >
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={4} style={{ backgroundColor: "#fafafa" }}>
                            <>
                                <Box mb={1} >
                                    <Typography variant="h6" color="initial">Seleccione una categoría</Typography>
                                </Box>
                                <ProductCategoriesList categoriesArray={categories} />
                            </>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Route exact path='/admin/products_categories/:categoryId/:parentId'
                                component={ProductCategoriesInner} />
                        </Grid>
                    </Grid>
                </Paper>
                <Fab component={Link} to="/admin/products_categories/0/0" className={classes.fab}
                    color="primary" aria-label="add">
                    <Icon>add</Icon>
                </Fab>
            </GeneralContainer>
        </>
    )
}

export default ProductsCategories
