import React, { useState, useEffect } from 'react';
import { useHistory, Redirect } from 'react-router';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import Typography from '@material-ui/core/Typography';

import 'uikit/dist/css/uikit.min.css';
import UIkit from 'uikit/dist/js/uikit.js';
import MDUIkit from 'md-uikit';

import Logo from "../../assets/img/main_logo.png";
import BGLogin from "../../assets/img/bg_login.jpg";

import { useDispatch, useSelector } from "react-redux";
import userActions from '../../redux/user/user.actions';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `url(${BGLogin})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const LoginAdmin = () => {
    const classes = useStyles();
    const [userCredentials, setUserCredentials] = useState({ email: "", password: "" });

    const userReducer = useSelector(state => state.userReducer);
    const dispatch = useDispatch();
    let history = useHistory();

    const { email, password } = userCredentials;

    const onClickLogin = async event => {

        /* Validaciones */
        let flag = true;
        if (email === "") {
            flag = false;
        }
        if (password === "") {
            flag = false;
        }
        if (flag) {
            dispatch(userActions.login({ email, password }))
        } else {
            UIkit.notification('Please fill blank spaces');
        }

    }

    useEffect(() => {
        MDUIkit.components();
    }, [])
    const handleChange = event => {
        const { value, name } = event.target;
        setUserCredentials({ ...userCredentials, [name]: value })
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            onClickLogin(event)
        }
    }
    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <img src={Logo} alt="logo" width="100px" />
                    <Typography component="h1" variant="h5">Ingresar</Typography>
                    <Box pt={1}>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={handleChange}
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={handleChange}
                            onKeyPress={handleKeyPress}
                        />
                        {/*  <FormControlLabel
                            control={<Checkbox value="remember" color="primary" />}
                            label="Remember me"
                        /> */}
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={onClickLogin}
                            disabled={userReducer.loading}
                        >Ingresar</Button>
                        {/* <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2">
                                    ¿Olvidaste tu contraseña?
            </Link>
                            </Grid>
                            <Grid item>
                                <Link href="#" variant="body2">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid> */}
                    </Box>
                </div>
            </Grid>
        </Grid>
    )
}
export default LoginAdmin;