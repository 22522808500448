import React, { useEffect } from 'react';
import jwt_decode from "jwt-decode";
import { useClearCache } from 'react-clear-cache';

import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { createTheme, responsiveFontSizes, } from '@material-ui/core/styles';
import { ThemeProvider, } from '@material-ui/core';

import { IntlProvider } from 'react-intl';

import NotFound from './views/not-found';
import AdminPanel from './views/portal/AdminPanel';
import LoginAdmin from './views/portal/LoginAdmin';
import Checkout from './views/website/Checkout';

import MainContainer from './views/website/MainContainer';


import { useDispatch, useSelector } from "react-redux";
import userActions from './redux/user/user.actions';

import OrderConfirmation from './views/website/pages/OrderConfirmation';

import 'md-uikit/dist/css/md-uikit.css';
import './App.css';
import './assets/fonts/gotham/stylesheet.css';
import './assets/fonts/javatages/stylesheet.css';
import SnackBar from './views/portal/components/utils/SnackBar';

import Spanish from './lang/es.json';

let theme = createTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: /* "#6200EE" */ "#d58000",
    },
    secondary: {
      // This is green.A700 as hex.
      main: "#3949ab"/*  "#4c563b" */,
    },
  },
  typography: {
    fontFamily: [
      'Gotham Rounded',
      'Arial',
      'serif',
    ].join(','),
  },
});

theme = responsiveFontSizes(theme);



function App() {

  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const dispatch = useDispatch();
  const userReducer = useSelector(state => state.userReducer);

  useEffect(() => {
    console.log("React working...");
    if (userReducer.currentUser || userReducer.currentUserFront) {
      const token = userReducer.currentUser ? userReducer.currentUser.token : userReducer.currentUserFront.token;

      var decodedToken = jwt_decode(token);
      var dateNow = new Date();
      var tokenExp = new Date(decodedToken.exp);
      if (tokenExp.getTime() < Math.round(dateNow.getTime() / 1000)) {
        if (userReducer.currentUserFront) {
          dispatch(userActions.logoutFront());
        } else {
          dispatch(userActions.logout());
        }
      }
    }

    if (!isLatestVersion) {
      console.log("updated to the new version")
      emptyCacheStorage();
    }

  }, [])

  let locale = navigator.language;

  let lang;

  if (localStorage.language) { locale = localStorage.language }

  if (locale === "en") {
    console.log("en selected");
  } else {
    lang = Spanish;
  }


  return (
    <div className="App">
      <IntlProvider locale={locale} messages={lang}>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route path='/checkout/order-confirmation' component={OrderConfirmation} />
              <Route path='/checkout' render={() =>
                userReducer.currentUserFront ? (
                  <Checkout />
                ) : (
                  <Redirect to='/login' />
                )
              } />
              <Route path='/checkout' component={Checkout} />
              <Route path='/admin' render={() =>
                userReducer.currentUser ? (
                  <AdminPanel />
                ) : (
                  <Redirect to='/login_admin' />
                )
              } />
              <Route path='/login_admin' render={() =>
                userReducer.currentUser ? (
                  <Redirect to='/admin/orders' />
                ) : (
                  <LoginAdmin />
                )
              } />
              <Route path='/' component={MainContainer} />
              <Route component={NotFound} />
            </Switch>
          </Router>
          <SnackBar />
        </ThemeProvider>
      </IntlProvider>
    </div>
  );
}

export default App;
