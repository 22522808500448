import React from 'react'

import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import esLocale from "date-fns/locale/es";
import InputAdornment from '@material-ui/core/InputAdornment'
import { Box, Icon, IconButton } from '@material-ui/core';

const DatePicker = ({ onChange = () => null, value, label, size = "medium",
    required = false, helperText = false, error = false, variant = "outlined" }) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
            <KeyboardDatePicker
                fullWidth
                autoOk={true}
                variant="inline"
                format="dd/MM/yyyy"
                size={size}
                label={label}
                value={value}
                onChange={onChange}
                keyboardIcon={<Icon fontSize="small">event</Icon>}
                helperText={<Box style={{ color: "red" }}>{helperText}</Box>}
                InputProps={{ error: error, required: required }}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                    size: 'small'
                }}
                inputVariant={variant}

            />
        </MuiPickersUtilsProvider>
    )
}

export default DatePicker
