import settings from '../../settings.js';

import { setAlert } from '../alert/alert.actions'

export const fetchStart = () => ({
    type: "FETCH_START_USER"
});

export const fetchFailure = errorMessage => ({
    type: "FETCH_FAILURE_USER",
    payload: errorMessage
});

export const fetchSuccess = () => ({
    type: "FETCH_SUCCESS_USER"
});

const login = ({ email, password }) => async dispatch => {
    dispatch(fetchStart());

    const body = {
        "email": email,
        "password": password
    };
    console.log(body);
    console.log(`${settings.connectionString}/api/users/login`);
    try {
        const response = await fetch(`${settings.connectionString}/api/users/login`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        const data = await response.json();
        console.log(data);
        if (data.user.userRol == "admin") {
            console.log("user is admin");
            dispatch({
                type: 'LOGIN_SUCCESS',
                payload: {
                    'userId': data.user.userId,
                    'firstName': data.user.firstName,
                    'lastName': data.user.lastName,
                    'email': data.user.email,
                    'token': data.token,
                    'rol': data.user.userRol
                }
            });
        } else {
            dispatch(fetchSuccess());
            dispatch(setAlert({ msg: "No tiene autorización para ingresar." }));
        }

    } catch (error) {
        console.log(error.message);
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "Usuario y/o contraseña no coinciden." }));
    }
};

const loginUser = ({ email, password }) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());

    const body = {
        "email": email,
        "password": password
    };
    console.log(body);
    try {
        const response = await fetch(`${settings.connectionString}/api/users/login`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        const data = await response.json();
        console.log(data);

        if (data.error) {
            let message = "";
            if (data.message === "login_failed") {
                message = "Invalid username or password"
                if (state.userReducer.language === "es") {
                    message = "Usuario y/o contraseña no coinciden";
                }
            }
            if (data.message === "inactive_account") {
                message = "Inactive account. Check you email to activate it."
                if (state.userReducer.language === "es") {
                    message = "Cuenta inactiva. Revise su correo para activarla.";
                }
            }


            dispatch(setAlert({ msg: message }));
            dispatch(fetchFailure(message));
        } else {
            let message = "Welcome!"

            if (state.userReducer.language === "es") {
                message = "Bienvenido!";
            }
            dispatch(setAlert({ msg: message }));
            dispatch({
                type: 'LOGIN_USER_SUCCESS',
                payload: {
                    'userId': data.user.userId,
                    'firstName': data.user.firstName,
                    'lastName': data.user.lastName,
                    'email': data.user.email,
                    'token': data.token,
                    'rol': data.user.userRol
                }
            });
            return true;
        }

    } catch (error) {
        console.log("catch ");
        dispatch(setAlert({ msg: "Error al iniciar." }));
        dispatch(fetchFailure(error.message));
    }
    return false;
};

const loginWithToken = ({ token, provider, facebookUserID, facebookEmail }) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());

    const body = {
        "token": token,
        "provider": provider,
        "language": state.userReducer.language,
        "facebookUserID": facebookUserID,
        "facebookEmail": facebookEmail
    };

    console.log(body);

    try {
        const url = `${settings.connectionString}/api/users/loginWithToken`;
        console.log(url)
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        const data = await response.json();
        console.log(data);

        let message = "Welcome!"

        if (state.userReducer.language === "es") {
            message = "Bienvenido " + data.user.firstName + "!";
        }
        dispatch(setAlert({ msg: message }));
        dispatch({
            type: 'LOGIN_USER_SUCCESS',
            payload: {
                'userId': data.user.userId,
                'firstName': data.user.firstName,
                'lastName': data.user.lastName,
                'email': data.user.email,
                'token': data.token,
                'rol': data.user.userRol
            }
        });
        return true;

    } catch (error) {
        console.log("catch ");
        console.log(error);
        dispatch(setAlert({ msg: "Error al iniciar." }));
        dispatch(fetchFailure(error.message));
    }
    return false;
};

const logout = () => async dispatch => {
    dispatch({
        type: 'LOGOUT'
    });
};

const logoutFront = () => async (dispatch, getState) => {

    const state = getState();
    let message = "See you later.";

    if (state.userReducer.language === "es") {
        message = "Nos vemos pronto.";
    }
    dispatch(setAlert({ msg: message }));
    dispatch({
        type: 'LOGOUT_FRONT'
    });
};
const getClients = () => async (dispatch, getState) => {

    const state = getState();
    dispatch(fetchStart());

    try {

        const response = await fetch(`${settings.connectionString}/api/users/readClients`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'GET'
        });

        const res = await response.json();
        dispatch({
            type: 'GET_USERS',
            payload: res
        });

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }

}

const getUsersByRol = (userRol) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    try {

        const response = await fetch(`${settings.connectionString}/api/users/usersByRol/${userRol}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'GET'
        });

        const res = await response.json();
        dispatch({
            type: 'GET_USERS',
            payload: res
        });

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const getUser = (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("getUser");
    let token = "";
    if (state.userReducer.currentUser) {
        token = state.userReducer.currentUser.token;
    } else {
        token = state.userReducer.currentUserFront.token;
    }

    try {
        const response = await fetch(`${settings.connectionString}/api/users/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: 'GET'
        });
        const res = await response.json();
        console.log(res);

        res.status = res.status === "1" ? true : false;
        dispatch(fetchSuccess());

        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const addUser = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("addUser");
    try {
        console.log(body);
        const response = await fetch(`${settings.connectionString}/api/users/addUserAdmin`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        console.log(response);
        const res = await response.json();
        if (res.error) {
            dispatch(setAlert({ msg: "Este correo ya existe" }));
            dispatch(fetchFailure("Este correo ya existe"));
        } else {
            dispatch(setAlert({ msg: "Usuario creado" }));
        }

        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo crear el usuario" }));
    }
    return false;
}

const signUp = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("signUp");
    try {
        console.log(body);
        const response = await fetch(`${settings.connectionString}/api/users/signUp`, {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        /* const text = await response.text();
        console.log(text); */
        const res = await response.json();
        console.log(res);
        if (res.error) {
            let message = "This user exist. If you do not remember your password, please select the Forgot password option"

            if (state.userReducer.language === "es") {
                message = "Este usuario ya existe. Si no recuerda su contraseña, haga click en olvidé mi contraseña";
            }
            dispatch(setAlert({ msg: message }));
            dispatch(fetchFailure(message));
        } else {
            dispatch(fetchSuccess());
            /* let message = "Signup complete. You can access now!"

            if (state.userReducer.language === "es") {
                message = "Registro completo. Puedes acceder ahora!";
            }
            dispatch(setAlert({ msg: message })); */
        }

        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo crear el usuario" }));
    }
    return false;
}

const updateUser = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("updateUser");

    let token = "";
    if (state.userReducer.currentUser) {
        token = state.userReducer.currentUser.token;
    } else {
        token = state.userReducer.currentUserFront.token;
    }

    try {
        console.log(body);
        const response = await fetch(`${settings.connectionString}/api/users/update`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: 'PUT',
            body: JSON.stringify(body)
        });
        console.log(response);
        const res = await response.json();
        if (res) {
            dispatch(fetchSuccess());
            dispatch(setAlert({ msg: "Usuario actualizada" }));
        }
        return true;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo actualizar el usuario" }));
    }
    return false;
}
const updateUserAdmin = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("updateUserAdmin");
    try {
        console.log(body);
        const response = await fetch(`${settings.connectionString}/api/users/updateAdmin`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'PUT',
            body: JSON.stringify(body)
        });

        const res = await response.json();
        console.log("updateAdmin");
        console.log(res);
        if (res) {
            dispatch(fetchSuccess());
            dispatch(setAlert({ msg: "Usuario actualizado" }));
        }
        return true;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo actualizar el usuario" }));
    }
    return false;
}

const updatePassword = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("updatePassword");
    console.log(body);

    let token = "";
    if (state.userReducer.currentUser) {
        token = state.userReducer.currentUser.token;
    } else {
        token = state.userReducer.currentUserFront.token;
    }

    try {
        const response = await fetch(`${settings.connectionString}/api/users/updatePassword`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: 'PUT',
            body: JSON.stringify(body)
        });
        if (response.status >= 400 && response.status < 500) {
            throw "Error" + response.status;
        }
        if (response.status >= 500 && response.status < 600) {
        }
        const res = await response.json();
        if (res) {
            dispatch(fetchSuccess());
            dispatch(setAlert({ msg: "Contraseña actualizada" }));
        }
        return true;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo actualizar la contraseña" }));
    }
    return false;
}
const resetPassword = (body, token) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());

    try {
        console.log("resetPassword(body, token)");
        console.log(body);
        console.log(token);

        const response = await fetch(`${settings.connectionString}/api/users/resetPassword`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        if (response.status >= 400 && response.status < 500) {
            throw "Error" + response.status;
        }
        if (response.status >= 500 && response.status < 600) {
        }
        const res = await response.json();
        console.log("resetPassword");
        console.log(res);
        if (res) {
            dispatch(fetchSuccess());
        }
        return true;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo actualizar la contraseña" }));
    }
    return false;
}
const activateAccount = (token) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());

    try {
        console.log("resetPassword(body, token)");
        console.log(token);

        const response = await fetch(`${settings.connectionString}/api/users/activateAccount`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: 'POST'
        });
        if (response.status >= 400 && response.status < 500) {
            throw "Error" + response.status;
        }
        if (response.status >= 500 && response.status < 600) {
            throw "Error" + response.status;
        }
        const res = await response.json();
        console.log("activateAccount");
        console.log(res);
        if (res) {
            dispatch(fetchSuccess());
        }
        return true;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
    return false;
}
const activateAccountAdmin = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());

    try {
        console.log("activateAccountAdmin");
        console.log(body);

        const response = await fetch(`${settings.connectionString}/api/users/activateAccountAdmin`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        if (response.status >= 400 && response.status < 500) {
            throw "Error" + response.status;
        }
        if (response.status >= 500 && response.status < 600) {
            throw "Error" + response.status;
        }
        const res = await response.json();
        console.log("activateAccount");
        console.log(res);
        if (res) {
            dispatch(setAlert({ msg: "Usuario actualizada" }));
            dispatch(fetchSuccess());
        }
        return true;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
    return false;
}

const forgotPassword = (data) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("forgotPassword");

    try {
        const body = {
            ...data
        }
        console.log(body);
        const response = await fetch(`${settings.connectionString}/api/users/sendToken`, {
            headers: {
                'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        if (response.status >= 400 && response.status < 500) {
            throw "Error" + response.status;
        }
        if (response.status >= 500 && response.status < 600) {
        }
        const res = await response.json();
        if (res) {
            dispatch(fetchSuccess());
        }
        return true;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        let message = "We couldn’t find that email.";
        if (state.userReducer.language === "es") {
            message = "No pudimos encontrar este email.";
        }
        dispatch(setAlert({ msg: message }));
    }
    return false;
}
const deleteUser = (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("deleteUser");
    try {
        const response = await fetch(`${settings.connectionString}/api/users/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'DELETE'
        });

        console.log(response);
        const res = await response.json();
        if (res) {
            dispatch(getUsersByRol('admin'));
            dispatch(setAlert({ msg: "Usuario eliminado" }));
        }

        return true;

    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "Error al eliminar" }));
    }
    return false;
}

const setLanguage = (newLang) => (dispatch) => {
    /* console.log("newLang");
    console.log(newLang); */

    dispatch({
        type: 'SET_LANGUAGE',
        payload: newLang
    });
}

const userActions = {
    login,
    loginWithToken,
    logout,
    logoutFront,
    loginUser,
    getUser,
    addUser,
    updateUserAdmin,
    deleteUser,
    getUsersByRol,
    getClients,
    updatePassword,
    updateUser,
    setLanguage,
    forgotPassword,
    resetPassword,
    signUp,
    activateAccount,
    activateAccountAdmin
}
export default userActions;