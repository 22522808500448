import React, { useState } from 'react'
import UIkit from 'uikit';
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';
import voucherActions from "../../../redux/vouchers/vouchers.actions";

import settings from '../../../settings.js';
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { Box } from '@material-ui/core';

import sslImg from '../../../assets/img/SSL-Secure.png';

const CheckoutTotal = ({ discount, setDiscount, total, selectedDeliveryOption, subTotal,
    voucherInfo, setVoucherInfo, voucherValidation, setVoucherValidation, placeOrderButton,
    saveStep3, orderNotes, setOrderNotes, currentStep }) => {

    const userReducer = useSelector(state => state.userReducer);
    const dispatch = useDispatch();
    async function validateCode() {

        const req = {
            "total": total,
            "code": voucherInfo.code
        };
        const res = await dispatch(voucherActions.validateVoucher(req))
        setVoucherValidation(res);
        if (res.valid) {
            if (res.voucherInfo.discountType == "amount") {
                setDiscount(res.voucherInfo.amount)
            }
            if (res.voucherInfo.discountType == "percentage") {
                let newDiscount = (parseFloat(res.voucherInfo.amount) * total) / 100;
                console.log("newDiscount");
                console.log(newDiscount);
                setDiscount(newDiscount)
            }
        }
    }
    const onClickVeriyCode = () => {
        if (voucherInfo.code != "") {
            validateCode();
        } else {
            alert("Please enter a valid code to continue");
        }

    }
    const handleChange = e => {
        const { name, value } = e.target;
        setVoucherInfo({ ...voucherInfo, [name]: value });
        if (value == "") {
            setVoucherValidation({
                valid: null,
                voucherInfo: { voucherId: 0 }
            })
        }
    }

    const resetPromoCode = () => {
        setVoucherValidation({
            valid: null,
            voucherInfo: { voucherId: 0 }
        })
        setDiscount(0);
        setVoucherInfo({ ...voucherInfo, code: "" });
    }

    const renderVoucherMessage = e => {
        /* userReducer.language == "es" ?
            item.instructions : item.instructions_en */
        let message = "";
        let color = "red";
        if (voucherValidation.valid != null && voucherValidation.valid) {
            color = "green";
        }
        switch (voucherValidation.message) {
            case "unlimited_validation_ok":
                if (userReducer.language === "es") {
                    message = "Voucher agregado";
                } else {
                    message = "Voucher added";
                }
                break;
            case "amount_restriction":
                if (userReducer.language === "es") {
                    message = "Monto mínimo inválido";
                } else {
                    message = "Invalid minumum amount";
                }
                break;
            case "once_user_validation_used":
                if (userReducer.language === "es") {
                    message = "Ya usaste este voucher";
                } else {
                    message = "You already used this voucher";
                }
                break;
            case "validation_expired":
                if (userReducer.language === "es") {
                    message = "Este voucher esta vencido";
                } else {
                    message = "This voucher is expired";
                }
                break;
            case "code_not_found":
                if (userReducer.language === "es") {
                    message = "Voucher inválido";
                } else {
                    message = "Invalid voucher";
                }
                break;
            default:

                break;
        }
        return <span className={`md-color-${color}-500`}>
            {message}
        </span>
    }
    return (
        <>
            <div className="uk-card md-bg-white" >
                <div className="uk-card-header border-bottom" style={{ backgroundColor: "#215167" }}>
                    <h3 className="uk-card-title uk-margin-remove-bottom uk-text-left">
                        <div className="uk-clearfix">
                            <div className="uk-float-left">
                                <Typography variant="h5" style={{ color: "white", fontWeight: 700 }}>
                                    <FormattedMessage
                                        id="checkout_total_title"
                                        defaultMessage="Your order"
                                    />
                                </Typography>
                            </div>
                            <div className="uk-float-right">
                                <small className="md-color-white">Total</small>
                                <h4 className='uk-margin-remove md-color-white uk-text-bold'>S/. {total}</h4>
                            </div>
                        </div>
                    </h3>
                </div>
                <div className="uk-card-body">
                    <div className='uk-card md-bg-white'>
                        <div className="uk-card-header border-bottom" onClick={() => setVoucherInfo({ ...voucherInfo, visible: !voucherInfo.visible })}>
                            <div className="uk-flex uk-flex-between pointer uk-flex-middle">
                                <div className="">
                                    <h6 className="uk-margin-remove-bottom uk-text-left">
                                        <FormattedMessage
                                            id="checkout_total_addVoucher"
                                            defaultMessage="Add a voucher"
                                        />
                                    </h6>
                                </div>
                                <div className="">
                                    <i className="md-icon material-icons md-fab-wave">keyboard_arrow_down</i>
                                </div>
                            </div>
                        </div>
                        {voucherInfo.visible &&
                            <div className='uk-card-body '>
                                <div className='uk-width-1-1'>
                                    <div className="uk-flex uk-flex-between">
                                        <div className="">
                                            <div className="md-input-wrapper md-input-no-label md-input-dense">
                                                <input type="text" className="md-input" name="code" placeholder="Ingrese un codigo" onChange={handleChange} value={voucherInfo.code.toUpperCase()}
                                                    {...((voucherValidation.valid != null && voucherValidation.valid) && { readOnly: true })}
                                                />
                                            </div>
                                        </div>
                                        <div className="" style={{ marginLeft: "8px" }}>
                                            {voucherValidation.valid != null ? (
                                                voucherValidation.valid ?
                                                    <button className="md-btn md-color-white md-btn-primary" onClick={resetPromoCode}>
                                                        <FormattedMessage
                                                            id="checkout_total_removeButton"
                                                            defaultMessage="Remove"
                                                        />
                                                    </button>
                                                    :
                                                    <button className="md-btn md-color-white md-btn-primary" onClick={onClickVeriyCode}>
                                                        <FormattedMessage
                                                            id="checkout_total_addButton"
                                                            defaultMessage="Add"
                                                        />
                                                    </button>
                                            )
                                                :
                                                <button className="md-btn md-color-white md-btn-primary" onClick={onClickVeriyCode}>
                                                    <FormattedMessage
                                                        id="checkout_total_addButton"
                                                        defaultMessage="Add"
                                                    />
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        {
                                            renderVoucherMessage()
                                        }
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <table width="100%" className="uk-table ">
                        <tbody>
                            <tr>
                                <td className='uk-text-left'>Subtotal</td>
                                <td className='uk-text-right'><h5 className="md-color-blue-grey-900 uk-margin-remove">S/. {subTotal.toFixed(2)}</h5></td>
                            </tr>
                            <tr>
                                <td className='uk-text-left'>
                                    <FormattedMessage
                                        id="checkout_total_deliveryFee"
                                        defaultMessage="Delivery fee"
                                    />
                                </td>
                                <td className='uk-text-right'>S/. {parseFloat(selectedDeliveryOption.fee).toFixed(2)}</td>
                            </tr>
                            {voucherValidation.valid != null && voucherValidation.valid &&
                                <tr>
                                    <td className='uk-text-left md-color-red-500'>
                                        <FormattedMessage
                                            id="checkout_total_voucher"
                                            defaultMessage="Voucher discount"
                                        />
                                    </td>
                                    <td className='uk-text-right md-color-red-500'>S/. -{parseFloat(discount).toFixed(2)}</td>
                                </tr>
                            }
                            <tr>
                                <td className='uk-text-left' ><h5>TOTAL</h5>  </td>
                                <td className='uk-text-right' ><h5>S/. {total}</h5></td>
                            </tr>
                        </tbody>
                    </table>
                    <Box p={1}>
                        <TextField
                            fullWidth
                            multiline
                            variant="filled"
                            rows={3}
                            label="Notas adicionales"
                            value={orderNotes}
                            placeholder=""
                            onChange={e => setOrderNotes(e.target.value)}

                        />
                    </Box>
                    <button ref={placeOrderButton} onClick={saveStep3} disabled={currentStep !== 3}
                        className="md-btn md-color-white md-btn-primary md-btn-block ripple-surface">
                        <FormattedMessage
                            id="checkout_step3_payment_button"
                            defaultMessage="Place Order"
                        />
                    </button>
                    <Box align="center" pt={2}>
                        <img src={sslImg} style={{ maxWidth: "150px" }} alt="" />
                    </Box>
                </div>
            </div>
        </>
    )
}

export default CheckoutTotal
