import settings from '../../settings.js';

import { setAlert } from '../alert/alert.actions';

export const fetchStart = () => ({
    type: "FETCH_START_VOUCHERS"
});
export const fetchSuccess = () => ({
    type: "FETCH_SUCCESS_VOUCHERS"
});
export const fetchFailure = errorMessage => ({
    type: "FETCH_FAILURE_VOUCHERS",
    payload: errorMessage
});

const getVouchers = () => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    try {
        const response = await fetch(`${settings.connectionString}/api/vouchers/private`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'GET'
        });
        
        const res = await response.json();
        dispatch({
            type: 'GET_VOUCHERS',
            payload: res
        });

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
};

const getVouchersFront = () => async dispatch => {
    dispatch(fetchStart());

    try {
        const response = await fetch(`${settings.connectionString}/api/vouchers/public`, {
            method: 'GET'
        });
        const res = await response.json();
        dispatch({
            type: 'GET_VOUCHERS_FRONT',
            payload: res
        });

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
};
const getVoucher = (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    try {
        const response = await fetch(`${settings.connectionString}/api/vouchers/${id}`, {
            method: 'GET',
        });
        const res = await response.json();
        res.expDate = res.expDate != null ? new Date(res.expDate) : new Date();
        res.active = res.active == 1 ? true : false;
        res.amountRestrictionFlag = res.amountRestrictionFlag == 1 ? true : false;
        dispatch(fetchSuccess());
        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const insertVoucher = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("addVoucher");
    try {
        const response = await fetch(`${settings.connectionString}/api/vouchers`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        console.log(response);
        const res = await response.json();
        dispatch(fetchSuccess());
        if (res) {
            dispatch(setAlert({ msg: "Voucher creado" }));
        }
        return true;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo crear el Voucher" }));
    }
    return false;
}

const updateVoucher = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("updateVoucher");
    try {
        const response = await fetch(`${settings.connectionString}/api/vouchers`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'PUT',
            body: JSON.stringify(body)
        });
        if (response.status >= 400 && response.status < 500) {
            throw "Error" + response.status;
        }
        if (response.status >= 500 && response.status < 600) {
        }
        console.log(response);
        const res = await response.json();
        console.log(res);
        dispatch(fetchSuccess());
        dispatch(setAlert({ msg: "Voucher actualizado" }));
        return true;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo actualizar el Voucher" }));
    }
    return false;
}

const deleteVoucher = (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("deleteVoucher");
    try {
        const response = await fetch(`${settings.connectionString}/api/vouchers/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'DELETE'
        });
        const res = await response.json();
        console.log("deleteVoucher");
        console.log(res);
        if (res) {
            dispatch(getVouchers());
            dispatch(setAlert({ msg: "Voucher eliminado" }));
            return true;
        }
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "Error al eliminar" }));
    }
    return false;
}

const validateVoucher = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("validateVoucher");
    try {
        const response = await fetch(`${settings.connectionString}/api/vouchers/validate`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUserFront.token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        const res = await response.json();
        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
};
export default {
    getVouchers,
    getVouchersFront,
    insertVoucher,
    getVoucher,
    updateVoucher,
    deleteVoucher,
    validateVoucher
}