import { Box, Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import reportsActions from '../../../../redux/reports/reports.actions';

import settings from '../../../../settings.js';

const useStyles = makeStyles({
    table: {
        /* minWidth: 650, */
    },
});

const ProductGeneralReport = ({ dataResult, dateFrom, dateTo }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <>
            <Box pb={1} pt={2} display="flex" justifyContent="flex-end">
                <Button size="small" variant="outlined" color="primary" href={`${settings.connectionString}/reports/productGeneral.php?dateFrom=${dateFrom.toLocaleString('en-US')}&dateTo=${dateTo.toLocaleString('en-US')}`}>
                    Exportar a excel</Button>

            </Box>
            <Paper variant="outlined" >
                <Box overflow="auto">
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombre</TableCell>
                                <TableCell>Costo</TableCell>
                                <TableCell>Precio</TableCell>
                                <TableCell align="right">Precio en Oferta</TableCell>
                                <TableCell align="right">Stock</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataResult.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell >{row.name}</TableCell>
                                    <TableCell >{row.cost}</TableCell>
                                    <TableCell >{row.price}</TableCell>
                                    <TableCell align="right">{row.alt_price_active === "1" ? row.alt_price : "-"}</TableCell>
                                    <TableCell align="right">{row.stock_active === "1" ? row.stock_number : "-"}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </Paper>
        </>
    )
}

export default ProductGeneralReport
