import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Box, CircularProgress, Typography } from '@material-ui/core'

const LoadingPaymentModal = ({ loadingModalInfo, setLoadingModalInfo }) => {

    const onCloseModal = (event, reason) => {

        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setLoadingModalInfo({ ...loadingModalInfo, showModal: false })
        }
    }
    return (
        <Dialog open={loadingModalInfo.showModal}
            onClose={onCloseModal}
            disableEscapeKeyDown={true}>
            <DialogContent>
                {loadingModalInfo.loading ?
                    <Box align="center" p={6}>
                        <CircularProgress />
                    </Box>
                    :
                    <Box align="center" p={2}>
                        <Typography variant="h6" color="initial">
                            {loadingModalInfo.message}
                        </Typography>
                        <Box pt={1}>
                            <Button variant="contained" color="primary"
                                style={{ borderRadius: "25px" }}
                                onClick={() => setLoadingModalInfo({ ...loadingModalInfo, showModal: false })}>
                                OK
                            </Button>
                        </Box>
                    </Box>
                }
            </DialogContent>
        </Dialog>
    )
}

export default LoadingPaymentModal
