import React, { useEffect, useState, useRef } from 'react';
import { useHistory, Link, useLocation } from 'react-router-dom';
import UIkit from 'uikit';
import MDUIkit from 'md-uikit';
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';

// Images
import logo from '../../assets/img/main_logo_yellow.png';

import CartActions from '../../providers/cart/cart.actions'
import TotalLabel from './components/TotalLabel';
import userActions from '../../redux/user/user.actions';
import Button from '@material-ui/core/Button'
import {
  Badge, Box, Hidden, Icon, IconButton, Dialog, DialogTitle,
  Typography, DialogContent, TextField, DialogActions, useTheme, useMediaQuery
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  menuColor: {
    color: "#76596B",
  },
  menuTitle: {
    color: "#76596B",
    transition: "all 0.3s ease-in-out 0s",
    padding: theme.spacing(1),
    borderRadius: "25px",
    fontWeight: "700",
    "&:hover": {
      color: "white",
      backgroundColor: theme.palette.primary.main
    }
  },
  menuTitleBorderPrimary: {
    borderStyle: "solid",
    borderWidth: "2px",
    borderColor: theme.palette.primary.main
  },
  menuTitleActive: {
    color: "white",
    backgroundColor: theme.palette.primary.main
  }
}))

const FrontHeader = ({ modalCart, setOpenDrawer }) => {

  const [searchValue, setSearchValue] = useState("");
  const [showSearchModal, setShowSearchModal] = useState(false)
  const userReducer = useSelector(state => state.userReducer);
  const cart = CartActions();
  const classes = useStyles();
  const history = useHistory();
  let location = useLocation();
  const logoMobileRef = useRef();
  const logoRef = useRef();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  //cargando...
  useEffect(() => {
    MDUIkit.components();
    window.addEventListener('scroll', onScroll);
  }, [])

  const onScroll = () => {
    if (isMobile) {
      if (logoMobileRef.current) {
        if (window.scrollY > 0) {
          logoMobileRef.current.classList.remove('big_logo');
        } else {
          logoMobileRef.current.classList.add("big_logo");
        }
      }
    } else {
      if (logoRef.current) {
        if (window.scrollY > 0) {
          logoRef.current.classList.remove('big_logo');
        } else {
          console.log(window.scrollY);
          logoRef.current.classList.add("big_logo");
        }
      }
    }

  }
  const toggleCart = e => {
    e.preventDefault();
    UIkit.offcanvas(modalCart.current).show();
  }

  const handleChange = event => {
    const { value, name } = event.target;
    setSearchValue(value);
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      setSearchValue("");
      history.push("/search/" + searchValue);
      setShowSearchModal(false)
    }
  }

  const onSearchButton = (event) => {
    setSearchValue("");
    history.push("/search/" + searchValue);
    setShowSearchModal(false)
  }

  return (
    <div>
      <div uk-sticky="animation: uk-animation-slide-top;" >
        <nav className="uk-navbar-container uk-navbar" uk-navbar="mode: click" style={{ height: 75 }}>
          <div className="uk-navbar-left">
            <>
              <Hidden mdUp>
                <Box ml={1}>
                  <IconButton aria-label="" onClick={() => setOpenDrawer(true)}>
                    <Icon className={classes.menuColor}>menu</Icon>
                  </IconButton>
                </Box>
              </Hidden>
              <div className="uk-visible@m">
                <Box pl={1} display="flex">
                  <Box pl={1}>
                    <Typography
                      className={`${location.pathname.startsWith("/shop", 0) && classes.menuTitleActive} ${classes.menuTitle}`}
                      component={Link}
                      variant="body2"
                      to="/shop/1"
                    >
                      <FormattedMessage
                        id="header_shop"
                        defaultMessage="SHOP"
                      />
                    </Typography>
                  </Box>
                  <Box pl={1}>
                    <Typography
                      className={`${location.pathname === "/about" && classes.menuTitleActive} ${classes.menuTitle}`}
                      component={Link}
                      variant="body2"
                      to="/about"
                    >
                      <FormattedMessage
                        id="header_about"
                        defaultMessage="ABOUT US"
                      />
                    </Typography>
                  </Box>

                  <Box pl={1}>
                    <Typography
                      className={`${location.pathname === "/producers" && classes.menuTitleActive} ${classes.menuTitle}`}
                      component={Link}
                      variant="body2"
                      to="/producers"
                    >
                      <FormattedMessage
                        id="header_producers"
                        defaultMessage="PRODUCERS"
                      />
                    </Typography>
                  </Box>

                  <Box pl={1}>
                    <Typography
                      className={`${location.pathname === "/steps" && classes.menuTitleActive} ${classes.menuTitle}`}
                      component={Link}
                      variant="body2"
                      to="/steps"
                    >
                      <FormattedMessage
                        id="header_steps"
                        defaultMessage="HOW IT WORKS"
                      />
                    </Typography>
                  </Box>

                </Box>
              </div>
            </>
          </div>
          <div className="uk-navbar-center">
            <div className="uk-hidden@m ">
              <a className="logo" href="#">
                <img className="main_logo_store_mobile" ref={logoMobileRef} src={logo} />
              </a>
            </div>
            <div className="uk-visible@m ">
              <a className="logo" href="#">
                <img className="main_logo_store" ref={logoRef} src={logo} />
              </a>
            </div>
          </div>
          <div className="uk-navbar-right">

            <Box>
              <IconButton
                onClick={() => setShowSearchModal(true)} >
                <Icon className={classes.menuColor}>search</Icon>
              </IconButton>
            </Box>
            <Hidden smDown>
              <Box pl={1}>
                <Typography
                  className={`${classes.menuTitle}`}
                  component={Link}
                  variant="body2"
                  to="/recipes"
                >
                  <FormattedMessage
                    id="header_recipes"
                    defaultMessage="RECIPES"
                  />
                </Typography>
              </Box>
            </Hidden>

            {userReducer.currentUserFront ?
              <>
                {/* <Hidden mdUp>
                   <Box mr={1}>
                    <IconButton component={Link} to='/account/orders' >
                      <Icon className={classes.menuColor}>person</Icon>
                    </IconButton>
                  </Box> 
                </Hidden> */}
                <Box pl={1}>
                  <Button
                    variant="outlined"
                    style={{ borderRadius: "25px", marginRight: "8px" }}
                    component={Link}
                    to='/account/orders'
                    startIcon={<Icon>person</Icon>}
                    className="uk-visible@m"
                  >
                    <FormattedMessage
                      id="header_account"
                      defaultMessage="My account"
                    />
                  </Button>
                </Box>
              </>
              :
              <>
                {/*  <Hidden mdUp>
                  <Box mr={1}>
                    <IconButton component={Link} to='/login' >
                      <Icon className={classes.menuColor}>login</Icon>
                    </IconButton>
                  </Box>
                </Hidden> */}
                <Hidden smDown>

                  <Box px={1}>
                    <Typography
                      className={`${classes.menuTitle} ${classes.menuTitleBorderPrimary}`}
                      component={Link}
                      variant="body2"
                      to="/login"
                    >
                      <FormattedMessage
                        id="header_login"
                        defaultMessage="LOGIN / REGISTER"
                      />
                    </Typography>
                  </Box>
                  {/* <Button
                    variant="outlined"
                    className={classes.menuColor}
                    style={{ borderRadius: "25px", marginRight: "8px" }}
                    component={Link}
                    to='/login'
                    size="small"
                  >
                    <FormattedMessage
                      id="header_login"
                      defaultMessage="Login / Register"
                    />
                  </Button> */}
                </Hidden>
              </>
            }
            <Box mr={2}>
              {cart.cartItems.length > 0 ?
                <IconButton onClick={toggleCart} >
                  <Badge badgeContent={cart.cartItems.length} color="primary">
                    <Icon className={classes.menuColor}>shopping_cart</Icon>
                  </Badge>
                </IconButton>
                :
                <IconButton onClick={toggleCart} >
                  <Icon className={classes.menuColor}>shopping_cart</Icon>
                </IconButton>
              }
            </Box>
            {/* <div className='cart-container uk-margin-right uk-visible@m'>
              <div className="cart-icon">

                <i onClick={toggleCart} className='material-icons md-icon'>shopping_cart</i>
              </div>
              <Box mr={1} className='cart-header'>
                <label ><FormattedMessage
                  id="header_your_order"
                  defaultMessage="Your order:"
                /></label>
                <TotalLabel />
              </Box>
              <div className="cart-button">
                {cart.cartItems.length ?
                  <button onClick={toggleCart} className="md-btn md-btn-primary ripple-surface">
                    <FormattedMessage
                      id="header_view_order"
                      defaultMessage="View order"
                    />
                  </button>
                  :
                  <a className="md-btn " disabled>
                    <FormattedMessage
                      id="header_view_order"
                      defaultMessage="View order"
                    />
                  </a>
                }
              </div>
            </div> */}
          </div>
        </nav>
      </div>
      <Dialog open={showSearchModal} onClose={() => setShowSearchModal(false)}
        fullWidth maxWidth="sm">
        <DialogTitle id="">
          <Box display="flex" alignItems="center">
            <Box flexGrow={1}>
              <Typography variant="h6" color="initial">
                <FormattedMessage
                  id="header_search"
                  defaultMessage="Search"
                />
              </Typography>
            </Box>
            <Box>
              <IconButton size="small" onClick={() => setShowSearchModal(false)}>
                <Icon>close</Icon>
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            value={searchValue}
            onChange={handleChange}
            onKeyPress={handleKeyPress}
            autoFocus
            label={
              <FormattedMessage
                id="header_product"
                defaultMessage="Product name"
              />
            }
          />
        </DialogContent>
        <DialogActions>
          <Button variant="text" color="default" onClick={() => setShowSearchModal(false)}>
            <FormattedMessage
              id="header_cancel"
              defaultMessage="Cancel"
            />
          </Button>
          <Button variant="text" color="default" onClick={onSearchButton}>
            <FormattedMessage
              id="header_search"
              defaultMessage="Search"
            />
          </Button>
        </DialogActions>
      </Dialog>
    </div >
  )
}
export default FrontHeader;