import { Avatar, Box, Divider, Icon, Typography } from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'

import settings from '../../../../../settings.js';


const useStyles = makeStyles(theme => ({
    overFlowProd: {
        [theme.breakpoints.down('lg')]: {
            maxWidth: 850,
        },
        [theme.breakpoints.down('md')]: {
            maxWidth: 850,
        },
        [theme.breakpoints.down('sm')]: {
            maxWidth: 490,
        },
        [theme.breakpoints.down('xs')]: {
            maxWidth: 290,
        },
    },

}));

const OrderItems = ({ items }) => {
    const [open, setOpen] = useState(false)
    const classes = useStyles();
    return (
        <Box mt={2}>
            {items.length > 1 ?
                <>
                    <Box ml={2} className="pointer" >
                        <Box display="flex" alignItems="center" onClick={() => setOpen(!open)} >
                            <Typography variant="body1" color="primary" >
                                {items.length} productos
                            </Typography>
                            <Box>
                                {open ?
                                    <Icon fontSize="small" color="primary">expand_less</Icon>
                                    :
                                    <Icon fontSize="small" color="primary">expand_more</Icon>
                                }
                            </Box>
                        </Box>
                        <Box className={classes.overFlowProd}>
                            {!open &&
                                <Box overflow="auto" mt={1} display="flex" alignItems="center" className="pointer">
                                    {items.map((item, index) => (
                                        <Box key={index} mr={1}>
                                            <Avatar variant="rounded">
                                                <img src={`${settings.connectionString}/files/products/${item.image}`} alt="" />
                                            </Avatar>
                                        </Box>
                                    ))}
                                </Box>
                            }
                        </Box>
                    </Box>
                    {open && items.map((item, index) => (
                        <>
                            <Box key={index} ml={2} mt={1} alignItems="center">
                                <Box display="flex" alignItems="center">
                                    <Avatar variant="rounded">
                                        <img src={`${settings.connectionString}/files/products/${item.image}`} alt="" />
                                    </Avatar>
                                    <Box ml={1}>
                                        <Typography variant="body2" color="initial">{item.name} </Typography>
                                        <Box>
                                            <Typography variant="caption" color="initial">
                                                {item.quantity + " x "} {"S/." + item.orderPrice}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </>
                    ))
                    }
                </>
                :
                items[0] &&
                <Box ml={2} mt={1} alignItems="center">
                    <Box display="flex" alignItems="center">

                        <Avatar variant="rounded">
                            <img src={`${settings.connectionString}/files/products/${items[0].image}`} alt="" />
                        </Avatar>
                        <Box ml={1}>
                            <Typography variant="body2" color="initial">{items[0].name} </Typography>
                            <Box>
                                <Typography variant="caption" color="initial">
                                    {items[0].quantity + " x "} {"S/." + items[0].orderPrice}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>

            }
        </Box>
    )
}

export default OrderItems
