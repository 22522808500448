import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react'

import { Link, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
    contrastColor: {
        color: theme.palette.secondary.contrastText
    },
    contentBefore: {
        backgroundColor: theme.palette.secondary.main,
        position: "absolute",
        /* zIndex: "-1", */
        top: "27px",
        left: "0",
        width: "100%",
        height: "132px",
        transition: "all 0.3s ease",
    },
    title: {
        zIndex: 2,
        position: "relative",
    },
}))
const ContentBefore = ({ title, icon = false }) => {
    const classes = useStyles();
    return (
        <>
            <Box className={classes.contentBefore}></Box>
            <Box className={classes.title} display="flex" alignItems="center" mb={3}>
                {icon &&
                    <Box mr={1} className={classes.contrastColor + " pointer"}>
                        {icon}
                    </Box>
                }
                <Box>
                    <Typography variant="h4" className={classes.contrastColor}>
                        {title}
                    </Typography>
                </Box>
            </Box>

            {/* <i onClick={() => history.goBack()} className="uk-margin-top material-icons md-color-white md-icon md-36">navigate_before</i> */}

        </>
    )
}

export default ContentBefore
