import { Box, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({

    hoverColor: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover
        },
        borderBottom: "1px solid #B5B5B5",
        borderBottomColor: theme.palette.divider
    }
}));

const GeneralRow = ({ children }) => {
    const classes = useStyles();
    return (
        <Box p={2} className={classes.hoverColor}>
            {children}
        </Box>
    )
}

export default GeneralRow
