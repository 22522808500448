import {
    Fab, Icon, Paper, Grid, Box, Divider,
    Typography, CircularProgress, Avatar
} from '@material-ui/core';

import { Link, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react'
import ContentBefore from './components/ContentBefore';

import { useDispatch, useSelector } from "react-redux";
import vouchersActions from '../../redux/vouchers/vouchers.actions';

import settings from '../../settings.js';
import GeneralContainer from './components/GeneralContainer';
import FAB from './components/utils/FAB';
import GeneralRow from './components/utils/GeneralRow';
import ResponsiveMenuButton from './components/utils/ResponsiveMenuButton';
import DeleteModal from './components/modals/DeleteModal';

const Vouchers = () => {

    const vouchersReducer = useSelector(state => state.vouchersReducer);
    const dispatch = useDispatch();

    const history = useHistory();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({
        voucherId: 0
    });

    /* const classes = useStyles(); */

    useEffect(() => {
        dispatch(vouchersActions.getVouchers());
    }, []);

    const menu = [
        {
            title: "Ver/Editar",
            icon: "visibility",
            action: e => onClickView(e)
        },
        {
            title: "Eliminar",
            icon: "delete",
            action: e => onClickDelete(e)
        },
    ]

    const onClickView = item => {
        console.log("onClickView");
        history.push(`/admin/voucher_detail/${item.voucherId}`)
    }

    const onClickDelete = item => {
        setSelectedItem(item);
        setShowDeleteModal(true);
    }
    const deleteProgram = item => {
        const result = dispatch(vouchersActions.deleteVoucher(selectedItem.voucherId));
        if (result) {
            setShowDeleteModal(false);
        }
    }

    return (
        <>
            <ContentBefore title="Cupones de descuento" />
            <GeneralContainer>
                <Paper >
                    {vouchersReducer.loading ?
                        <CircularProgress />
                        :
                        <>
                            {vouchersReducer.vouchers.length > 0 && vouchersReducer.vouchers.map((item, index) => (
                                <GeneralRow key={index}>
                                    <Box display="flex" alignItems="center">
                                        <Box mr={1}>
                                            <Avatar style={{ width: "80px", height: "80px" }} variant="rounded"
                                            />
                                        </Box>
                                        <Box flexGrow={1}>
                                            <Typography component={Link} to={`/admin/voucher_detail/${item.voucherId}`} variant="h6" color="initial">
                                                {item.code}
                                            </Typography>
                                            <Typography component="span" variant="body1" color="initial">
                                                {item.active == "1" ?
                                                    <Box fontWeight="fontWeightBold" color="success.main">Activo</Box>
                                                    :
                                                    <Box fontWeight="fontWeightBold" color="text.secondary">Inactivo</Box>
                                                }
                                            </Typography>

                                        </Box>
                                        <Box >
                                            <ResponsiveMenuButton menu={menu} item={item} title={item.code} />
                                        </Box>
                                    </Box>
                                </GeneralRow>
                            ))}
                        </>
                    }
                </Paper>
            </GeneralContainer>
            <DeleteModal showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                title={selectedItem.code}
                action={deleteProgram}
            />
            <FAB component={Link} to="/admin/voucher_detail/0">
                <Icon>add</Icon>
            </FAB>
        </>
    )
}

export default Vouchers
