import React, { useState } from 'react'
import Grid from '@material-ui/core/Grid'

import { useDispatch, useSelector } from "react-redux";
import catActions from '../../../../redux/product_categories/product_categories.actions';
import {
    Button, FormControlLabel, Icon, IconButton, Paper, TextField,
    Typography, Switch, Box
} from '@material-ui/core'
import { setAlert } from '../../../../redux/alert/alert.actions';


const SubCategoryForm = ({ setAddSubcategory, parentId ,getCategoryInfo}) => {

    const dispatch = useDispatch();
    const [categoryInfo, setCategoryInfo] = useState({
        name: "",
        name_en: "",
        status: true,
    })

    async function insertCategory() {
        const req = {
            "name": categoryInfo.name,
            "name_en": categoryInfo.name_en,
            "status": categoryInfo.status ? 1 : 0,
            "parentId": parentId
        };
        await dispatch(catActions.addCategory(req));
        getCategoryInfo();
    }

    const handleChange = event => {
        /*  event.preventDefault(); */
        const { value, name } = event.target;
        console.log(name);
        console.log(name + " " + value);
        setCategoryInfo({ ...categoryInfo, [name]: value });
    }
    const handleSwitch = event => {
        event.preventDefault();
        const { checked, name } = event.target;
        setCategoryInfo({ ...categoryInfo, [name]: checked });
    }

    const onClickSave = async event => {
        event.preventDefault();
        let flagWhites = true;
        let flagGeneral = true;
        if (categoryInfo.name === "" || categoryInfo.name_en === "") {
            flagWhites = false;
        }
        if (!flagWhites) {
            flagGeneral = false;
            dispatch(setAlert({ msg: 'Complete la información para continuar' }));
        }
        console.log(flagGeneral);
        if (flagGeneral) {
            await insertCategory();
            setAddSubcategory(false);
        }
    }
    return (
        <Paper variant="outlined" style={{ padding: "8px" }} >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Box display="flex">
                        <Box flexGrow={1}>
                            <Typography variant="body1" color="initial">
                                <Box fontWeight="fontWeightBold">
                                    Agregar Subcategoria
                                </Box>
                            </Typography>
                        </Box>
                        <Box><IconButton size="small" onClick={() => setAddSubcategory(false)}>
                            <Icon fontSize="small"> close</Icon></IconButton>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        name="name"
                        size="small"
                        value={categoryInfo.name}
                        onChange={handleChange}
                        variant="outlined"
                        label="Nombre"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        name="name_en"
                        size="small"
                        value={categoryInfo.name_en}
                        onChange={handleChange}
                        variant="outlined"
                        label="Nombre Ingles"
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={categoryInfo.status}
                                onChange={handleSwitch}
                                name="status"
                                color="primary"
                            />
                        }
                        label={categoryInfo.status ? "subcategoría activa" : "subcategoría inactiva"}
                    />
                </Grid>
                <Grid item xs={12} align="right">
                    <Button onClick={onClickSave} variant="text" startIcon={<Icon>add</Icon>} color="primary">
                        agregar subcategoría
                        </Button>
                </Grid>
            </Grid>
        </Paper>
    )
}

export default SubCategoryForm
