const INITIAL_STATE = {
  orders: [],
  order: null,
  loading: false,
  errorMessage: undefined,
  hasMore: false
};

const ordersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_START_ORDER":
      return {
        ...state,
        loading: true
      };
    case "FETCH_SUCCESS_ORDER":
      return {
        ...state,
        loading: false
      };
    case "GET_ORDER":
      return {
        ...state,
        order: action.payload,
        loading: false
      };
    case "GET_ORDERS":
      return {
        ...state,
        hasMore: action.payload.length > 0 ? true : false,
        orders: action.payload,
        order: null,
        loading: false
      };
    case "GET_ORDERS_PREVIOUS":
      return {
        ...state,
        hasMore: action.payload.length > 0 ? true : false,
        orders: [...state.orders, ...action.payload],
        order: null,
        loading: false
      };
    case "FETCH_FAILURE_ORDER":
      return {
        ...state,
        errorMessage: action.payload,
        loading: false
      };
    case "RESET_ORDERS":
      return {
        ...state,
        orders: []
      };
    default:
      return state;
  }
};

export default ordersReducer;