import { Box, Fab, Icon, makeStyles } from '@material-ui/core'
import React from 'react'

const useStyles = makeStyles((theme) => ({
    fab: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        zIndex: 2,
    },
}));

const FAB = ({ children, ...props }) => {
    const classes = useStyles();
    return (
        <Fab {...props}
            className={classes.fab} color="primary" aria-label="add">
            { children}
        </Fab>
    )
}

export default FAB
