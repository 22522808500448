import { Box, TextField, Typography } from '@material-ui/core'
import React from 'react'

import CartActions from '../../../providers/cart/cart.actions';


const ProductComment = ({ item, placeholder }) => {
    const cart = CartActions();

    const onChangeComment = (item) => e => {
        const { value, name } = e.target;
        console.log("onChangeComment");
        const newItem = { ...item, comments: value };
        console.log(newItem);
        cart.updateItemComment(newItem);
    }

    return (
        <>
            <Box mb={1}>
                <Typography variant="body2" color="initial">{placeholder}</Typography>
            </Box>
            <TextField
                fullWidth
                value={item.comments}
                onChange={onChangeComment(item)}
                rows={3}
                variant="outlined"
                multiline
            />
        </>
    )
}

export default ProductComment
