const INITIAL_STATE = {
  loading: false,
  errorMessage: undefined
};

const webSettingsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_START_REPORTS":
      return {
        ...state,
        loading: true
      };
    case "FETCH_SUCCESS_REPORTS":
      return {
        ...state,
        loading: false
      };
    case "FETCH_FAILURE_REPORTS":
      return {
        ...state,
        errorMessage: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default webSettingsReducer;