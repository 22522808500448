import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from "react-router-dom";
import Grid from '@material-ui/core/Grid'
import { Box, Container, Divider, Link, Typography, Button, CircularProgress, Avatar, Icon } from '@material-ui/core'

import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';

import FrontFooter from '../Footer';

import reportsActions from '../../../redux/reports/reports.actions';
import ordersActions from '../../../redux/orders/orders.actions';
import CartActions from '../../../providers/cart/cart.actions';

import settings from '../../../settings.js';

import Logo from "../../../assets/img/main_logo.png";

const OrderConfirmation = () => {

    const [orderInfo, setOrderInfo] = useState({
        created_date: new Date(),
        total_price: "0",
        stateId: 1,
        refNumber: "",
        firstName: "",
        lastName: "",
        paymentName: "",
        items: []
    })
    const loading = useSelector(state => state.ordersReducer.loading);
    const userReducer = useSelector(state => state.userReducer);
    const dispatch = useDispatch();

    const cart = CartActions();
    const history = useHistory();
    useEffect(() => {
        if (cart.createdOrder !== 0) {

            const fetchData = async () => {
                console.log("loading data...");
                const data = await dispatch(ordersActions.getOrder(cart.createdOrder));
                console.log(data);
                setOrderInfo(data);
            };

            fetchData();
        } else {
            history.push("/shop/1");
        }
    }, [cart.createdOrder])

    const states = [
        { id: 1, name: "Recibido", name_en: "Received", icon: "" },
        { id: 2, name: "Confirmado", name_en: "Confirmed", icon: "" },
        { id: 3, name: "Enviado", name_en: "Sent", icon: "" },
        { id: 4, name: "Entregado", name_en: "Delivered", icon: "" },
        { id: 5, name: "Cancelado", name_en: "Canceled", icon: "" },
    ]

    const backToStore = e => {
        e.preventDefault();
        history.push("./shop/1")
    }

    const savePDF = async () => {
        const data = {
          data: orderInfo,
          type: "single_order_report"
        }
        await dispatch(reportsActions.savePdf(data));
      }

    return (
        <>
            {loading ?
                <Box display="flex" justifyContent="center" p={5}>
                    <CircularProgress />
                </Box>
                :
                <Box my={3}>
                    <Container maxWidth="sm" >
                        <Grid container spacing={2} >
                            <Grid item xs={12} align="center">
                                <img src={Logo} width="120px" alt="" />
                            </Grid>
                            <Grid item xs={12} align="center">
                                <Typography variant="h5" color="initial">
                                    <Box fontWeight="fontWeightBold">
                                        <FormattedMessage
                                            id="confirmation_title"
                                            defaultMessage="Thanks for your order!"
                                        />🎉
                                </Box>
                                </Typography>
                            </Grid>
                            <Grid item xs={12} align="center">
                                <Typography variant="body1" color="initial">
                                    <FormattedMessage
                                        id="confirmation_message"
                                        defaultMessage="The confirmation of your order and the updates will be sent to {email}"
                                        values={
                                            {
                                                email: orderInfo.userEmail,
                                            }
                                        }
                                    />
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex">
                                    <Box flexGrow={1}>
                                        <Box>
                                            <Typography variant="h6" color="initial">
                                                <FormattedMessage
                                                    id="confirmation_refNumber"
                                                    defaultMessage="Order {refNumber}"
                                                    values={
                                                        {
                                                            refNumber: orderInfo.refNumber,
                                                        }
                                                    }
                                                />
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body1" color="initial">
                                                {orderInfo.created_date.toLocaleString('es-US', { hour12: true })}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box pt={2} display="flex" justifyContent="center">
                                        <Button
                                            variant="text"
                                            color="primary"
                                            startIcon={<Icon>save</Icon>}
                                            onClick={savePDF}
                                        >
                                            Descargar PDF
                                                </Button>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex">
                                    <Box flexGrow={1}>
                                        <Box>
                                            <Typography variant="h6" color="initial">
                                                <FormattedMessage
                                                    id="confirmation_status"
                                                    defaultMessage="Order status: {status}"
                                                    values={
                                                        {
                                                            status: userReducer.language == "es" ? states[orderInfo.stateId - 1].name : states[orderInfo.stateId - 1].name_en,
                                                        }
                                                    }
                                                />
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body1" color="initial">
                                                <FormattedMessage
                                                    id="confirmation_payment"
                                                    defaultMessage="Payment: {paymentName}"
                                                    values={
                                                        {
                                                            paymentName: userReducer.language === "es" ? orderInfo.paymentName : orderInfo.paymentName_en,
                                                        }
                                                    }
                                                />
                                            </Typography>
                                            <Typography variant="body1" color="initial">
                                                Total: {"S/." + orderInfo.totalPrice}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Box mb={1}>
                                    <Typography variant="h6" color="initial">
                                        <FormattedMessage
                                            id="confirmation_orderName"
                                            defaultMessage="Your order"
                                        />
                                    </Typography>
                                </Box>
                                {orderInfo.items.map((item, index) => (
                                    <Box display="flex" alignItems="center">
                                        <Box mr={1} >
                                            <Avatar variant="rounded">
                                                <img src={`${settings.connectionString}/files/products/${item.image}`} alt="" />
                                            </Avatar>
                                        </Box>
                                        <Box flexGrow={1}>
                                            <Box>
                                                <Typography variant="body1" color="initial">
                                                    <Box fontWeight="fontWeightBold">
                                                        {item.name}
                                                    </Box>
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="body1" color="initial">
                                                    × {item.quantity}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                ))}
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Box display="flex">
                                    <Box flexGrow={1}>
                                        <Box>
                                            <Typography variant="h6" color="initial">
                                                <FormattedMessage
                                                    id="confirmation_questions"
                                                    defaultMessage="Questions about your order?"
                                                />
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Typography variant="body1" color="initial">
                                                <FormattedMessage
                                                    id="confirmation_footer_message"
                                                    defaultMessage="We are here for you. Let us know how we can help."
                                                />
                                                {/* Email: "email" */}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} align="center">
                                <Button variant="outlined" color="default" onClick={backToStore}>
                                    <FormattedMessage
                                        id="confirmation_button"
                                        defaultMessage="Continue shopping"
                                    />
                            </Button>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            }
            <FrontFooter />
        </>
    )
}

export default OrderConfirmation
