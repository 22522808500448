import { useContext } from "react";
import { CartContext } from './cart.provider';
const CartActions = () => {
    const { state, dispatch } = useContext(CartContext);
    return {
        cartItems: state.cartItems,
        createdOrder: state.createdOrder,
        subTotal: state.subTotal,
        addItem: function (item) {
            dispatch({
                type: "ADD_ITEM",
                payload: item
            });
            dispatch({
                type: "GET_TOTAL_PRICE"
            });
        },
        updateItemComment: function (item) {
            dispatch({
                type: "UPDATE_ITEM_COMMENT",
                payload: item
            });
        },
        setCreatedOrder: function (item) {
            dispatch({
                type: "SET_CREATED_ORDER",
                payload: item
            })
        },
        resetCreatedOrder: function (item) {
            dispatch({
                type: "RESET_CREATED_ORDER",
                payload: item
            })
        },
        removeItem: function (item) {
            dispatch({
                type: "REMOVE_ITEM",
                payload: item
            })
            dispatch({
                type: "GET_TOTAL_PRICE"
            });
        },
        setList: function (item) {
            dispatch({
                type: "SET_LIST",
                payload: item
            })
            dispatch({
                type: "GET_TOTAL_PRICE"
            });
        },
        resetList: function (item) {
            dispatch({
                type: "RESET_LIST",
                payload: item
            })
        },
        getCartSubTotal: function () {
            dispatch({
                type: "GET_TOTAL_PRICE"
            })
        }
    }
}
export default CartActions;

