import React from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Box, Grid, Typography } from '@material-ui/core'

const DeleteModal = ({ showModal, setShowModal, action, title, description = "" }) => {
    return (
        <div>
            <Dialog open={showModal} onClose={() => setShowModal(false)} maxWidth="xs" fullWidth >
                <DialogTitle id=""><Box fontWeight="fontWeightBold">¿Deseas eliminar?</Box></DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        {description != "" &&
                            <Grid item xs={12}>
                                <Box>{description}</Box>
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <Typography variant="body1" color="inherit">
                                <Box fontWeight="fontWeightBold" textAlign="center" >{title}</Box>
                            </Typography>
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowModal(false)} color="initial">
                        Cancelar
                </Button>
                    <Button color="primary" onClick={action} >
                        Eliminar
                </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default DeleteModal
