import React, { useState, useEffect } from 'react'
import {
    Checkbox,
    ListItemSecondaryAction, ListItemText, ListItemIcon, ListItem, List, CircularProgress
} from '@material-ui/core'

import ProductCategoriesListItem from './ProductCategoriesListItem';

const ProductCategoriesList = ({ isLoading, categoriesArray }) => {

    return (
        <>
            {isLoading ?
                <CircularProgress color="secondary" />
                :
                <List dense>
                    {categoriesArray.length > 0 && categoriesArray.map((item, index) => (
                        <ProductCategoriesListItem key={index} item={item}
                            />
                    ))}
                </List>
            }
        </>
    )
}

export default ProductCategoriesList
