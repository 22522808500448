const INITIAL_STATE = {
  producers: [],
  loading: false,
  errorMessage: undefined
};

const producersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_START":
      return {
        ...state,
        loading: true
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        loading: false
      };
    case "GET_PRODUCERS":
      return {
        ...state,
        producers: action.payload,
        loading: false
      };
    case "GET_PRODUCERS_FRONT":
      return {
        ...state,
        producers: action.payload,
        loading: false
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        errorMessage: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default producersReducer;