import React, { useState, useEffect } from 'react';

import { Link, useHistory } from "react-router-dom";
import ContentBefore from './components/ContentBefore';
import GeneralContainer from './components/GeneralContainer';

import { Box, CircularProgress, Paper, Grid, InputBase, InputAdornment, Icon, Typography, Avatar, Button } from '@material-ui/core';
import GeneralRow from './components/utils/GeneralRow';
import ResponsiveMenuButton from './components/utils/ResponsiveMenuButton';

import { useDispatch, useSelector } from "react-redux";
import webSettingsActions from '../../redux/websettings/websettings.actions';
import culqi_icon from '../../assets/img/items/brandCulqi-white.svg';


const ApplicantHistoryPage = () => {

    const webSettingsReducer = useSelector(state => state.webSettingsReducer);
    const dispatch = useDispatch();

    const history = useHistory();

    useEffect(() => {
        dispatch(webSettingsActions.getWebSettings());
    }, []);

    return (
        <>
            <ContentBefore title="Configuración General" />
            <GeneralContainer>
                {webSettingsReducer.loading ?
                    <CircularProgress />
                    :
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper>
                                {webSettingsReducer.settings.length > 0 &&
                                    webSettingsReducer.settings.map((item, index) => (
                                        <GeneralRow key={index}>
                                            <Box display="flex" alignItems="center">
                                                <Box flexGrow={1}>
                                                    <Box display="flex" alignItems="center">
                                                        <Box >
                                                            <Typography component={Link} to={`/admin/websettings_detail/${item.id}`} variant="h6">
                                                                <Box color="text.primary">{item.title}</Box>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box >
                                                    <Button variant="outlined" color="primary" onClick={() => history.push(`/admin/websettings_detail/${item.id}`)}>
                                                        Editar
                                               </Button>
                                                </Box>
                                            </Box>
                                        </GeneralRow>
                                    ))
                                }
                            </Paper>
                        </Grid>
                    </Grid>
                }
            </GeneralContainer>
        </>
    );
}
export default ApplicantHistoryPage;