import { Box, Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import reportsActions from '../../../../redux/reports/reports.actions';

import settings from '../../../../settings.js';

const useStyles = makeStyles({
    table: {
        /* minWidth: 650, */
    },
});

const OrdenesGeneralReport = ({ dataResult, dateFrom, dateTo }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <>
            <Box pb={1} pt={2} display="flex" justifyContent="flex-end">
                <Button size="small" variant="outlined" color="primary" href={`${settings.connectionString}/reports/orderGeneral.php?dateFrom=${dateFrom.toLocaleString('en-US')}&dateTo=${dateTo.toLocaleString('en-US')}`}>
                    Exportar a excel</Button>

            </Box>
            <Paper variant="outlined" >
                <Box overflow="auto">
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Fecha</TableCell>
                                <TableCell>Zona</TableCell>
                                <TableCell>#Pedido</TableCell>
                                <TableCell>Cliente</TableCell>
                                <TableCell>Estado</TableCell>
                                <TableCell>Tipo de pago</TableCell>
                                <TableCell align="right" >Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataResult.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell >{row.created_date}</TableCell>
                                    <TableCell >{row.Zone}</TableCell>
                                    <TableCell >{row.refNumber}</TableCell>
                                    <TableCell >{row.firstName+" "+row.lastName}</TableCell>
                                    <TableCell >{row.stateId}</TableCell>
                                    <TableCell >{row.paymentName}</TableCell>
                                    <TableCell align="right">{row.totalPrice}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </Paper>
        </>
    )
}

export default OrdenesGeneralReport
