import React, { useEffect, useRef, useState } from 'react'

import UIkit from 'uikit';
import axios from 'axios';

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import DatePicker from '../utils/DatePicker'
import FormControl from '@material-ui/core/FormControl'
import FormHelperText from '@material-ui/core/FormHelperText'
import { Radio, FormControlLabel, RadioGroup } from '@material-ui/core/'

import { useDispatch, useSelector } from "react-redux";
import settings from '../../../../settings.js';
import FormLabel from '@material-ui/core/FormLabel'
import bannersActions from '../../../../redux/banners/banners.actions';
import Slim from '../../../../components/slim/slim.react';
import { setAlert } from '../../../../redux/alert/alert.actions';


const AddBannerModal = ({ showModal, setShowModal, fetchBanners }) => {

    const loading = useSelector(state => state.bannersReducer.loading);

    const dispatch = useDispatch();

    const [newBannerInfo, setNewBannerInfo] = useState({
        title: "",
        dateFrom: new Date(),
        dateTo: new Date(),
        visible: "1",
        img: ""
    });
    const [sizeExceeded, setSizeExceeded] = useState(false);
    const [selectedFile, setSelectedFile] = useState({});


    const handleChange = event => {
        event.preventDefault();
        const { value, name } = event.target;
        console.log(name);
        console.log(name + " " + value);
        setNewBannerInfo({ ...newBannerInfo, [name]: value });
    }

    async function sendInfo(newObj) {
        /* setIsLoading(true);
        
        console.log("sendInfo");
        console.log(JSON.stringify(req));
        const response = await fetch(`${settings.connectionString}/api/banner_insert.php`, {
            method: 'POST',
            body: JSON.stringify(req)
        });
        const res = await response.json();
        setIsLoading(false);
        console.log(res); */

        const req = {
            "title": newObj.title,
            "img": newObj.img,
            "visible": newObj.visible,
            "dateFrom": newObj.dateFrom.toLocaleString('en-US'),
            "dateTo": newObj.dateTo.toLocaleString('en-US')
        };
        const response = await dispatch(bannersActions.addBanner(req));
        if (response) {
            setShowModal(false);
            setSelectedFile({});
        }

    }
    const UploadDocument = event => {
        event.preventDefault();
        let flagWhites = true;
        let flagGeneral = true;
        if (newBannerInfo.title === "") {
            flagWhites = false;
        }
        if (!flagWhites) {
            flagGeneral = false;
            dispatch(setAlert({ msg: 'Complete toda la información requerida.' }));
        }
        if (selectedFile.file == undefined) {
            flagGeneral = false;
            dispatch(setAlert({ msg: 'Seleccione una imagen' }));
        }
        console.log(flagGeneral);
        if (flagGeneral) {
            uploadHandler();
        }
    }
    const uploadHandler = () => {
        const formData = new FormData();

        console.log("Uploading file...");
        let fileName = selectedFile.name;
        let fileContent = selectedFile.file;
        formData.append('code', "123");
        formData.append('folder', "banners");
        formData.append(
            'myFile_0',
            fileContent,
            fileName
        );
        axios.post(`${settings.connectionString}/api/utils/upload`, formData, {
            onUploadProgress: progressEvent => {
                console.log(progressEvent.loaded / progressEvent.total)
            }
        })
            .then(response => {
                console.log(response);
                const newObj = { ...newBannerInfo };
                newObj.img = response.data.fileName;
                //setNewBannerInfo({ ...newBannerInfo, img: response.data.url });

                sendInfo(newObj);

            }).catch(function (error) {
                console.log(error);
            });
    }
    const didConfirmSlim = (data) => {
        // slim instance reference
        console.log(data);
        console.log(data.output.image);
        var canvas = data.output.image;
        var fileName = data.input.name;
        canvas.toBlob(function (blob) {
            setSelectedFile({ file: blob, name: fileName });
        });
    }
    console.log(newBannerInfo)
    return (
        <>
            <Dialog open={showModal} onClose={() => setShowModal(false)} aria-labelledby="" maxWidth="sm" fullWidth>
                <DialogTitle>Nuevo Banner</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Titulo"
                                name="title"
                                value={newBannerInfo.title}
                                onChange={handleChange}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Slim
                                ratio="142:57"
                                size="1420,570"
                                label="Haga click para subir una imagen (1420x570)"
                                instantEdit="true"
                                didConfirm={didConfirmSlim}
                            >
                                <input type="file" name="foo" />
                            </Slim>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Estado</FormLabel>
                                <RadioGroup row aria-label="gender" name="visible" value={newBannerInfo.visible} onChange={handleChange}>
                                    <FormControlLabel value="1" control={<Radio />} label="Visible" />
                                    <FormControlLabel value="2" control={<Radio />} label="Programado" />
                                    <FormControlLabel value="3" control={<Radio />} label="Oculto" />
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        {newBannerInfo.visible == "2" &&
                            <>
                                <Grid item md={6}>
                                    <DatePicker
                                        label="Fecha Inicio"
                                        value={newBannerInfo.dateFrom}
                                        onChange={value => setNewBannerInfo({ ...newBannerInfo, dateFrom: value })}
                                    />
                                </Grid>
                                <Grid item md={6}>
                                    <DatePicker
                                        label="Fecha Fin"
                                        value={newBannerInfo.dateTo}
                                        onChange={value => setNewBannerInfo({ ...newBannerInfo, dateTo: value })}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowModal(false)} color="initial">
                        Cancelar
            </Button>
                    <Button onClick={UploadDocument} color="primary"
                        disabled={loading}>
                        Guardar
            </Button>
                </DialogActions>
            </Dialog>
            {/* <div ref={modal} data-uk-modal>
                <div className="uk-modal-dialog uk-margin-auto-vertical">
                    <div className="uk-modal-header">
                        <h2 className="uk-modal-title">Nuevo Banner</h2>
                    </div>
                    <div className="uk-modal-body uk-grid-small" data-uk-grid>
                        <div className="uk-width-1-1">
                            <div className="md-input-wrapper md-input-wrapper-outlined">
                                <label>Titulo</label>
                                <input type="text" name='title' className="md-input" onChange={handleChange} 
                                required value={newBannerInfo.title} />
                            </div>
                        </div>
                        <div className="uk-width-1-1">
                            <Slim
                                ratio="2:1"
                                minSize={{ width: 600, height: 400 }}
                                label="Haga click para subir una imagen"
                                instantEdit="true"
                                didConfirm={didConfirmSlim}
                            >
                                <input type="file" name="foo" />
                            </Slim>
                        </div>
                        <div className="md-radio md-radio-inline">
                            <input id="3" type="radio" name="visible" onChange={handleChangeRadio} checked={newBannerInfo.visible === "1"} value={"1"} />
                            <label for="3">Visible</label>
                        </div>
                        <div className="md-radio md-radio-inline">
                            <input id="radio_program" type="radio" name="visible" onChange={handleChangeRadio} checked={newBannerInfo.visible === "2"} value={"2"} />
                            <label for="radio_program">Programado</label>
                        </div>
                        <div className="md-radio md-radio-inline">
                            <input id="radio_program_oculto" type="radio" name="visible" onChange={handleChangeRadio} checked={newBannerInfo.visible === "3" ? true : false} value={"3"} />
                            <label for="radio_program_oculto">Oculto</label>
                        </div>
                        {newBannerInfo.visible == "2" &&
                            <>
                                <div className="uk-width-1-1">
                                    <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled">
                                        <label>Fecha Inicio</label>
                                        <input type="date" name='dateFrom' className="md-input" onChange={handleChange} required value={newBannerInfo.dateFrom} />
                                    </div>
                                </div>
                                <div className="uk-width-1-1">
                                    <div className="md-input-wrapper md-input-wrapper-outlined md-input-filled ">
                                        <label>Fecha Fin</label>
                                        <input type="date" name='dateTo' className="md-input" onChange={handleChange} required value={newBannerInfo.dateTo} />
                                    </div>
                                </div>
                            </>
                        }
                    </div>
                    <div className="uk-modal-footer uk-text-right">
                        <button className="md-btn md-btn-text uk-modal-close" type="button">Cancelar</button>
                        <button onClick={UploadDocument} className="md-btn md-color-white md-btn-primary">Crear Banner</button>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default AddBannerModal
