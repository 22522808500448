import settings from '../../settings.js';

import { setAlert } from '../alert/alert.actions';

export const fetchStart = () => ({
    type: "FETCH_START"
});
export const fetchSuccess = () => ({
    type: "FETCH_SUCCESS"
});
export const fetchFailure = errorMessage => ({
    type: "FETCH_FAILURE",
    payload: errorMessage
});

const getRecipes = () => async (dispatch, getState) => {

    const state = getState();
    dispatch(fetchStart());

    try {
        const response = await fetch(`${settings.connectionString}/api/recipes/private`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'GET'
        });
        if (response.status >= 400 && response.status < 500) {
            throw "Error" + response.status;
        }
        if (response.status >= 500 && response.status < 600) {
        }
        const res = await response.json();
        console.log("set alert");
        /* dispatch(setAlert({ msg: "hola" })); */
        dispatch({
            type: 'GET_RECIPES',
            payload: res
        });

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
};

const getRecipesFront = () => async dispatch => {
    dispatch(fetchStart());

    try {
        const response = await fetch(`${settings.connectionString}/api/recipes/public`, {
            method: 'GET'
        });
        const res = await response.json();
        dispatch({
            type: 'GET_RECIPES_FRONT',
            payload: res
        });

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
};

const getRecipe = (id) => async (dispatch, getState) => {

    const state = getState();
    dispatch(fetchStart());
    console.log("getRecipe");

    try {

        const response = await fetch(`${settings.connectionString}/api/recipes/${id}`, {
            method: 'GET'
        });
        const res = await response.json();

        console.log("getrecipe response");
        console.log(res);
        res.status = res.status === "1" ? true : false;
        dispatch(fetchSuccess());

        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const addRecipe = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("addRecipe");
    try {

        console.log(body);
        const response = await fetch(`${settings.connectionString}/api/recipes`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        console.log(response);
        const res = await response.json();
        if (res) {
            dispatch(setAlert({ msg: "Producto creado" }));
        }

        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo crear el recipe" }));
    }
    return false;
}

const updateRecipe = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("updateRecipe");
    try {

        console.log(body);
        const response = await fetch(`${settings.connectionString}/api/recipes`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'PUT',
            body: JSON.stringify(body)
        });
        if (response.status >= 400 && response.status < 500) {
            throw "Error" + response.status;
        }
        if (response.status >= 500 && response.status < 600) {
        }
        console.log(response);
        const res = await response.json();
        dispatch(fetchSuccess());
        if (res) {
            dispatch(fetchSuccess());
            dispatch(setAlert({ msg: "Receta actualizada" }));
        }
        return true;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo actualizar el recipe" }));
    }
    return false;
}

const deleteRecipe = (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("deleteRecipe");
    try {
        const response = await fetch(`${settings.connectionString}/api/recipes/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'DELETE'
        });

        console.log(response);
        const res = await response.json();
        if (res) {
            dispatch(getRecipes());
            dispatch(setAlert({ msg: "Receta eliminado" }));
        }

        return true;

    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "Error al eliminar" }));
    }
    return false;
}

export default {
    getRecipes,
    getRecipesFront,
    addRecipe,
    getRecipe,
    updateRecipe,
    deleteRecipe
}