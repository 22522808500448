import React, { useState } from 'react'
import ContentBefore from './components/ContentBefore'
import Grid from '@material-ui/core/Grid'
import GeneralContainer from './components/GeneralContainer'
import { CircularProgress, MenuItem, Paper, TextField, Typography, Button, Icon, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box } from '@material-ui/core'
import DatePicker from './components/utils/DatePicker'
import { useSelector, useDispatch } from 'react-redux'
import reportsActions from '../../redux/reports/reports.actions';
import { setAlert } from '../../redux/alert/alert.actions';
import QuantityProductsReport from './components/Reports/QuantityProductsReport'
import BestSellersReport from './components/Reports/BestSellersReport'
import ClientsAddressesReport from './components/Reports/ClientsAddressesReport'
import ProductGeneralReport from './components/Reports/ProductGeneralReport'
import OrdenesGeneralReport from './components/Reports/OrdenesGeneralReport'
import ClientGeneralReport from './components/Reports/ClientGeneralReport'
import MonthlyProductsReport from './components/Reports/MonthlyProductsReport'

const Reports = () => {

    const dispatch = useDispatch();
    const [dataResult, setDataResult] = useState([]);
    const [resultMessage, setResultMessage] = useState("");

    const reportsReducer = useSelector(state => state.reportsReducer);

    const [formData, setFormData] = useState({
        dateFrom: new Date(),
        dateTo: new Date(),
        type: "",
        type_text: "",
    })

    const handleChange = event => {
        /*  event.preventDefault(); */
        const { value, name } = event.target;
        console.log(name);
        console.log(name + " " + value);
        setFormData({ ...formData, [name]: value });
        setResultMessage("");
        setDataResult([]);
    }

    const onClickSearch = async e => {

        let flagWhites = true;
        let flagGeneral = true;
        if (formData.type === "") {
            flagWhites = false;
        }
        if (!flagWhites) {
            flagGeneral = false;
            dispatch(setAlert({ msg: 'Seleccione un reporte' }));
        }
        console.log(flagGeneral);
        if (flagGeneral) {
            const data = {
                dateFrom: formData.dateFrom.toLocaleString('en-US'),
                dateTo: formData.dateTo.toLocaleString('en-US'),
            }
            let result = "";
            switch (formData.type) {
                case "R1":
                    result = await dispatch(reportsActions.getQuantityProducts(data));
                    break;
                case "R2":
                    result = await dispatch(reportsActions.getReportClientsAddresses(data));
                    break;
                case "R3":
                    result = await dispatch(reportsActions.getReportBestSellers(data));
                    break;
                case "R4":
                    result = await dispatch(reportsActions.getOrders(data));
                    break;
                case "R5":
                    result = await dispatch(reportsActions.getProducts(data));
                    break;
                case "R6":
                    result = await dispatch(reportsActions.getClients(data));
                    break;
                case "R7":
                    result = await dispatch(reportsActions.getMonthlyOrderProducts(data));
                    break;
                default:
                    break;
            }
            setDataResult(result);
            if (!result) {
                setResultMessage("No se encontraron resultados para esta búsqueda");
            }
            console.log(result)
        }
    }
    const menu = [
        { id: "R1", name: "Ordenes - Cantidad de productos" },
        { id: "R2", name: "Ordenes - Detalles de entrega" },
        { id: "R3", name: "Ordenes - Productos más vendidos" },
        { id: "R4", name: "Ordenes - Información General" },
        { id: "R5", name: "Productos - Información General" },
        { id: "R6", name: "Clientes - Información General" },
        { id: "R7", name: "Ordenes - Reporte Mensual" },
    ]
    const selectedMenu = menu.find(item => (item.id === formData.type));

    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return (
        <>
            <ContentBefore title="Reportes" />

            <GeneralContainer>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper style={{ padding: "16px" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="body1" color="initial">
                                        La busqueda de reportes es deteminada por un tipo y un rango de fechas. Porfavor complete la siguiente información para generar su reporte
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12}>
                                    <TextField
                                        fullWidth
                                        label="Seleccione un reporte"
                                        select
                                        name="type"
                                        value={formData.type}
                                        onChange={handleChange}
                                        variant="outlined"
                                    >
                                        {menu.map(item => (
                                            <MenuItem value={item.id}>{item.name}</MenuItem>
                                        ))}
                                    </TextField>
                                </Grid>
                                {formData.type !== "R6" &&
                                    <>
                                        <Grid item xs={12} md={6}>
                                            <DatePicker
                                                label="Fecha Desde"
                                                value={formData.dateFrom}
                                                onChange={value => setFormData({ ...formData, dateFrom: value })}
                                            />
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <DatePicker
                                                label="Fecha Hasta"
                                                value={formData.dateTo}
                                                onChange={value => setFormData({ ...formData, dateTo: value })}
                                            />
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={12} align="right">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        startIcon={<Icon>search</Icon>}
                                        onClick={onClickSearch}
                                    >
                                        Buscar
                                    </Button>
                                </Grid>
                            </Grid>
                        </Paper>

                    </Grid>
                    <Grid item xs={12}>

                        {reportsReducer.loading ?
                            <CircularProgress />
                            :
                            <>
                                {dataResult.length > 0 ?
                                    <Paper style={{ padding: "16px" }}>
                                        <>
                                            <Box pb={2}>
                                                <Typography variant="h5" color="initial">
                                                    {selectedMenu && selectedMenu.name}</Typography>
                                            </Box>
                                            {formData.type !== "R6" &&
                                                <>
                                                    <Typography component="div" variant="body1" color="initial">
                                                        <Box component="span" fontWeight="fontWeightBold"> Del:</Box> {formData.dateFrom.toLocaleString('es-US', options)}
                                                    </Typography>
                                                    <Typography component="div" variant="body1" color="initial">
                                                        <Box component="span" fontWeight="fontWeightBold">Al:</Box> {formData.dateTo.toLocaleString('es-US', options)}
                                                    </Typography>
                                                </>
                                            }

                                            <Box pt={2}>
                                                {selectedMenu && selectedMenu.id == "R1" &&
                                                    <QuantityProductsReport dataResult={dataResult} dateFrom={formData.dateFrom} dateTo={formData.dateTo} />
                                                }
                                                {selectedMenu && selectedMenu.id == "R2" &&
                                                    <ClientsAddressesReport dataResult={dataResult} dateFrom={formData.dateFrom} dateTo={formData.dateTo} />
                                                }
                                                {selectedMenu && selectedMenu.id == "R3" &&
                                                    <BestSellersReport dataResult={dataResult} dateFrom={formData.dateFrom} dateTo={formData.dateTo} />
                                                }
                                                {selectedMenu && selectedMenu.id == "R4" &&
                                                    <OrdenesGeneralReport dataResult={dataResult} dateFrom={formData.dateFrom} dateTo={formData.dateTo} />
                                                }
                                                {selectedMenu && selectedMenu.id == "R5" &&
                                                    <ProductGeneralReport dataResult={dataResult} dateFrom={formData.dateFrom} dateTo={formData.dateTo} />
                                                }
                                                {selectedMenu && selectedMenu.id == "R6" &&
                                                    <ClientGeneralReport dataResult={dataResult} />
                                                }
                                                {selectedMenu && selectedMenu.id == "R7" &&
                                                    <MonthlyProductsReport dataResult={dataResult} dateFrom={formData.dateFrom} dateTo={formData.dateTo} />
                                                }
                                            </Box>
                                        </>
                                    </Paper>
                                    :
                                    <Paper style={{ padding: "16px" }}>
                                        <Box display="flex" p={4} justifyContent="center">{resultMessage}</Box>
                                    </Paper>
                                }

                            </>
                        }
                    </Grid>
                </Grid>
            </GeneralContainer>
        </>
    )
}

export default Reports
