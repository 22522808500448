import Orders from "./Orders.js";
import Banners from "./Banners.js";
import Products from "./Products.js";
import Productores from "./Productores.js";
import Recetas from "./Recetas.js";
import Reports from "./Reports.js";
import Payment from "./Payment.js";
import DeliveryZones from "./DeliveryZones.js";
import ProductsCategories from "./ProductsCategories.js";
import Clients from "./Clients.js";
import UserAdmins from "./UserAdmins.js";
import AutoDiscounts from "./AutoDiscounts.js";
import Vouchers from "./Vouchers.js";
import GeneralSettings from "./GeneralSettings.js";

const Routes = [

  {
    name: "Ventas",
    icon: 'flag',
    path: "/admin/orders",
    rol: 'admin',
    submenu: [
      {
        name: "Ordenes",
        path: "/admin/orders",
        component: Orders,
      },
      {
        name: "Clientes",
        path: "/admin/clients",
        component: Clients,
      }
    ]
  },
  {
    name: "Reportes",
    path: "/admin/reports",
    icon: 'leaderboard',
    component: Reports,
    rol: 'admin'
  },
  {
    name: "Productos",
    path: "/admin/products",
    icon: 'category',
    rol: 'admin',
    submenu: [
      {
        name: "Productos",
        path: "/admin/products",
        component: Products,
      },
      {
        name: "Categorias",
        path: "/admin/products_categories",
        component: ProductsCategories,
      }
    ]
  },

  {
    name: "Website",
    icon: 'public',
    rol: 'admin',
    submenu: [
      {
        name: "Banners",
        path: "/admin/banners",
        icon: 'insert_photo',
        component: Banners,
      },
      {
        name: "Productores",
        path: "/admin/partners",
        component: Productores,
      },
      {
        name: "Recetas",
        path: "/admin/recipes",
        icon: 'restaurant_menu',
        component: Recetas,
      },
      {
        name: "General",
        path: "/admin/general-settings",
        icon: 'settings',
        component: GeneralSettings,
      }
    ]
  },
  {
    name: "Marketing",
    icon: 'star',
    rol: 'admin',
    submenu: [
      {
        name: "Cupones de descuento",
        path: "/admin/vouchers",
        component: Vouchers,
      },
      {
        name: "Descuentos automáticos",
        path: "/admin/auto_discounts",
        component: AutoDiscounts,
      },
    ]
  },
  {
    name: "Configuración",
    icon: 'settings',
    rol: 'admin',
    submenu: [
      {
        name: "Usuarios",
        path: "/admin/users",
        component: UserAdmins,
      },
      {
        name: "Pagos",
        path: "/admin/settings_payment",
        component: Payment,
      },
      {
        name: "Zonas de delivery",
        path: "/admin/settings_delivery",
        component: DeliveryZones,
      }
    ]
  },
];

export default Routes;
