import React, { useState, useEffect } from 'react';

import UIkit from 'uikit';
import MDUIkit from 'md-uikit';
import { Link } from "react-router-dom";
import { Typography, Icon, Fab, Card, CardActionArea, CardContent, CardMedia, CardActions, Button, CircularProgress } from '@material-ui/core';
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import { useDispatch, useSelector } from "react-redux";
import bannerActions from '../../redux/banners/banners.actions';

import settings from '../../settings.js';
import ContentBefore from './components/ContentBefore';
import AddBannerModal from './components/modals/AddBannerModal';
import GeneralContainer from './components/GeneralContainer';

const useStyles = makeStyles((theme) => ({
    media: {
        height: 140,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        zIndex: 2,
    },
}));
const Products = () => {

    const bannersRed = useSelector(state => state.bannersReducer);
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const classes = useStyles();

    useEffect(() => {
        MDUIkit.components();
        //fetchBanners();
        dispatch(bannerActions.getBanners())
    }, []);

    return (
        <>
            <ContentBefore title="Banners" />
            <GeneralContainer>
                {bannersRed.loading ?
                    <CircularProgress />
                    :
                    <Grid container spacing={2}>
                        {bannersRed.banners.length > 0 ? (
                            bannersRed.banners.map(item => (
                                <Grid item xs={6} md={4}>
                                    <Card>
                                        {/* <CardActionArea> */}
                                        <CardMedia
                                            className={classes.media}
                                            image={`${settings.connectionString}/files/banners/${item.img}`}
                                            title="Contemplative Reptile"
                                        />
                                        <CardContent>
                                            <Typography gutterBottom variant="h5" component="h2">
                                                {item.title}
                                            </Typography>
                                            <Typography variant="body2" color="textSecondary" component="p">
                                                {(() => {
                                                    switch (item.visible) {
                                                        case '1':
                                                            return <span class="uk-label uk-label-success">Visible</span>;
                                                        case '2':
                                                            return <span class="uk-label uk-label-warning">Programado</span>;
                                                        case '3':
                                                            return <span class="uk-label md-bg-blue-grey-700">oculto</span>;
                                                    }
                                                })()}
                                            </Typography>
                                        </CardContent>
                                        {/* </CardActionArea> */}
                                        <CardActions>
                                            <Button component={Link} to={`/admin/banners_detail/${item.bannerId}`} size="small" color="primary">
                                                Ver / Editar</Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            ))
                        ) : (
                            <Typography>No hay data.</Typography>
                        )}
                    </Grid>
                }
                <AddBannerModal showModal={showModal} setShowModal={setShowModal} /* fetchBanners={fetchBanners}  */ />
                <Fab onClick={() => setShowModal(true)} className={classes.fab} color="primary" aria-label="add">
                    <Icon>add</Icon>
                </Fab>
            </GeneralContainer>
        </>
    );
}
export default Products;