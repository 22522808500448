import settings from '../../settings.js';

import { setAlert } from '../alert/alert.actions';


export const fetchStart = () => ({
    type: "FETCH_START"
});
export const fetchSuccess = () => ({
    type: "FETCH_SUCCESS"
});
export const fetchFailure = errorMessage => ({
    type: "FETCH_FAILURE",
    payload: errorMessage
});

const getProductCategories = () => async (dispatch,getState) => {
    const state = getState();

    dispatch(fetchStart());
    try {
        const response = await fetch(`${settings.connectionString}/api/categories/private`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'GET'
        });
        const res = await response.json();
        /* console.log("getProductCategories")
        console.log(res) */
        dispatch({
            type: "GET_CATEGORIES",
            payload: res
        })
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
};

const getProductCategoriesFront = () => async (dispatch,getState) => {
    const state = getState();

    dispatch(fetchStart());
    
    try {
        const response = await fetch(`${settings.connectionString}/api/categories/public`, {
            headers: {
                'Content-Type': 'application/json'
            },
            method: 'GET'
        });
        const res = await response.json();
        dispatch({
            type: "GET_CATEGORIES",
            payload: res
        })
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
};

const getCategory = (id) => async (dispatch,getState) => {
    const state = getState();
    dispatch(fetchStart());
    try {
        const response = await fetch(`${settings.connectionString}/api/categories/${id}`, {
            method: 'GET'
        });
        const res = await response.json();
        res.status = res.status === "1" ? true : false;
        dispatch(fetchSuccess());
        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const addCategory = (body) => async (dispatch,getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("addCategory");
    try {
        const response = await fetch(`${settings.connectionString}/api/categories`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'POST',
            body: JSON.stringify(body)
        });
        console.log(response);
        /* const text = await response.text();
        console.log(text); */
        const res = await response.json();
        dispatch(fetchSuccess());
        dispatch(getProductCategories());
        if (res) {
            if (body.parentId === "0") {
                dispatch(setAlert({ msg: "Categoria creada" }));
            } else {
                dispatch(setAlert({ msg: "Subcategoría creada" }));
            }
        }
        return true;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo crear la categoria" }));
    }
    return false;
}

const updateCategory = (body) => async (dispatch,getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("updateCategory");
    try {
        console.log(body);
        const response = await fetch(`${settings.connectionString}/api/categories`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'PUT',
            body: JSON.stringify(body)
        });
        console.log(response);
        dispatch(setAlert({ msg: "Categoria actualizada" }));
        dispatch(getCategory(body.categoryId));
        dispatch(getProductCategories());

        return true;

    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "Error al eliminar" }));
    }
    return false;
}
const deleteCategory = (id) => async (dispatch,getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("updateCategory");
    try {
        const response = await fetch(`${settings.connectionString}/api/categories/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'DELETE'
        });

        dispatch(setAlert({ msg: "Categoria eliminada" }));
        dispatch(getProductCategories());
        return true;

    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "Error al eliminar" }));
    }
    return false;
}

export default {
    getProductCategories,
    getProductCategoriesFront,
    getCategory,
    addCategory,
    updateCategory,
    deleteCategory
}