import React, { useState, useEffect } from 'react';

import { Link, useHistory } from "react-router-dom";
import ContentBefore from './components/ContentBefore';
import GeneralContainer from './components/GeneralContainer';

import { Box, CircularProgress, Paper, Grid, InputBase, InputAdornment, Icon, Typography, Avatar } from '@material-ui/core';
import GeneralRow from './components/utils/GeneralRow';
import ResponsiveMenuButton from './components/utils/ResponsiveMenuButton';

import { useDispatch, useSelector } from "react-redux";
import paymentsActions from '../../redux/payments/payments.actions';
import { amber, blue, blueGrey, green } from '@material-ui/core/colors';
import FAB from './components/utils/FAB';
import DeleteModal from './components/modals/DeleteModal';
import culqi_icon from '../../assets/img/items/brandCulqi-white.svg';

const ApplicantHistoryPage = () => {

    const paymentsReducer = useSelector(state => state.paymentsReducer);
    const dispatch = useDispatch();

    const history = useHistory();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({
        orderId: 0
    });
    //const classes = useStyles();

    useEffect(() => {
        dispatch(paymentsActions.getPayments());
    }, []);


    const menu = [
        {
            title: "Ver/Editar",
            icon: "visibility",
            action: e => onClickView(e)
        },
        {
            title: "Eliminar tipo de pago",
            icon: "delete",
            action: e => onClickDelete(e)
        },
    ]
    const onClickView = item => {
        console.log("onClickView");
        history.push(`/admin/payments_detail/${item.paymentId}`)
    }

    const onClickDelete = item => {
        setSelectedItem(item);
        setShowDeleteModal(true);
    }
    const deleteProgram = item => {
        if (selectedItem.paymentId !== "2") {

            const result = dispatch(paymentsActions.deletePayment(selectedItem.paymentId));
            if (result) {
                setShowDeleteModal(false);
            }
        } else {
            alert("No es posible borrar pago con tarjeta");
            setShowDeleteModal(false);
        }
    }

    return (
        <>
            <ContentBefore title="Tipo de Pagos" />
            <GeneralContainer>
                {paymentsReducer.loading ?
                    <CircularProgress />
                    :
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper>
                                {paymentsReducer.payments.length > 0 ?
                                    paymentsReducer.payments.map((item, index) => (
                                        <GeneralRow key={index}>
                                            <Box display="flex" alignItems="center">
                                                <Box flexGrow={1}>
                                                    <Box display="flex" alignItems="center">
                                                        <Box mr={1}>
                                                            {item.paymentId == "2" ?
                                                                <>
                                                                    {/*<Box bgcolor="black" p={1}>
                                                                     <img src={culqi_icon} width="20px" alt="ca" ></img> 
                                                                </Box>*/}
                                                                    <Icon fontSize="large" color="primary">credit_card</Icon>
                                                                </>
                                                                :
                                                                <Icon fontSize="large" color="primary">account_balance_wallet</Icon>
                                                            }
                                                        </Box>
                                                        <Box >
                                                            <Typography component={Link} to={`/admin/payments_detail/${item.paymentId}`} variant="h6">
                                                                <Box color="text.primary">{item.name}</Box>
                                                            </Typography>
                                                            <Typography component="span" variant="body1" color="initial">
                                                                {item.status == "1" ?
                                                                    <Box fontWeight="fontWeightBold" color="success.main">Activo</Box>
                                                                    :
                                                                    <Box fontWeight="fontWeightBold" color="text.secondary">Inactivo</Box>
                                                                }
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box >
                                                    {item.paymentId !== "2" &&
                                                        <ResponsiveMenuButton menu={menu} item={item} title={"Orden: " + item.refNumber} />
                                                    }
                                                </Box>
                                            </Box>
                                        </GeneralRow>
                                    )) :
                                    <Box>No existen tipos de pagos</Box>
                                }
                            </Paper>
                        </Grid>
                    </Grid>

                }
            </GeneralContainer>
            <DeleteModal showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                title={selectedItem.name}
                action={deleteProgram}
            />
            <FAB component={Link} to="/admin/payments_detail/0">
                <Icon>add</Icon>
            </FAB>
        </>
    );
}
export default ApplicantHistoryPage;