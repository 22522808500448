import React, { useEffect, useState } from 'react'
import CartActions from '../../../providers/cart/cart.actions';

const TotalLabel = () => {
    const cart = CartActions();

    useEffect(() => {
        //cart.getCartSubTotal();
    }, [cart.cartItems])

    return (
        <>
            <h5 className="md-color-blue-grey-900 uk-margin-remove">S/. {cart.subTotal && cart.subTotal.toFixed(2)}</h5>
        </>
    )
}

export default TotalLabel
