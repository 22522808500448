import { Box, FormControlLabel, Grid, Icon, IconButton, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText, Radio, Typo, Gridgraphy, Button, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import UIkit from 'uikit';
import { FormattedMessage } from 'react-intl';
import zonesActions from '../../../redux/delivery_zone/delivery_zone.actions';
import { useDispatch, useSelector } from "react-redux";
import DOMPurify from "dompurify";

import settings from '../../../settings.js';

const CheckoutStep1 = ({ deliveryType, setDeliveryType, selectedAddress, setSelectedAddress, Steps,
    setSteps, currentStep, setCurrentStep, addressDetails, setAddressDetails,
    selectedDeliveryOption, setSelectedDeliveryOption }) => {

    /* const [deliveryOptionsArray, setDeliveryOptionsArray] = useState([]); */
    const [dZCategoriesArray, setDZCategoriesArray] = useState([]);
    const [addressesArray, setAddressesArray] = useState([]);
    const [boolNewAddress, setBoolNewAddress] = useState(false);

    const userReducer = useSelector(state => state.userReducer);
    const zones = useSelector(state => state.zonesReducer.zones);
    const loading = useSelector(state => state.zonesReducer.loading);
    const dispatch = useDispatch();

    useEffect(() => {

    }, []);

    useEffect(() => {
        const getData = async () => {
            const data = await dispatch(zonesActions.getCategoriesZones(1));
            setDZCategoriesArray(data);
            await dispatch(zonesActions.getZonesFront());

            getAddresses();
        }
        getData();

    }, [])

    async function getAddresses() {
        try {
            console.log("getAddresses");
            console.log(userReducer.currentUserFront.token)
            const response = await fetch(`${settings.connectionString}/api/addresses/byUser`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userReducer.currentUserFront.token
                },
                method: 'GET'
            });
            if (response.status >= 400 && response.status < 500) {
                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();

            setAddressesArray(res);
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    }
    async function deleteAddress(addressId) {
        console.log("deleteAddress");
        try {
            const response = await fetch(`${settings.connectionString}/api/addresses/${addressId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + userReducer.currentUserFront.token
                },
                method: 'DELETE',
            });
            if (response.status >= 400 && response.status < 500) {
                //UIkit.notification('Information error.');

                throw "Error" + response.status;
            }
            if (response.status >= 500 && response.status < 600) {
                UIkit.notification('Error. Please try later.');
            }
            const res = await response.json();
            getAddresses();
            console.log(res);
        } catch (error) {
            console.log(error);
        }
    }
    const handleChange = event => {
        const { value, name } = event.target;
        setAddressDetails({ ...addressDetails, [name]: value })
    }
    const handleChangeDeliveryOptions = event => {
        console.log("handleChangeDeliveryOptions")
        const { value, name } = event.target;
        const item = zones.find(item => item.deliveryZoneId == value);
        console.log(item)
        setSelectedDeliveryOption(item);
    }
    const handleSelectedAddress = addressId => event => {
        console.log("handleSelectedAddress")
        /* const { value, name } = event.target; */
        const foundedAddress = addressesArray.find(item => item.addressId == addressId);
        console.log(foundedAddress);
        setSelectedAddress(foundedAddress);

        /* Update selected deliveryoption */
        const item = zones.find(item => item.deliveryZoneId == foundedAddress.delivery_zone_id);

        console.log(item)
        setSelectedDeliveryOption(item);
    }

    const saveAddress = () => {
        var control = false;
        if (boolNewAddress || addressesArray.length === undefined) {
            if (addressDetails.address_street != "") {
                setSelectedAddress({ ...addressDetails, addressId: "" });
                if (selectedDeliveryOption.deliveryZoneId != 0) {
                    control = true;
                } else {
                    let message = "Please select a Delivery Zone.";
                    if (userReducer.language === "es") {
                        message = "Por favor seleccione una Zona Delivery";
                    }
                    alert(message);
                }
            } else {
                let message = "Enter the address details to continue.";
                if (userReducer.language === "es") {
                    message = "Ingrese los detalles de su dirección para continuar";
                }
                alert(message);
            }
        } else {
            if (selectedDeliveryOption.deliveryZoneId != 0 && selectedAddress.addressId !== 0 && selectedAddress.addressId !== "") {

                control = true;
            }
            else {
                let message = "Select an adress to continue.";
                if (userReducer.language === "es") {
                    message = "Seleccione una dirección para continuar";
                }
                alert(message);
            }
        }
        if (control) {
            const newObject = [...Steps];
            const selectedStep = newObject.find(item => item.step == 1);
            selectedStep.verified = true;
            setCurrentStep(2);
            setSteps(newObject);
        }
    }

    const saveStep1 = () => {
        let flag = true;
        if (deliveryType === "1") {
            console.log(selectedAddress);
            if (selectedAddress.addressId == 0) {
                flag = false;
            }
        }

        if (deliveryType === "2") {
            console.log("selectedDeliveryOption");
            console.log(selectedDeliveryOption);
            if (selectedDeliveryOption.deliveryZoneId == 0) {
                flag = false;
            } else {
                setSelectedAddress(1);
            }

        }
        if (flag) {
            const newObject = [...Steps];
            const selectedStep = newObject.find(item => item.step == 1);
            selectedStep.verified = true;
            setCurrentStep(2);
            setSteps(newObject);
        } else {
            let message = "Choose an option to continue."
            if (userReducer.language === "es") {
                message = "Escoja una opción para continuar";
            }
            alert(message);
        }

        console.log();
    }
    const onClickCategory = (item) => event => {
        setSelectedAddress({
            addressId: 0
        });
        setAddressDetails({
            address_street: "",
            reference: "",
            address_street_2: ""
        })
        setDeliveryType(item.deliveryZoneCategoryId);
        setSelectedDeliveryOption({
            deliveryZoneId: 0,
            fee: 0
        })
    }
    const onClickAddNewAddress = () => {
        setSelectedAddress({
            addressId: 0
        });
        setBoolNewAddress(true);
    }
    const onClickDeleteAddress = addressId => e => {
        let response = window.confirm("Deseas eliminar esta dirección?");
        if (response) {
            setSelectedAddress({
                addressId: 0
            });
            deleteAddress(addressId);
        } else {
            console.log("You pressed Cancel!");
        }
    }

    const handleClickStores = (deliveryZoneId) => e => {
        e.preventDefault();
        const item = zones.find(item => item.deliveryZoneId == deliveryZoneId);
        console.log(item)
        setSelectedDeliveryOption(item);
    }

   
    return (
        <>
            <div className="uk-card md-bg-white">
                <div className="uk-card-header border-bottom">
                    <div className="uk-flex uk-flex-middle">
                        <div>
                            {Steps[0].verified == true ?
                                currentStep == 1 ? <i className="fas fa-truck"></i>
                                    :
                                    <i className="material-icons md-color-green-500">check_circle</i>
                                :
                                <>
                                    <i className="fas fa-truck"></i>
                                    {/* <Typography variant="subtitle1" className="uk-card-title" style={{ fontWeight: "bold" }}>1.</Typography> */}
                                </>
                            }
                        </div>
                        <div style={{ marginLeft: "8px" }}>
                            <h3 className="uk-card-title uk-margin-remove-bottom uk-text-left">
                                <FormattedMessage
                                    id="checkout_step1_title"
                                    defaultMessage="How would you like to receive your groceries?"
                                />
                            </h3>
                        </div>
                    </div>
                </div>
                <div className="uk-card-body">
                    {currentStep == 1 ? (
                        <div>
                            <div className="uk-grid" data-uk-grid style={{ marginBottom: "16px" }}>
                                {dZCategoriesArray.length > 0 && dZCategoriesArray.map((item) => (
                                    <div className="uk-width-1-2" key={item.deliveryZoneCategoryId}>
                                        <div className={`uk-card uk-card-body pointer ${deliveryType == item.deliveryZoneCategoryId ? "selected" : ""}`}
                                            onClick={onClickCategory(item)}>
                                            <div className="uk-flex uk-flex-middle">
                                                <div>
                                                    {item.deliveryZoneCategoryId === "1" && <i className="fas fa-truck"></i>}
                                                    {item.deliveryZoneCategoryId === "2" && <i className="fas fa-shopping-bag"></i>}
                                                </div>
                                                <div style={{ marginLeft: "8px" }}>
                                                    <h5 className="uk-margin-remove-bottom">{userReducer.language == "es" ?
                                                        item.name_es : item.name_en
                                                    }</h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            {deliveryType === "1" ? (
                                <>

                                    <h4 className="uk-text-left">
                                        <FormattedMessage
                                            id="checkout_step1_title_orderDetails"
                                            defaultMessage="Address details"
                                        />
                                    </h4>
                                    {(boolNewAddress || addressesArray.length === undefined) ? (
                                        <>
                                            <div className='uk-grid-small' data-uk-grid>
                                                <div className='uk-width-1-1'>

                                                    <p className="uk-margin-remove uk-text-left">
                                                        <FormattedMessage
                                                            id="checkout_step1_address"
                                                            defaultMessage="Address"
                                                        />
                                                    </p>
                                                    <div className="md-input-wrapper md-input-no-label md-input-dense">
                                                        <input type="text" className="md-input" name="address_street" onChange={handleChange}
                                                            required value={addressDetails.address_street} />
                                                    </div>
                                                </div>
                                                <div className='uk-width-1-1'>
                                                    <p className="uk-margin-remove uk-text-left">
                                                        <FormattedMessage
                                                            id="checkout_step1_address_2"
                                                            defaultMessage="Address 2"
                                                        />
                                                    </p>
                                                    <div className="md-input-wrapper md-input-no-label md-input-dense">
                                                        <input type="text" placeholder="Apartment, Floor, etc" className="md-input" name="address_street_2"
                                                            onChange={handleChange} required value={addressDetails.address_street_2} />
                                                    </div>
                                                </div>
                                                <div className='uk-width-1-1'>
                                                    <p className="uk-margin-remove uk-text-left">
                                                        <FormattedMessage
                                                            id="checkout_step1_address_reference"
                                                            defaultMessage="Address reference"
                                                        />
                                                    </p>
                                                    <div className="md-input-wrapper md-input-no-label md-input-dense">
                                                        <input type="text" className="md-input" name="reference" onChange={handleChange} value={addressDetails.reference} />
                                                    </div>
                                                </div>
                                                <div className='uk-width-1-1'>
                                                    {addressesArray.length > 0 &&
                                                        <Button
                                                            variant="text"
                                                            size="small"
                                                            color="primary"
                                                            startIcon={<Icon>navigate_before</Icon>}
                                                            onClick={() => setBoolNewAddress(false)}
                                                        >
                                                            <FormattedMessage
                                                                id="checkout_step1_addresses"
                                                                defaultMessage="My addresses"
                                                            />
                                                        </Button>
                                                    }
                                                </div>
                                            </div>

                                        </>
                                    ) : (
                                        <Grid container spacing={1}>

                                            <Grid item xs={12}>
                                                <p className=" uk-text-left" >
                                                    <FormattedMessage
                                                        id="checkout_step1_address_message"
                                                        defaultMessage="We found previous addresses for your account. Please choose one or press add new address to continue"
                                                    />
                                                </p>
                                                <p className="uk-margin-remove uk-text-left">
                                                    <FormattedMessage
                                                        id="checkout_step1_address_choose"
                                                        defaultMessage="Choose an address"
                                                    />
                                                </p>

                                                <List >
                                                    {addressesArray.length > 0 && addressesArray.map(item => (
                                                        <ListItem key={item.addressId} button>
                                                            <ListItemIcon>
                                                                <Radio
                                                                    checked={selectedAddress.addressId == item.addressId ? true : false}
                                                                    onChange={handleSelectedAddress(item.addressId)}
                                                                    value={item.addressId}
                                                                    name="addressId"
                                                                    disabled={item.statusZone !== "1"}
                                                                />
                                                            </ListItemIcon>
                                                            <ListItemText
                                                                onClick={item.statusZone === "1" && handleSelectedAddress(item.addressId)}
                                                                primary={(item.address_street_2 != "" ? item.address_street_2 + ", " : "") + item.address_street + (item.statusZone !== "1" ? " (No disponible)" : "")} />
                                                            <ListItemSecondaryAction>
                                                                <IconButton edge="end" onClick={onClickDeleteAddress(item.addressId)}>
                                                                    <Icon>close</Icon>
                                                                </IconButton>
                                                            </ListItemSecondaryAction>

                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Button
                                                    variant="text"
                                                    size="small"
                                                    color="primary"
                                                    onClick={onClickAddNewAddress}
                                                >
                                                    <FormattedMessage
                                                        id="checkout_step1_address_add"
                                                        defaultMessage="Add new address"
                                                    />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <Grid container spacing={1} style={{ marginTop: "16px" }}>
                                        <Grid xs={12} item>
                                            <h4 className="uk-text-left">
                                                <FormattedMessage
                                                    id="checkout_step1_form_title"
                                                    defaultMessage="Delivery Zone"
                                                />
                                            </h4>
                                            <p className="uk-text-left">
                                                <FormattedMessage
                                                    id="checkout_step1_form_desc"
                                                    defaultMessage='We only cover the following delivery zones. If you cannot find you delivery zone you can choose "Store Pickup" instead.'
                                                />
                                            </p>
                                            <p className="uk-margin-remove uk-text-left">
                                                <FormattedMessage
                                                    id="checkout_step1_form_select_zone"
                                                    defaultMessage="Delivery Zones"
                                                />
                                            </p>
                                            <div className="md-select md-input-wrapper md-input-no-label md-input-dense">
                                                <select className="md-input" onChange={handleChangeDeliveryOptions} required name="deliveryZoneId"
                                                    value={selectedDeliveryOption.deliveryZoneId}>
                                                    <option value="" disabled="" selected hidden></option>
                                                    {zones.length > 0 && zones.map((item, index) => (
                                                        item.deliveryZoneCategoryId === "1" &&
                                                        <option key={index} value={item.deliveryZoneId}>{item.description + " (S/. " + item.fee + ")"}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box mt={1} align='right'>
                                                <Button variant='contained' color="primary" onClick={saveAddress} endIcon={<Icon>arrow_forward</Icon>} >
                                                    <FormattedMessage
                                                        id="checkout_continue"
                                                        defaultMessage="Continue"
                                                    />
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </>
                            ) : (
                                deliveryType === "2" &&
                                <>
                                    <div className='uk-width-1-1'>
                                        <p className="uk-margin-remove uk-text-left">
                                            <FormattedMessage
                                                id="checkout_step1_locations"
                                                defaultMessage="Store locations"
                                            />
                                        </p>
                                        <ul className="uk-list uk-list-divider">
                                            {zones.length > 0 && zones.map((item, index) => (
                                                item.deliveryZoneCategoryId === "2" &&
                                                <>
                                                    <li className="ripple-surface" key={index} onClick={handleClickStores(item.deliveryZoneId)}>
                                                        <div className="uk-flex uk-flex-middle">
                                                            <div className="md-radio md-radio-primary">
                                                                <input id={`radio_payment_${item.deliveryZoneId}`} type="radio"
                                                                    checked={selectedDeliveryOption.deliveryZoneId == item.deliveryZoneId ? true : false}
                                                                    value={item.deliveryZoneId} name="radio-event" />
                                                                <label for={`radio_payment_${item.deliveryZoneId}`}></label>
                                                            </div>
                                                            <div style={{ paddingLeft: "8px" }}>
                                                                <h5 className="uk-margin-remove">{item.description + " (S/. " + item.fee + ")"}</h5>
                                                            </div>
                                                        </div>
                                                    </li>
                                                    <Box ml={2} p={2}>
                                                        <div dangerouslySetInnerHTML={{
                                                            __html:
                                                                userReducer.language == "es" ?
                                                                    DOMPurify.sanitize(item.notes) : DOMPurify.sanitize(item.notes_en)
                                                        }}></div>
                                                    </Box>
                                                </>
                                            ))}
                                        </ul>
                                    </div>
                                    <Box mt={1} align='right'>
                                        <Button variant='contained' color="primary" onClick={saveStep1} endIcon={<Icon>arrow_forward</Icon>} >
                                            <FormattedMessage
                                                id="checkout_continue"
                                                defaultMessage="Continue"
                                            />
                                        </Button>
                                    </Box>
                                </>
                            )}

                        </div>
                    ) : (
                        Steps[0].verified == true && (
                            <>
                                <h5>
                                    <FormattedMessage
                                        id="checkout_step1_selectedAddress"
                                        defaultMessage="Selected Address"
                                    />
                                </h5>
                                {deliveryType === "1" ?
                                    <div className="uk-text-left" >
                                        <div>
                                            <span><strong>
                                                <FormattedMessage
                                                    id="checkout_step1_form_title"
                                                    defaultMessage="Delivery Zone"
                                                />:</strong> {/* selectedAddress.delivery_zone_id */selectedDeliveryOption.description + " (S/. " + selectedDeliveryOption.fee + ")"}</span>
                                        </div>
                                        <div>
                                            <span>
                                                <strong>
                                                    <FormattedMessage
                                                        id="checkout_step1_address"
                                                        defaultMessage="Address"
                                                    />:
                                                </strong>
                                                {(selectedAddress.address_street_2 !== undefined ? selectedAddress.address_street_2 + " - " : "") + selectedAddress.address_street}</span>
                                        </div>
                                        <div>
                                            <span><strong>
                                                <FormattedMessage
                                                    id="checkout_step1_address_reference"
                                                    defaultMessage="Reference"
                                                />:</strong> {selectedAddress.reference}</span>
                                        </div>
                                    </div>
                                    :
                                    <Box p={1}>
                                        <Typography variant="body1" color="initial">
                                            <FormattedMessage
                                                id="checkout_step1_store_title"
                                                defaultMessage="Store Pickup"
                                            />
                                        </Typography>
                                        <Typography variant="h6" color="initial">
                                            {selectedDeliveryOption.description}
                                        </Typography>
                                    </Box>
                                }
                                <p className="uk-text-center">
                                    <a onClick={() => setCurrentStep(1)} >
                                        <FormattedMessage
                                            id="checkout_editInfo"
                                            defaultMessage="Edit information"
                                        />
                                    </a>
                                </p>
                            </>
                        )
                    )
                    }
                </div>
            </div>
        </>
    )
}

export default CheckoutStep1
