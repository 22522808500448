import { Avatar, Box, Button, Hidden, Icon, TextField, Typography } from '@material-ui/core'
import { Link } from "react-router-dom";
import React, { useEffect, useState } from 'react'
import PriceInfo from '../components/PriceInfo'
import { FormattedMessage } from 'react-intl';
import UIkit from "uikit";

import CartActions from '../../../providers/cart/cart.actions';
import settings from '../../../settings.js';
import AddToCardButton from '../components/AddToCardButton';
import ProductComment from '../components/ProductComment';
import { useDispatch, useSelector } from "react-redux";

const CheckoutStep2 = ({ Steps, setSteps, currentStep, setCurrentStep, selectedDeliveryOption }) => {

    const cart = CartActions();
    const [canContinue, setCanContinue] = useState(false);
    const userReducer = useSelector(state => state.userReducer);

    useEffect(() => {
        console.log("selectedDeliveryOption:", selectedDeliveryOption);
        let control = true;
        for (let index = 0; index < cart.cartItems.length; index++) {
            const zones = cart.cartItems[index].zones;
            let valid = zones.findIndex(item => item.deliveryZoneId === selectedDeliveryOption.deliveryZoneId);

            console.log(valid);

            if (valid > -1) {
                cart.cartItems[index].validToDelivery = true;
            } else {
                cart.cartItems[index].validToDelivery = false;
                control = false;
            }
        }
        if (control) {
            setCanContinue(true);
        } else {
            setCanContinue(false);
        }

    }, [selectedDeliveryOption, cart.cartItems]);

    const saveStep2 = () => {
        let flag = true;
        if (cart.cartItems.length == 0) {
            UIkit.notification("Your cart is empty. Please select more items to continue");
            flag = false;
        }
        if (flag) {
            const newObject = [...Steps];
            const selectedStep = newObject.find(item => item.step == 2);
            selectedStep.verified = true;
            setCurrentStep(3);
            setSteps(newObject);
        }
        console.log();
    }

    console.log(cart.cartItems);

    return (
        <div className="uk-card md-bg-white">
            <div className="uk-card-header border-bottom">
                <div className="uk-flex uk-flex-middle">
                    <div>
                        {Steps[1].verified == true ?
                            currentStep == 2 ? <i className="fas fa-file-search"></i>
                                :
                                <i className="material-icons md-color-green-500">check_circle</i>
                            :
                            <i className="fas fa-search"></i>
                        }
                    </div>
                    <div style={{ marginLeft: "8px" }}>
                        <h3 className="uk-card-title uk-margin-remove-bottom uk-text-left">
                            <FormattedMessage
                                id="checkout_step2_title"
                                defaultMessage="Review your items"
                            />
                        </h3>
                    </div>
                </div>
            </div>
            <div className="uk-card-body">
                {currentStep == 2 ? (
                    <>
                        {cart.cartItems.length > 0 ?
                            <>
                                <Box>
                                    {cart.cartItems.map(item => (
                                        <>
                                            <Hidden mdUp>
                                                <Box display="flex" alignItems="center">
                                                    <Box alignItems="center" flexGrow={1}>
                                                        <Box mr={1}>
                                                            <Avatar style={{ width: "50px", height: "50px" }} variant="rounded" src={`${settings.connectionString}/files/products/${item.image}`}></Avatar>
                                                        </Box>
                                                        <Box >
                                                            <Typography variant="body1" color="initial">
                                                                <Box component="span" color={item.validToDelivery ? "" : "error.main"} >{item.name}</Box>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box width={140} p={1} align="right">
                                                        <PriceInfo item={item} showUnitPrice={false} smallEdition={true} />
                                                        <AddToCardButton item={item} />
                                                    </Box>
                                                </Box>
                                                {item.commentsFlag &&
                                                    <Box py={1}>
                                                        <ProductComment
                                                            placeholder={
                                                                <FormattedMessage
                                                                    id="product_comment"
                                                                    defaultMessage="If you have any special requirement about {name}, please write it here."
                                                                    values={
                                                                        { name: item.name }
                                                                    }
                                                                />
                                                            }
                                                            item={item} />
                                                    </Box>
                                                }
                                            </Hidden>
                                            <Hidden smDown>
                                                <Box display="flex" alignItems="center">
                                                    <Box display="flex" alignItems="center" flexGrow={1}>
                                                        <Box mr={1}>
                                                            <Avatar style={{ width: "50px", height: "50px" }} variant="rounded" src={`${settings.connectionString}/files/products/${item.image}`}></Avatar>
                                                        </Box>
                                                        <Box >
                                                            <Typography variant="body1" color="initial">
                                                                <Box component="span" color={item.validToDelivery ? "" : "error.main"} >{item.name}</Box>
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box width={140} p={1}>
                                                        <PriceInfo item={item} showUnitPrice={false} smallEdition={true} />
                                                    </Box>
                                                    <Box p={1}>
                                                        <AddToCardButton item={item} />
                                                    </Box>
                                                </Box>
                                                <Box py={1}>
                                                    {item.commentsFlag &&
                                                        <Box py={1}>
                                                            <ProductComment
                                                                placeholder={
                                                                    <FormattedMessage
                                                                        id="product_comment"
                                                                        defaultMessage="If you have any special requirement about {name}, please write it here."
                                                                        values={{ name: item.name }}
                                                                    />
                                                                }
                                                                item={item} />
                                                        </Box>
                                                    }
                                                </Box>
                                            </Hidden>
                                        </>
                                    ))}
                                </Box>
                            </>
                            :
                            <>
                                <div>
                                    <FormattedMessage
                                        id="checkout_empty_cart"
                                        defaultMessage="No items added"
                                    />
                                </div>
                                <div style={{ paddingTop: "8px" }}><Link to="/shop/1">
                                    <FormattedMessage
                                        id="checkout_return_to_store"
                                        defaultMessage="Return to store"
                                    />
                                </Link></div>
                            </>
                        }
                        {!canContinue &&
                            <Box p={2} bgcolor="#fffde7">
                                <Typography variant="h6" color="initial">
                                    <FormattedMessage
                                        id="checkout_step2_important"
                                        defaultMessage="Important:"
                                    />
                                </Typography>
                                {userReducer.language == "es" ?
                                    <Typography component="div" variant="body1" color="initial">
                                        Los productos marcados en <Box component="span" color="error.main">rojo</Box> no estan pemitidos para la Zona Delivery seleccionada.
                                    </Typography>
                                    :
                                    <Typography component="div" variant="body1" color="initial">
                                        The product(s) marked in <Box component="span" color="error.main">red</Box> are not allowed to the selected Delivery Zone.
                                    </Typography>
                                }

                                <Typography component="div" variant="body1" color="initial">
                                    <FormattedMessage
                                        id="checkout_step2_continue_message2"
                                        defaultMessage="Please remove them to continue or change the Delivery Zone."
                                    />

                                </Typography>
                            </Box>
                        }
                        <Box /*display="flex" justifyContent="space-between" */ align='right' >
                            {/* <Button style={{
                            }} onClick={() => setCurrentStep(1)} startIcon={<Icon>arrow_back</Icon>}>
                                <FormattedMessage
                                    id="checkout_back"
                                    defaultMessage="Back"
                                />
                            </Button> */}
                            <Button variant='contained' color="primary" onClick={saveStep2} endIcon={<Icon>arrow_forward</Icon>} disabled={!canContinue}>
                                <FormattedMessage
                                    id="checkout_continue"
                                    defaultMessage="Continue"
                                />
                            </Button>
                        </Box>
                    </>
                ) : (
                    Steps[1].verified == true && (
                        <>
                            {cart.cartItems ?
                                <>
                                    <Hidden mdUp>
                                        {cart.cartItems.map(item => (
                                            <Box display="flex" alignItems="center">
                                                <Box display="flex" alignItems="center" flexGrow={1}>
                                                    <Box mr={1}>
                                                        <Avatar style={{ width: "50px", height: "50px" }} variant="rounded" src={`${settings.connectionString}/files/products/${item.image}`}></Avatar>
                                                    </Box>
                                                    <Box >
                                                        <p className="uk-margin-remove">{item.name}</p>
                                                    </Box>
                                                </Box>
                                                <Box width={140} p={1} align="right">
                                                    <PriceInfo item={item} showUnitPrice={false} smallEdition={true} />
                                                    <span className='uk-text-bold' align="right">{item.quantity}
                                                        <FormattedMessage
                                                            id="checkout_step2_units"
                                                            defaultMessage=" units"
                                                        />
                                                    </span>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Hidden>
                                    <Hidden smDown>
                                        <table width="100%" className="uk-table">
                                            <tbody>
                                                {cart.cartItems.map(item => (
                                                    <tr>
                                                        <td ><img width='100px' src={`${settings.connectionString}/files/products/${item.image}`} alt="" /></td>
                                                        <td><p >{item.name}</p></td>
                                                        <td width='100px'>
                                                            <PriceInfo item={item} showUnitPrice={false} smallEdition={true} />
                                                        </td>
                                                        <td><span className=''>{item.quantity}
                                                            <FormattedMessage
                                                                id="checkout_step2_units"
                                                                defaultMessage=" units"
                                                            />
                                                        </span></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </Hidden>
                                </>
                                :
                                "No hay produtos en el carrito."
                            }
                            <p className="uk-text-center"><a onClick={() => setCurrentStep(2)} >

                                <FormattedMessage
                                    id="checkout_editInfo"
                                    defaultMessage="Edit information"
                                />
                            </a></p>
                        </>

                    )
                )}
            </div>
        </div>

    )
}

export default CheckoutStep2
