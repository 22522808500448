import { useSelector } from 'react-redux';
import settings from '../../settings.js';

import { setAlert } from '../alert/alert.actions';

export const fetchStart = () => ({
    type: "FETCH_START_ORDER"
});
export const fetchSuccess = () => ({
    type: "FETCH_SUCCESS_ORDER"
});
export const fetchFailure = errorMessage => ({
    type: "FETCH_FAILURE_ORDER",
    payload: errorMessage
});

const getOrders = (type, querySearch, page) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    
    const query = `?q=${querySearch}`;
    const pageOut = `&page=${page}`;
    const pageSize = `&pageSize=4`;

    try {
        const url = `${settings.connectionString}/api/orders${query}${pageOut}${pageSize}`;
        console.log(url)
        const response = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'GET'
        });
        const res = await response.json();
        console.log("getOrders")
        console.log(res)
        for (let index = 0; index < res.length; index++) {

            res[index].created_date = new Date(res[index].created_date);
        }
        dispatch({
            type: type,
            payload: res
        });
        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
};

const getOrder = (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    let token = "";
    if (state.userReducer.currentUser) {
        token = state.userReducer.currentUser.token;
    } else {
        token = state.userReducer.currentUserFront.token;
    }
    try {

        const response = await fetch(`${settings.connectionString}/api/orders/byId/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: 'GET',
        });
        const res = await response.json();
        res.created_date = new Date(res.created_date);

        console.log(res);
        dispatch({
            type: "GET_ORDER",
            payload: res
        })
        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}
const getOrderByRef = (id) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    let token = "";
    if (state.userReducer.currentUser) {
        token = state.userReducer.currentUser.token;
    } else {
        token = state.userReducer.currentUserFront.token;
    }
    try {
        console.log("getOrder");


        const response = await fetch(`${settings.connectionString}/api/orders/byRefNumber/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: 'GET'
        });
        const res = await response.json();
        res.created_date = new Date(res.created_date);

       // console.log(res);
        dispatch({
            type: "GET_ORDER",
            payload: res
        })
        return res;
    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const getOrdersByUserId = (id) => async (dispatch, getState) => {
    const state = getState();
    console.log("getOrdersByUserId = " + id)
    dispatch(fetchStart());

    let token = "";
    if (state.userReducer.currentUser) {
        token = state.userReducer.currentUser.token;
    } else {
        token = state.userReducer.currentUserFront.token;
    }

    try {

        const response = await fetch(`${settings.connectionString}/api/orders/byUserId/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            method: 'GET'
        });
        const res = await response.json();
        console.log("getOrdersByUserId")
        console.log(res)
        /* res.created_date = new Date(res.created_date); */

        dispatch({
            type: "GET_ORDERS",
            payload: res
        })

    } catch (error) {
        dispatch(fetchFailure(error.message));
    }
}

const getOrdersByVoucherId = (id) => async (dispatch, getState) => {

    const state = getState();
    dispatch(fetchStart());
    console.log("getOrdersByVoucherId");
    try {
        const response = await fetch(`${settings.connectionString}/api/orders/byVoucherId/${id}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'GET'
        });
        const res = await response.json();
        /* res.created_date = new Date(res.created_date); */

        dispatch({
            type: "GET_ORDERS",
            payload: res
        })

    } catch (error) {
        dispatch({
            type: "GET_ORDERS",
            payload: []
        })
        dispatch(fetchFailure(error.message));
    }
}

const updateOrderStatus = (body) => async (dispatch, getState) => {
    const state = getState();
    dispatch(fetchStart());
    console.log("updateOrderStatus");
    console.log(body);
    try {
        const response = await fetch(`${settings.connectionString}/api/orders/updateState`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + state.userReducer.currentUser.token
            },
            method: 'PUT',
            body: JSON.stringify(body)
        });
        console.log(response);
        /* const text = await response.text();
        console.log(text); */
        const res = await response.json();
        dispatch(fetchSuccess());
        if (res) {
            dispatch(setAlert({ msg: "Estado actualizado" }));
            dispatch(getOrder(body.orderId));
        }
        return true;
    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "No se pudo actualizar la Order" }));
    }
    return false;
}
/*
const deleteOrder = (id) => async dispatch => {
    dispatch(fetchStart());
    console.log("deleteOrder");
    try {
        const body = {
            OrderId: id,
            jwt: currentUser.token
        }
        const response = await fetch(`${settings.connectionString}/api/Order_delete.php`, {
            method: 'POST',
            body: JSON.stringify(body)
        });
        if (response.status >= 400 && response.status < 500) {
            throw "Error" + response.status;
        }
        if (response.status >= 500 && response.status < 600) {
        }
        console.log(response);
        const res = await response.json();
        if (res) {
            dispatch(setAlert({ msg: "Order eliminado" }));
        }

        return true;

    } catch (error) {
        dispatch(fetchFailure(error.message));
        dispatch(setAlert({ msg: "Error al eliminar" }));
    }
    return false;
} */

export default {
    getOrders,
    getOrder,
    updateOrderStatus,
    getOrdersByUserId,
    getOrdersByVoucherId,
    getOrderByRef,
}