import { combineReducers } from 'redux';

import bannersReducer from './banners/banners.reducer';
import alertReducer from './alert/alert.reducer';
import userReducer from './user/user.reducer';
import ordersReducer from './orders/orders.reducer';
import productsReducer from './products/products.reducer';
import paymentsReducer from './payments/payments.reducer';
import producersReducer from './producers/producers.reducer';
import recipesReducer from './recipes/recipes.reducer';
import vouchersReducer from './vouchers/vouchers.reducer';
import productsCategoriesReducer from './product_categories/product_categories.reducer';
import zonesReducer from './delivery_zone/delivery_zone.reducer';
import automaticDiscountsReducer from './automatic_discounts/automatic_discounts.reducer';
import webSettingsReducer from './websettings/websettings.reducer';
import reportsReducer from './reports/reports.reducer';

const rootReducer = combineReducers({
  bannersReducer,
  alertReducer,
  userReducer,
  ordersReducer,
  productsReducer,
  paymentsReducer,
  vouchersReducer,
  producersReducer,
  recipesReducer,
  zonesReducer,
  automaticDiscountsReducer,
  productsCategoriesReducer,
  webSettingsReducer,
  reportsReducer
});
export default rootReducer;