import React, { useState } from 'react'
import MDUIkit from "md-uikit";
import UIkit from "uikit";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider, Drawer, Icon, IconButton, InputBase, List, ListItem, ListItemText, TextField, Typography } from '@material-ui/core';

import logo from '../../../assets/img/main_logo.png';
import { FormattedMessage } from 'react-intl';
import userActions from '../../../redux/user/user.actions';

const drawerWidth = 256;
const useStyles = makeStyles((theme) => ({
    drawer: {
        width: "100%",
        flexShrink: 0,
    },
    drawerPaper: {
        width: "100%",
        boxSizing: 'border-box',
        backgroundColor: "rgba(33, 81, 103, 0.85)"
    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(3),
        padding: theme.spacing(1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'center',
    },
    listItemContainer: {
        margin: theme.spacing(1),
        width: "initial",
        borderRadius: theme.spacing(0.5)
    },
    nested: {
        paddingLeft: theme.spacing(9),
    },
    menuColor: {
        color: "#76596B",
    },
    menuTitle: {
        color: "white",
        transition: "all 0.3s ease-in-out 0s",
        padding: theme.spacing(1),
        marginLeft: theme.spacing(2),
        marginBottom: theme.spacing(5),
        fontWeight: "700",
        borderRadius: "25px",
        "&:hover": {
            color: "white",
            backgroundColor: theme.palette.primary.main
        }
    },
    menuTitleBorderPrimary: {
        borderStyle: "solid",
        borderWidth: "2px",
        borderColor: theme.palette.primary.main
    },
    menuTitleActive: {
        color: "white",
        backgroundColor: theme.palette.primary.main
    },
    searchInput: {
        backgroundColor: "rgba(255, 255, 255, 0.6)",
        padding: theme.spacing(1)
    }
}));

const MenuSidebar = ({ setOpenDrawer, openDrawer }) => {

    const [searchValue, setSearchValue] = useState("");
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    let location = useLocation();
    const userReducer = useSelector(state => state.userReducer);

    const onClickCloseMenu = route => e => {
        e.preventDefault();
        setOpenDrawer(false);
        history.push(route)
    }

    const handleChange = event => {
        const { value, name } = event.target;
        setSearchValue(value);
    }
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            setSearchValue("");
            history.push("/search/" + searchValue);
            setOpenDrawer(false);
        }
    }

    const logout = () => {
        setOpenDrawer(false);
        dispatch(userActions.logoutFront());
    }

    return (
        <Drawer
            className={classes.drawer}
            anchor="top"
            variant="temporary"
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            classes={{
                paper: classes.drawerPaper,
            }}
            BackdropProps={{ style: { backgroundColor: 'transparent' } }}
        /* ModalProps={{
            keepMounted: true, // Better open performance on mobile.
        }} */
        >
            <Box display="flex" p={2} justifyContent="flex-end">
                <IconButton onClick={() => setOpenDrawer(false)}><Icon style={{ color: "white" }}>close</Icon></IconButton>
            </Box>
            <Box pb={3}>
                <Typography
                    className={`${location.pathname === "/" && classes.menuTitleActive} ${classes.menuTitle}`}
                    component="a"
                    variant="body2"
                    onClick={onClickCloseMenu("/")}
                >
                    <FormattedMessage
                        id="header_home"
                        defaultMessage="HOME"
                    />
                </Typography>
            </Box>
            <Box pb={3}>
                <Typography
                    className={`${location.pathname.startsWith("/shop", 0) && classes.menuTitleActive} ${classes.menuTitle}`}
                    component="a"
                    variant="body2"
                    onClick={onClickCloseMenu("/shop/1")}
                >
                    <FormattedMessage
                        id="header_shop"
                        defaultMessage="SHOP"
                    />
                </Typography>
            </Box>
            <Box pb={3}>
                <Typography
                    className={`${location.pathname === "/about" && classes.menuTitleActive} ${classes.menuTitle}`}
                    component="a"
                    onClick={onClickCloseMenu("/about")}
                    variant="body2"
                >
                    <FormattedMessage
                        id="header_about"
                        defaultMessage="ABOUT US"
                    />
                </Typography>
            </Box>

            <Box pb={3}>
                <Typography
                    className={`${location.pathname === "/producers" && classes.menuTitleActive} ${classes.menuTitle}`}
                    component="a"
                    onClick={onClickCloseMenu("/producers")}
                    variant="body2"
                >
                    <FormattedMessage
                        id="header_producers"
                        defaultMessage="PRODUCERS"
                    />
                </Typography>
            </Box>

            <Box pb={3}>
                <Typography
                    className={`${location.pathname === "/steps" && classes.menuTitleActive} ${classes.menuTitle}`}
                    component="a"
                    onClick={onClickCloseMenu("/steps")}
                    variant="body2"
                >
                    <FormattedMessage
                        id="header_steps"
                        defaultMessage="HOW IT WORKS"
                    />
                </Typography>
            </Box>
            <Box pb={3}>
                <Typography
                    className={`${location.pathname === "/recipes" && classes.menuTitleActive} ${classes.menuTitle}`}
                    component="a"
                    onClick={onClickCloseMenu("/recipes")}
                    variant="body2"
                >
                    <FormattedMessage
                        id="header_recipes"
                        defaultMessage="RECIPES"
                    />
                </Typography>
            </Box>
            <Box pb={3}>
                <Divider variant="middle" style={{ backgroundColor: "white", width: "50%" }} />
            </Box>
            <Box pb={3}>
                {!userReducer.currentUserFront ?
                    <Typography
                        className={`${classes.menuTitle} ${classes.menuTitleBorderPrimary}`}
                        component="a"
                        variant="body2"
                        onClick={onClickCloseMenu("/login")}
                    >
                        <FormattedMessage
                            id="header_login"
                            defaultMessage="LOGIN / REGISTER"
                        />
                    </Typography>
                    :
                    <>
                        <Box pb={2}>
                            <Typography
                                className={`${location.pathname.startsWith("/account", 0) && classes.menuTitleActive} ${classes.menuTitle}`}
                                component="a"
                                onClick={onClickCloseMenu("/account/orders")}
                                variant="body2"
                            >
                                <FormattedMessage
                                    id="header_account"
                                    defaultMessage="MY ACCOUNT"
                                />
                            </Typography>
                        </Box>
                        <Box>
                            <Typography
                                className={`${classes.menuTitle}`}
                                onClick={logout}
                                component="a"
                                variant="body2"
                            >
                                <FormattedMessage
                                    id="account_logout"
                                    defaultMessage="LOGOUT"
                                />
                            </Typography>
                        </Box>
                    </>
                }

            </Box>
            {/* <Box pb={7} px={2}>
                <InputBase
                    fullWidth
                    value={searchValue}
                    onChange={handleChange}
                    onKeyPress={handleKeyPress}
                    autoFocus
                    variant="outlined"
                    className={classes.searchInput}
                    endAdornment={
                        <IconButton size="small">
                            <Icon fontSize="small">search</Icon>
                        </IconButton>
                    }
                />
            </Box> */}
        </Drawer >
    )
}

export default MenuSidebar
