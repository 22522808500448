import {
  AppBar, Box, Icon, IconButton, makeStyles, Menu, MenuItem,
  Toolbar, Typography, useScrollTrigger
} from '@material-ui/core';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

/* import 'uikit/dist/css/uikit.min.css';
import 'md-uikit/dist/css/md-uikit.css';
import UIkit from 'uikit/dist/js/uikit.js';
 */

import { useDispatch, useSelector } from "react-redux";
import userActions from '../../redux/user/user.actions';

const useStyles = makeStyles((theme) => ({

  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  iconMenu: {
    marginRight: theme.spacing(2),
  },
}));

const Header = ({ openDrawer, classAppBarShift, setOpenDrawer }) => {

  const userReducer = useSelector(state => state.userReducer);
  const dispatch = useDispatch();

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onClickLogout = async event => {
    handleMenuClose();
    dispatch(userActions.logout());
  }
  return (
    <>
      < >
        <AppBar
          elevation={trigger ? 4 : 0}
          position="sticky"
          color="secondary"
          className={`${classes.appBar} ${openDrawer ? classAppBarShift : ""}`}
        >
          <Toolbar>
            <Box flexGrow={1}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={() => setOpenDrawer(!openDrawer)}
                edge="start"
                className={classes.menuButton}
              /* className={clsx(classes.menuButton, open && classes.hide)} */
              >
                <Icon>menu</Icon>
              </IconButton>
            </Box>
            <Box>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleMenuOpen}
                edge="end"
              >
                <Icon>person</Icon>
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        <Menu
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          keepMounted
          open={isMenuOpen}
          onClose={handleMenuClose}
        >
          <MenuItem onClick={onClickLogout}>
            <Icon className={classes.iconMenu} fontSize="small">logout</Icon>
            <Typography variant="inherit">Salir</Typography>
          </MenuItem>

        </Menu>
      </>
      {/* <div uk-sticky="show-on-up: true; animation: uk-animation-slide-top;" >
      <header id='admin-header'>
        <nav className="uk-navbar-container md-top-app-bar-dense md-bg-primary" uk-navbar="mode: click">
          <div className="uk-navbar-left">
            <a href="#" id="md-drawer-toggle" className="uk-navbar-toggle" ><i className="material-icons md-icon md-color-white">menu</i></a>
            <span className="uk-navbar-item md-top-app-bar__title md-color-white"></span>
          </div>
          <div className="uk-navbar-right ">
            <ul className="uk-navbar-nav nav-overlay">
              {user.currentUser &&
                <li>
                  <a className="uk-navbar-item" href="#/"><i className="material-icons md-icon md-color-white">person</i></a>
                  <div uk-dropdown="mode: click; pos: bottom-right">
                    <ul className="uk-nav uk-navbar-dropdown-nav" >
                      <li><a href="#" onClick={onClickLogout}><i className="material-icons">power_settings_new</i>Salir</a></li>
                    </ul>
                  </div>
                </li>
              }
            </ul>
          </div>
        </nav>
      </header>
    </div > */}
    </>
  )
}

/* const mapStateToProps = state => ({
  currentUser: state.user.currentUser
})

const mapDispatchToProps = dispatch => ({
  resetUser: item => dispatch(resetUser(item)),
  resetApplicants: item => dispatch(resetApplicants(item)),
  resetCart: item => dispatch(resetCart(item))
}); */
export default Header;