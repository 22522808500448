export const addItem = (cartItems, cartItemToAdd) => {
    const existingCartItem = cartItems.find(
        cartItem => cartItem.productId === cartItemToAdd.productId
    );

    if (existingCartItem) {
        return cartItems.map(cartItem =>
            cartItem.productId === cartItemToAdd.productId
                ? { ...cartItem, quantity: cartItem.quantity + 1 }
                : { ...cartItem }
        );
    }

    return [...cartItems, { ...cartItemToAdd, quantity: 1 }];
};

export const updateItemComment = (cartItems, cartItemToAdd) => {
    console.log("updateItemComment");
    console.log(cartItemToAdd);
    const existingCartItem = cartItems.find(
        cartItem => cartItem.productId === cartItemToAdd.productId
    );
     if (existingCartItem) {
        return cartItems.map(cartItem =>
            cartItem.productId === cartItemToAdd.productId
                ? { ...cartItem, comments: cartItemToAdd.comments }
                : { ...cartItem }
        );
    }
/*
    return [...cartItems, { ...cartItemToAdd, comment: "" }]; */
};

export const removeItem = (cartItems, cartItemToRemoveId) => {
    const existingCartItem = cartItems.find(
        cartItem => cartItem.productId === cartItemToRemoveId
    );

    if (existingCartItem.quantity === 1) {
        return cartItems.filter(cartItem => cartItem.productId !== cartItemToRemoveId);
    }

    return cartItems.map(cartItem =>
        cartItem.productId === cartItemToRemoveId
            ? { ...cartItem, quantity: cartItem.quantity - 1 }
            : { ...cartItem }
    );
};
export const getCartTotal = (cartItems) => {
    let subTotal = 0;
    console.log("getCartTotal");
    for (let index = 0; index < cartItems.length; index++) {
        const element = cartItems[index];
        let selectedPrice = element.price;
        if (element.alt_price_active !== false) {
            selectedPrice = element.alt_price;
        }
        if (element.auto_discounts) {
            let maxIfAmount = 0;
            let selectedNewPrice = 0;
            for (let index = 0; index < element.auto_discounts.length; index++) {
                const elementInner = element.auto_discounts[index];
                if (parseInt(elementInner.if_amount) > maxIfAmount && parseInt(elementInner.if_amount) <= element.quantity) {
                    maxIfAmount = elementInner.if_amount;
                    selectedNewPrice = elementInner.new_price
                }
            }
            if (selectedNewPrice) {
                selectedPrice = selectedNewPrice;
            }
        }
        var suma = (parseFloat(selectedPrice) * element.quantity);
        subTotal += suma;
    }
    console.log(subTotal);
    return subTotal;
};