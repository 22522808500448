import {
    Fab, Icon, Paper, Grid, Box, Divider,
    Typography, CircularProgress, Avatar
} from '@material-ui/core';

import { Link, useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react'
import ContentBefore from './components/ContentBefore';

import { useDispatch, useSelector } from "react-redux";
import recipesActions from '../../redux/recipes/recipes.actions';

import settings from '../../settings.js';
import GeneralContainer from './components/GeneralContainer';
import FAB from './components/utils/FAB';
import GeneralRow from './components/utils/GeneralRow';
import ResponsiveMenuButton from './components/utils/ResponsiveMenuButton';
import DeleteModal from './components/modals/DeleteModal';

const Recipes = () => {

    const recipesReducer = useSelector(state => state.recipesReducer);
    const dispatch = useDispatch();

    const history = useHistory();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedItem, setSelectedItem] = useState({
        orderId: 0
    });

    /* const classes = useStyles(); */

    useEffect(() => {
        dispatch(recipesActions.getRecipes());
    }, []);

    const menu = [
        {
            title: "Ver/Editar",
            icon: "visibility",
            action: e => onClickView(e)
        },
        {
            title: "Eliminar",
            icon: "delete",
            action: e => onClickDelete(e)
        },
    ]

    const onClickView = item => {
        console.log("onClickView");
        history.push(`/admin/recipe_detail/${item.recipeId}`)
    }

    const onClickDelete = item => {
        setSelectedItem(item);
        setShowDeleteModal(true);
    }
    const deleteProgram = item => {
        const result = dispatch(recipesActions.deleteRecipe(selectedItem.recipeId));
        if (result) {
            setShowDeleteModal(false);
        }
    }

    return (
        <>
            <ContentBefore title="Recetas" />
            <GeneralContainer>
                <Paper >
                    {recipesReducer.loading ?
                        <CircularProgress />
                        :
                        <>
                            {recipesReducer.recipes.length > 0 && recipesReducer.recipes.map((item, index) => (
                                <>
                                    <GeneralRow>
                                        <Box display="flex" alignItems="center">
                                            <Box mr={1}>
                                                {item.image !== null && item.image !== "" ?
                                                    <Avatar style={{ width: "80px", height: "80px" }} variant="rounded"
                                                        src={`${settings.connectionString}/files/recipes/${item.image}`} />
                                                    :
                                                    <Avatar style={{ width: "80px", height: "80px" }} variant="rounded"
                                                        src={`${settings.connectionString}/files/recipes/general_image.jpg`} />
                                                }
                                            </Box>
                                            <Box flexGrow={1}>
                                                <Typography component={Link} to={`/admin/recipe_detail/${item.recipeId}`} variant="h6" color="initial">
                                                    {item.name}
                                                </Typography>
                                                <Typography component="span" variant="body1" color="initial">
                                                    {item.status == "1" ?
                                                        <Box fontWeight="fontWeightBold" color="success.main">Activo</Box>
                                                        :
                                                        <Box fontWeight="fontWeightBold" color="text.secondary">Inactivo</Box>
                                                    }
                                                </Typography>

                                            </Box>
                                            <Box >
                                                <ResponsiveMenuButton menu={menu} item={item} title={item.name} />
                                            </Box>
                                        </Box>
                                    </GeneralRow>
                                </>
                            ))}
                        </>
                    }
                </Paper>
            </GeneralContainer>
            <DeleteModal showModal={showDeleteModal}
                setShowModal={setShowDeleteModal}
                title={selectedItem.name}
                action={deleteProgram}
            />
            <FAB component={Link} to="/admin/recipe_detail/0">
                <Icon>add</Icon>
            </FAB>
        </>
    )
}

export default Recipes
