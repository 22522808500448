import { Fab, Icon, Paper, Grid, Box, Divider, Typography, CircularProgress, Avatar } from '@material-ui/core';
import { Link } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react'
import ContentBefore from './components/ContentBefore';

import { useDispatch, useSelector } from "react-redux";
import productsActions from '../../redux/products/products.actions';

import settings from '../../settings.js';
import GeneralContainer from './components/GeneralContainer';
import FAB from './components/utils/FAB';
import GeneralRow from './components/utils/GeneralRow';
import HasMore from './components/utils/HasMore';
import SearchInput from './components/utils/SearchInput';
import OrderByButton from './components/utils/OrderByButton';

const useStyles = makeStyles((theme) => ({
    media: {
        height: 140,
    },
    fab: {
        position: 'fixed',
        bottom: theme.spacing(3),
        right: theme.spacing(3),
        zIndex: 2,
    },
    hoverColor: {
        '&:hover': {
            backgroundColor: theme.palette.action.hover
        },
        borderBottom: "1px solid #B5B5B5",

    }
}));
const Products = () => {

    const productsReducer = useSelector(state => state.productsReducer);

    const loading = useSelector(state => state.productsReducer.loading);
    const hasMore = useSelector(state => state.productsReducer.hasMore);

    const [page, setPage] = useState(1)
    const [querySearch, setQuerySearch] = useState("")
    const [sortBy, setSortBy] = useState("name:asc");

    const dispatch = useDispatch();

    const classes = useStyles();

    const getActionName = "GET_PRODUCTS";
    const getActionPreviousName = "GET_PRODUCTS_PREVIOUS";

    useEffect(() => {
        if (page !== 1) {
            console.log("changing page...")
            dispatch(productsActions.getProducts(getActionPreviousName, querySearch, page, sortBy));
        }
    }, [page])

    /* useEffect(() => {
        dispatch(productsActions.getProducts());
    }, []); */

    const dispatchFromSort = async (sort) => {
        console.log("dispatchFromSort")
        setSortBy(sort);
        setPage(1);
        await dispatch(productsActions.getProducts(getActionName, querySearch, 1, sort));
    }

    const dispatchFromSearch = async () => {
        console.log("dispatchFromSearch")
        setPage(1);
        await dispatch(productsActions.getProducts(getActionName, querySearch, 1, sortBy));
    }

    const menuSort = [
        {
            title: "A - Z (Nombre)",
            value: "name:asc"
        },
        {
            title: "Z - A (Nombre)",
            value: "name:desc"
        },
        {
            title: "$ - $$$$",
            value: "price:asc"
        },
        {
            title: "$$$$ - $",
            value: "price:desc"
        },
    ]

    return (
        <>
            <ContentBefore title="Productos" />
            <GeneralContainer>
                <Paper style={{ marginBottom: "16px" }}>
                    <Box p={1} display="flex" alignItems="center">
                        <Box flexGrow={1} >
                            <SearchInput
                                placeholder="Buscar producto"
                                querySearch={querySearch}
                                setQuerySearch={setQuerySearch}
                                setPage={setPage}
                                dispatchFromSearch={dispatchFromSearch}
                            />
                        </Box>
                        <Box ml={1} >
                            <OrderByButton
                                menu={menuSort}
                                sortBy={sortBy}
                                dispatchFromSort={dispatchFromSort}
                                title={"Ordenar por"}
                            />
                        </Box>
                    </Box>
                </Paper>
                <Paper>
                    {productsReducer.products.length > 0 ?
                        productsReducer.products.map((item, index) => (
                            <GeneralRow key={index}>
                                <Box display="flex" alignItems="center">
                                    <Box mr={1}>
                                        {item.image !== null && item.image !== "" ?
                                            <Avatar style={{ width: "80px", height: "80px" }} variant="rounded" src={`${settings.connectionString}/files/products/${item.image}`} />
                                            :
                                            <Avatar style={{ width: "80px", height: "80px" }} variant="rounded" src={`${settings.connectionString}/files/products/general_image.jpg`} />
                                        }
                                    </Box>
                                    <Box flexGrow={1}>
                                        <Typography component={Link} to={`/admin/product_detail/${item.productId}`} variant="h6" color="initial">
                                            {item.name}
                                        </Typography>
                                        <Typography component="span" variant="body1" color="initial">
                                            {item.product_status == "1" ?
                                                <Box fontWeight="fontWeightBold" color="success.main">Producto Activo</Box>
                                                :
                                                <Box fontWeight="fontWeightBold" color="text.secondary">Producto Inactivo</Box>
                                            }
                                        </Typography>

                                    </Box>
                                    <Box >
                                        {item.alt_price_active == "1" ?
                                            <>
                                                <Typography variant="h5" color="initial">
                                                    S/. {item.alt_price}
                                                </Typography>
                                                <Typography variant="body2" color="initial">
                                                    antes S/. {item.price}
                                                </Typography>
                                            </>
                                            :
                                            <Typography variant="h5" color="initial">
                                                S/. {item.price}
                                            </Typography>
                                        }
                                    </Box>
                                    {/* <Box >
                                                    <Typography variant="h5" color="initial">
                                                        S/. {item.price}
                                                    </Typography>
                                                </Box> */}
                                </Box>
                            </GeneralRow>
                        ))
                        :
                        !loading &&
                        <Box display="flex" justifyContent="center" p={5}>
                            <Typography variant="subtitle1" color="initial">No se encontraron resultados</Typography>
                        </Box>
                    }
                    <HasMore
                        loading={loading}
                        setPage={setPage}
                        hasMore={hasMore}
                    />
                </Paper>
                {loading &&
                    <Box display="flex" justifyContent="center" p={5}>
                        <CircularProgress />
                    </Box>
                }
            </GeneralContainer>
            <FAB component={Link} to="/admin/product_detail/0">
                <Icon>add</Icon>
            </FAB>
        </>
    )
}

export default Products
