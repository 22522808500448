const INITIAL_STATE = {
  banners: [],
  banner: null,
  loading: false,
  errorMessage: undefined
};

const bannersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_START":
      return {
        ...state,
        loading: true
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        loading: false
      };
    case "GET_BANNER":
      return {
        ...state,
        banner: action.payload,
        loading: false
      };
    case "GET_BANNERS":
      return {
        ...state,
        banners: action.payload,
        banner: null,
        loading: false
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        errorMessage: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default bannersReducer;