import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import React from 'react'

import settings from '../../../../settings.js';

const ClientsAddressesReport = ({ dataResult, dateFrom, dateTo }) => {
    console.log(dataResult)
    return (
        <>
            <Box pb={1} pt={2} display="flex" justifyContent="flex-end">
                <Button size="small" variant="outlined" color="primary" href={`${settings.connectionString}/reports/addressesReport.php?dateFrom=${dateFrom.toLocaleString('en-US')}&dateTo=${dateTo.toLocaleString('en-US')}`}>
                    Exportar a excel</Button>
                {/*  <Box ml={1}>
                    <Button size="small" variant="outlined" color="primary" onClick={onSavePDF}>
                        Exportar a pdf</Button>
                </Box> */}
            </Box>
            <Table /* className={classes.table} */ aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Fecha</TableCell>
                        <TableCell>Zona</TableCell>
                        <TableCell>#Pedido</TableCell>
                        <TableCell>Nombres y apellidos</TableCell>
                        <TableCell>Dirección</TableCell>
                        <TableCell align="right">Total</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataResult.map((row) => (
                        <TableRow key={row.orderId}>
                            <TableCell component="th" scope="row">
                                {row.created_date}
                            </TableCell>
                            <TableCell>{row.Zone}</TableCell>
                            <TableCell>{row.refNumber}</TableCell>
                            <TableCell>{row.firstName + " " + row.lastName}</TableCell>
                            <TableCell>
                                {row.address_street != null ? ((row.address_street_2 !== "" ? row.address_street_2 + ", " : "")) + row.address_street + ((row.reference !== "" ?  " Ref: " + row.reference : "")): "-"}
                            </TableCell>
                            <TableCell align="right">S/.{row.totalPrice}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    )
}

export default ClientsAddressesReport
