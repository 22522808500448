import React, { useRef, useCallback, useEffect } from 'react'

const HasMore = ({ hasMore, loading, setPage, }) => {

    const observer = useRef();

    const lastElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                console.log("intersecting")
                setPage(prevPage => prevPage + 1);
            }
        })
        if (node) observer.current.observe(node);
    }, [loading, hasMore]);

    return (
        <div ref={lastElementRef} style={{ /*height: "1px" , backgroundColor: "red" */ }} />

    )
}

export default HasMore
