const INITIAL_STATE = {
  payments: [],
  payment: null,
  loading: false,
  errorMessage: undefined
};

const paymentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_START":
      return {
        ...state,
        loading: true
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        loading: false
      };
    case "GET_PAYMENTS":
      return {
        ...state,
        payments: action.payload,
        loading: false
      };
    case "GET_PAYMENTS_FRONT":
      return {
        ...state,
        payments: action.payload,
        loading: false
      };
    case "GET_PAYMENT":
      return {
        ...state,
        payment: action.payload,
        loading: false
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        errorMessage: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default paymentsReducer;