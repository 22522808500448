import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl';

const PriceInfo = ({ item, showUnitPrice = true, smallEdition = false }) => {

    const [values, setValues] = useState({
        units: "",
        cents: ""
    })
    useEffect(() => {
        let oldCents, oldUnits;
        let quantity = 1;
        if (item.quantity) {
            quantity = item.quantity;

        }
        var total = (parseFloat(item.price) * quantity).toFixed(2).toString();
        var cents = total.substring(total.length - 2);
        //console.log(total);
        var units = total.substring(0, total.length - 3);

        if (item.alt_price_active !== false) {
            oldUnits = units;
            oldCents = cents;
            let totalAlt = (parseFloat(item.alt_price) * quantity).toFixed(2).toString();
            units = totalAlt.substring(0, totalAlt.length - 3);
            cents = totalAlt.substring(totalAlt.length - 2);
            //console.log(total);
        }

        if (item.quantity) {
            if (item.auto_discounts) {
                let maxIfAmount = 0;
                let selectedNewPrice = 0;
                for (let index = 0; index < item.auto_discounts.length; index++) {
                    const element = item.auto_discounts[index];
                    if (parseInt(element.if_amount) > maxIfAmount && parseInt(element.if_amount) <= quantity) {
                        console.log("entro")
                        maxIfAmount = element.if_amount;
                        selectedNewPrice = element.new_price
                    }
                    console.log(element.if_amount, maxIfAmount, selectedNewPrice, quantity)
                }
                if (selectedNewPrice) {
                    oldUnits = units;
                    oldCents = cents;
                    let totalAlt = (parseFloat(selectedNewPrice) * quantity).toFixed(2).toString();
                    units = totalAlt.substring(0, totalAlt.length - 3);
                    cents = totalAlt.substring(totalAlt.length - 2);
                }
            }
        }
        setValues({ cents, units, oldCents, oldUnits });

    }, [item])
    return (
        <>
            {showUnitPrice && item.unit_price !== "" ?
                <>
                    <div>
                        <div className={`price ${item.alt_price_active !== false ? "md-color-green-500" : "md-color-blue-grey-900"}`}>
                            <span className="currency">S/</span>
                            <span className='unit'>{values.units}</span>
                            <span className='cents'>.{values.cents}</span>
                        </div>
                        {item.alt_price_active !== false &&
                            <div className="md-color-blue-grey-900" style={{
                                position: "relative",
                                top: "-16px"
                            }}>
                                <span className='cents'>
                                    <FormattedMessage
                                        id="component_old_price"
                                        defaultMessage="was "
                                    />
                                     S/ {values.oldUnits}.{values.oldCents}</span>
                            </div>
                        }
                    </div>
                    {/* <div><h5>S/{parseFloat(item.unit_price).toFixed(2)} / 1{item.unit}</h5></div> */}
                </>
                :
                <>
                    {smallEdition ?
                        <>
                            <div className={`uk-text-bold uk-text-right ${item.alt_price_active !== false ? "md-color-green-500" : "md-color-blue-grey-900"}`}>
                                <span >S/ {values.units}.{values.cents}</span>
                            </div>
                            {item.alt_price_active !== false &&
                                <div className="md-color-blue-grey-900 uk-text-right ">
                                    <span >
                                        <FormattedMessage
                                            id="component_old_price"
                                            defaultMessage="was "
                                        /> S/ {values.oldUnits}.{values.oldCents}</span>
                                </div>
                            }
                        </>
                        :
                        <>
                            <div className={`price ${item.alt_price_active !== false ? "md-color-green-500" : "md-color-blue-grey-900"}`}>
                                <span className="currency">S/</span>
                                <span className='unit'>{values.units}</span>
                                <span className='cents'>.{values.cents}</span>
                            </div>
                            {item.alt_price_active !== false &&
                                <div className="md-color-blue-grey-900" style={{
                                    position: "relative",
                                    top: "-16px"
                                }}>
                                    <span className='cents'> <FormattedMessage
                                        id="component_old_price"
                                        defaultMessage="was "
                                    /> S/ {values.oldUnits}.{values.oldCents}</span>
                                </div>
                            }
                        </>
                    }
                </>
            }


        </>
    )
}

export default PriceInfo
