import {
    Box, Button, CircularProgress, Grid, Icon, Input,
    InputBase, Paper, TextField, Typography
} from '@material-ui/core';
import NumberFormat from 'react-number-format';
import React, { useState, useEffect, createRef } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage } from 'react-intl';
import { useHistory } from "react-router-dom";
import MDUIkit from "md-uikit";
import Cleave from 'cleave.js/react';
import UIkit from "uikit";

import CartActions from '../../../providers/cart/cart.actions';
import settings from '../../../settings.js';
import paymentActions from '../../../redux/payments/payments.actions'
import cards from '../../../assets/img/cards.png';


const CheckoutStep3 = ({ Steps, currentStep, selectedPayment, setSelectedPayment, cardInfo, setCardInfo, setCurrentStep }) => {

    const loading = useSelector(state => state.paymentsReducer.loading);
    const payments = useSelector(state => state.paymentsReducer.payments);
    const dispatch = useDispatch();
    const cart = CartActions();
    const history = useHistory();
    const userReducer = useSelector(state => state.userReducer);
    const [paymentName, setPaymentName] = useState("")

    useEffect(() => {
        MDUIkit.components();
        console.log("getting payments");
        dispatch(paymentActions.getPaymentsFront());
    }, []);

    const handleClick = (paymentId) => e => {
        e.preventDefault();
        console.log(paymentId);
        setSelectedPayment(paymentId);
    }
    const handleChangeCard = e => {
        e.preventDefault();
        const { name, value } = e.target;
        console.log(value);
        setCardInfo({ ...cardInfo, [name]: value });
    }
    const getPaymentName = () => {
        const selectedPaymentName = payments.find(item => item.paymentId === selectedPayment);
        if (selectedPaymentName) {
            let name = selectedPaymentName.name_en;
            if (userReducer.language == "es") {
                name = selectedPaymentName.name;
            }
            setPaymentName(name);
            return name;
        }
        return "";
    }
    return (
        <div className="uk-card md-bg-white">
            <div className="uk-card-header border-bottom">
                <div className="uk-flex uk-flex-middle">
                    <div>
                        {Steps[2].verified == true ?
                            currentStep == 3 ? <i className="fas fa-credit-card"></i>
                                :
                                <i className="material-icons md-color-green-500">check_circle</i>
                            :
                            <i className="fas fa-credit-card"></i>
                        }
                    </div>
                    <div style={{ marginLeft: "8px" }}>
                        <h3 className="uk-card-title uk-margin-remove-bottom uk-text-left">
                            <FormattedMessage
                                id="checkout_step3_payment_title"
                                defaultMessage="Payment options"
                            />
                        </h3>
                    </div>
                </div>
            </div>
            <div className="uk-card-body">
                {currentStep == 3 ? (
                    <>
                        <div>
                            <>
                                {payments.length > 0 && payments.map((item, key) => (
                                    <>
                                        <Box p={1} key={key} className={`pointer ${selectedPayment == item.paymentId ? "md-border-primary" : ""}`} onClick={handleClick(item.paymentId)}>
                                            <div className="uk-flex uk-flex-middle uk-flex-between">
                                                <div className="uk-flex uk-flex-middle">
                                                    <div className="md-radio md-radio-primary md-radio-inline">
                                                        <input id={`radio_payment_${item.paymentId}`} type="radio"
                                                            checked={selectedPayment == item.paymentId ? true : false}
                                                            value={item.paymentId}
                                                        />
                                                        <label htmlFor={`radio_payment_${item.paymentId}`}></label>
                                                    </div>
                                                    <div className="uk-margin-left">
                                                        <h5 className="uk-margin-remove-bottom">
                                                            {userReducer.language == "es" ?
                                                                item.name : item.name_en
                                                            }
                                                        </h5>
                                                    </div>
                                                </div>
                                            </div>
                                            {selectedPayment == item.paymentId && (
                                                selectedPayment == 2 ?
                                                    <Box p={2}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Typography variant="body1" color="initial">
                                                                    <FormattedMessage
                                                                        id="checkout_step3_payment_card_description"
                                                                        defaultMessage="Press 'Place Order' to enter to bank details"
                                                                    />
                                                                </Typography>
                                                            </Grid>
                                                            {/*  <Grid item xs={12}>
                                                                <input type="text" data-culqi="card[email]" style={{ display: "none" }} value={userReducer.currentUserFront.email}
                                                                    id="card[email]" />
                                                                <Box pb={0.5}>
                                                                    <Typography variant="caption" color="initial">
                                                                        <FormattedMessage
                                                                            id="card_number"
                                                                            defaultMessage="Card number"
                                                                        />
                                                                    </Typography>
                                                                </Box>
                                                                <div class="md-input-wrapper md-input-dense md-input-no-label">
                                                                    <Cleave className="md-input"
                                                                        options={{
                                                                            creditCard: true
                                                                        }}
                                                                        name="number"
                                                                        onChange={handleChangeCard}
                                                                        value={cardInfo.number}
                                                                        data-culqi="card[number]"
                                                                        id="card[number]" />
                                                                    <span class="md-input-bar"></span>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <Box pb={0.5}>
                                                                    <Typography variant="caption" color="initial">
                                                                        CVV
                                                                    </Typography>
                                                                </Box>
                                                                <div class="md-input-wrapper md-input-dense md-input-no-label">
                                                                    <Cleave className="md-input"
                                                                        options={{
                                                                            blocks: [4],
                                                                            numericOnly: true
                                                                        }}
                                                                        name="cvv"
                                                                        onChange={handleChangeCard}
                                                                        value={cardInfo.cvv}
                                                                        data-culqi="card[cvv]" id="card[cvv]" />
                                                                    <span class="md-input-bar"></span>
                                                                </div>
                                                            </Grid>
                                                            <Grid item xs={12} sm={6}>
                                                                <Box pb={0.5}>
                                                                    <Typography variant="caption" color="initial">

                                                                        <FormattedMessage
                                                                            id="card_expiry"
                                                                            defaultMessage="Expiry date (MM/YYYY)"
                                                                        />
                                                                    </Typography>
                                                                </Box>
                                                                <Box display="flex" alignItems="center">
                                                                    <Box><div class="md-input-wrapper md-input-dense md-input-no-label">
                                                                        <Cleave className="md-input" placeholder="MM"
                                                                            options={{
                                                                                date: true,
                                                                                datePattern: ['m']
                                                                            }}
                                                                            name="exp_month"
                                                                            onChange={handleChangeCard}
                                                                            value={cardInfo.exp_month}
                                                                            data-culqi="card[exp_month]"
                                                                            id="card[exp_month]" />
                                                                        <span class="md-input-bar"></span>
                                                                    </div>
                                                                    </Box>
                                                                    <Box px={0.5}>/</Box>
                                                                    <Box>
                                                                        <div class="md-input-wrapper md-input-dense md-input-no-label">
                                                                            <Cleave className="md-input" placeholder="YYYY"
                                                                                options={{
                                                                                    date: true,
                                                                                    datePattern: ['Y']
                                                                                }}
                                                                                name="exp_year"
                                                                                onChange={handleChangeCard}
                                                                                value={cardInfo.exp_year}
                                                                                data-culqi="card[exp_year]"
                                                                                id="card[exp_year]" />
                                                                            <span class="md-input-bar"></span>
                                                                        </div>
                                                                    </Box>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={12} align="center">
                                                                <img style={{ maxWidth: "220px" }} src={cards} alt="" />
                                                            </Grid> */}
                                                        </Grid>
                                                    </Box>
                                                    :
                                                    <Box p={1}>
                                                        <div dangerouslySetInnerHTML={{
                                                            __html:
                                                                userReducer.language == "es" ?
                                                                    item.instructions : item.instructions_en

                                                        }}></div>
                                                    </Box>
                                            )
                                            }
                                        </Box>
                                    </>
                                ))}
                            </>
                        </div>
                    </>
                ) : (
                    <Box>{getPaymentName}</Box>
                )}
                {/* <Box display="flex" justifyContent="space-between" >
                    <Button onClick={() => setCurrentStep(2)} startIcon={<Icon>arrow_back</Icon>}>
                        <FormattedMessage
                            id="checkout_back"
                            defaultMessage="Back"
                        />
                    </Button>
                </Box> */}
            </div>
        </div >

    )
}

export default CheckoutStep3
