/* import ServiceProvider from "./service/service.provider"; */
import CartProvider from "./cart/cart.provider";
import React from 'react';

const Provider = ({ children }) => {
    return (
        <>
            {/* <ServiceProvider> */}
            <CartProvider>
                {children}
            </CartProvider>
            {/* </ServiceProvider >  */}
        </>
    );
}
export default Provider;