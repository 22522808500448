const INITIAL_STATE = {
  products: [],
  productCategories: [],
  loading: false,
  hasMore: false,
  errorMessage: undefined
};

const productsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_START_PRODUCTS":
      return {
        ...state,
        loading: true
      };
    case "FETCH_SUCCESS_PRODUCTS":
      return {
        ...state,
        loading: false
      };
    case "GET_PRODUCTS":
      return {
        ...state,
        products: action.payload,
        hasMore: action.payload.length > 0 ? true : false,
        productCategories: [],
        loading: false
      };
    case "GET_PRODUCTS_PREVIOUS":
      return {
        ...state,
        products: [...state.products, ...action.payload],
        hasMore: action.payload.length > 0 ? true : false,
        loading: false
      };
    case "GET_CATEGORIES":
      return {
        ...state,
        productCategories: action.payload,
        loading: false
      };
    case "FETCH_FAILURE_PRODUCTS":
      return {
        ...state,
        errorMessage: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default productsReducer;