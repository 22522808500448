import React, { lazy, Suspense } from 'react';
import { Route } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Container } from '@material-ui/core';
/* import UIkit from 'uikit'; */

import 'uikit/dist/css/uikit.min.css';
import Routes from './routes.js';
/* Custom Routes */

const ProductInner = lazy(() => import('./ProductInner'));
const BannersInner = lazy(() => import('./BannersInner'));
const PaymentInner = lazy(() => import('./PaymentInner'));
const DeliveryZoneInner = lazy(() => import('./DeliveryZoneInner'));
const RecipesInner = lazy(() => import('./RecipesInner'));
const ProductoresInner = lazy(() => import('./ProductoresInner'));
const OrderInner = lazy(() => import('./OrderInner'));
const ClientInner = lazy(() => import('./ClientInner'));
const UserInner = lazy(() => import('./UserInner'));
const VoucherInner = lazy(() => import('./VoucherInner'));
const AutoDiscountDetail = lazy(() => import('./AutoDiscountDetail'));
const GeneralSettingsDetail = lazy(() => import('./GeneralSettingsDetail'));

const drawerWidth = 256;
const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    paddingBottom: theme.spacing(4),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginBottom: theme.spacing(3)
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: drawerWidth,
  },
}));

const MainContent = ({ openDrawer }) => {
  const classes = useStyles();

  return (
    <main /* id="admin-main" */ className={`${classes.content} ${openDrawer ? classes.contentShift : ""}`}>
      <Container maxWidth="md">
        <Suspense fallback={
          <Box display="flex" justifyContent="center" p={5}>
            <CircularProgress />
          </Box>
        }>
          {/* Menu Routes */}
          {Routes.map((route, key) =>
            <div key={key}>
              <Route path={route.path} component={route.component} />
              {route.submenu &&
                route.submenu.map((inner_route, key) =>
                  <Route key={key} path={inner_route.path} component={inner_route.component} />
                )
              }
            </div>
          )}
          {/* Custom Routes */}
          <Route path='/admin/voucher_detail/:voucherId' component={VoucherInner} />
          <Route path='/admin/client_detail/:userId' component={ClientInner} />
          <Route path='/admin/banners_detail/:id' component={BannersInner} />
          <Route path='/admin/payments_detail/:paymentId' component={PaymentInner} />
          <Route path='/admin/deliveryzone_detail/:id' component={DeliveryZoneInner} />
          <Route path='/admin/recipe_detail/:recipeId' component={RecipesInner} />
          <Route path='/admin/producer_detail/:producerId' component={ProductoresInner} />
          <Route path='/admin/orders_detail/:orderId' component={OrderInner} />
          <Route path='/admin/user_detail/:userId' component={UserInner} />
          <Route path='/admin/product_detail/:id' component={ProductInner} />
          <Route path='/admin/autodiscount_detail/:autoId' component={AutoDiscountDetail} />
          <Route path='/admin/websettings_detail/:id' component={GeneralSettingsDetail} />
        </Suspense>
      </Container>
    </main>
  )
}

export default MainContent;