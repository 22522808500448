import { addItem, removeItem, getCartTotal, updateItemComment } from './cart.utils';

export const INITIAL_STATE = {
    cartItems: [],
    createdOrder: 0,
    subTotal: 0
}
const serviceReducer = (state, action) => {
    switch (action.type) {
        case "ADD_ITEM":
            return {
                ...state,
                cartItems: addItem(state.cartItems, action.payload)
            };

        case "UPDATE_ITEM_COMMENT":
            return {
                ...state,
                cartItems: updateItemComment(state.cartItems, action.payload)
            };
        case "SET_LIST":
            return {
                ...state,
                cartItems: action.payload
            };
        case "REMOVE_ITEM":
            return {
                ...state,
                cartItems: removeItem(state.cartItems, action.payload)
            };
        case "SET_CREATED_ORDER":
            return {
                ...state,
                createdOrder: action.payload
            };
        case "RESET_CREATED_ORDER":
            return {
                ...state,
                createdOrder: action.payload
            };
        case "GET_TOTAL_PRICE":
            return {
                ...state,
                subTotal: getCartTotal(state.cartItems)
            };
        case "RESET_LIST":
            return {
                ...state,
                cartItems: []
            };
        default:
            return state;
    }
}
export default serviceReducer;