const INITIAL_STATE = {
  zones: [],
  loading: false,
  errorMessage: undefined
};

const zonesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_START":
      return {
        ...state,
        loading: true
      };
    case "FETCH_SUCCESS":
      return {
        ...state,
        loading: false
      };
    case "GET_ZONES":
      return {
        ...state,
        zones: action.payload,
        loading: false
      };
    case "GET_ZONES_FRONT":
      return {
        ...state,
        zones: action.payload,
        loading: false
      };
    case "FETCH_FAILURE":
      return {
        ...state,
        errorMessage: action.payload,
        loading: false
      };
    default:
      return state;
  }
};

export default zonesReducer;