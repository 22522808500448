import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';

import { Link, useHistory } from "react-router-dom";
import ContentBefore from './components/ContentBefore';
import GeneralContainer from './components/GeneralContainer';

import { Box, CircularProgress, Paper, Grid, InputBase, InputAdornment, Icon, Typography, TextField, Chip, Button, MenuItem } from '@material-ui/core';
import GeneralRow from './components/utils/GeneralRow';
import ResponsiveMenuButton from './components/utils/ResponsiveMenuButton';
import OrderItems from './components/blocks/Orders/OrderItems';

import { useDispatch, useSelector } from "react-redux";
import ordersActions from '../../redux/orders/orders.actions';
import { amber, blue, blueGrey, green } from '@material-ui/core/colors';
import SearchInput from './components/utils/SearchInput';
import HasMore from './components/utils/HasMore';

const ApplicantHistoryPage = () => {

    const orders = useSelector(state => state.ordersReducer.orders);
    const loading = useSelector(state => state.ordersReducer.loading);
    const hasMore = useSelector(state => state.ordersReducer.hasMore);

    const [page, setPage] = useState(1)
    const [querySearch, setQuerySearch] = useState("")

    const getActionName = "GET_ORDERS";
    const getActionPreviousName = "GET_ORDERS_PREVIOUS";

    useEffect(() => {
        if (page !== 1) {
            console.log("changing page...")
            dispatch(ordersActions.getOrders(getActionPreviousName, querySearch, page));
        }
    }, [page])

    const dispatch = useDispatch();
    const history = useHistory();

    const states = [
        { id: 1, name: "Recibido", color: blue[500], icon: "" },
        { id: 2, name: "Confirmado", color: amber[500], icon: "" },
        { id: 3, name: "Enviado", color: green[500], icon: "" },
        { id: 4, name: "Entregado", color: blueGrey[500], icon: "" },
        { id: 5, name: "Cancelado", color: blueGrey[500], icon: "" },
    ]

    const dispatchFromSearch = async () => {
        setPage(1);
        await dispatch(ordersActions.getOrders(getActionName, querySearch, 1));
    }
    return (
        <>
            <ContentBefore title="Ordenes" />
            <GeneralContainer>
                <Paper style={{ marginBottom: "16px" }}>
                    <Box p={1} display="flex">
                        <SearchInput
                            placeholder="Buscar por email"
                            setPage={setPage}
                            querySearch={querySearch}
                            setQuerySearch={setQuerySearch}
                            dispatchFromSearch={dispatchFromSearch} />

                        {/* <Box ml={1} width={180} >
                                        <TextField
                                            fullWidth
                                            size="small"
                                            select
                                            name="unit"
                                            label="Orden:"
                                            
                                            variant="outlined"
                                        >
                                            <MenuItem value={"KG"}>A - Z</MenuItem>
                                            <MenuItem value={"LT"}>Z - A</MenuItem>
                                        </TextField>
                                    </Box> */}
                    </Box>
                </Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper>
                            {
                                orders.length > 0 ?
                                    <>
                                        {orders.map((item, index) => (
                                            <GeneralRow key={index}>
                                                <Box display="flex" alignItems="center">
                                                    <Box flexGrow={1}>
                                                        <Box display="flex">
                                                            <Box flexGrow={1}>
                                                                <Typography component={Link} to={`/admin/orders_detail/${item.orderId}`} variant="h6">
                                                                    <Box color="text.primary">#{item.refNumber} - {item.created_date.getDate() + "/" + (item.created_date.getMonth() + 1) + "/" + item.created_date.getFullYear()}</Box>
                                                                </Typography>
                                                                <Typography component="span" variant="body1">
                                                                    <Box fontWeight="fontWeightBold" color={states[item.stateId - 1].color}>{states[item.stateId - 1].name}</Box>
                                                                </Typography>
                                                                <Typography component="span" variant="body1">
                                                                    <Box fontWeight="fontWeightBold" >{item.firstName + " " + item.lastName + " - " + item.email}</Box>
                                                                </Typography>
                                                                <Box display="flex" alignItems="center">
                                                                    <Icon>account_balance_wallet</Icon>
                                                                    <Box ml={1}>
                                                                        <Typography variant="body2" color="initial">{item.paymentName}</Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                            <Box>
                                                                <Typography variant="h6" color="initial">
                                                                    S/. {item.totalPrice}
                                                                </Typography>
                                                            </Box>
                                                        </Box>
                                                        <Box>
                                                            <OrderItems items={item.items} />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </GeneralRow>
                                        ))}
                                        <HasMore
                                            loading={loading}
                                            setPage={setPage}
                                            hasMore={hasMore}
                                        />
                                    </>
                                    :
                                    !loading &&
                                    <Box display="flex" justifyContent="center" p={5}>
                                        <Typography variant="subtitle1" color="initial">No se encontraron resultados</Typography>
                                    </Box>
                            }
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        {loading &&
                            <Box display="flex" justifyContent="center" p={5}>
                                <CircularProgress />
                            </Box>
                        }
                    </Grid>
                </Grid>

            </GeneralContainer>
        </>
    );
}
export default ApplicantHistoryPage;