import { Box, makeStyles } from '@material-ui/core';
import React from 'react'

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative",
        zIndex: 2,
    },
}));

const GeneralContainer = ({ children }) => {
    const classes = useStyles();
    return (
        <Box className={classes.root}>
            {children}
        </Box>
    )
}

export default GeneralContainer
