import { Box, Button, Paper, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@material-ui/core'
import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import reportsActions from '../../../../redux/reports/reports.actions';

import settings from '../../../../settings.js';

const useStyles = makeStyles({
    table: {
        /* minWidth: 650, */
    },
});

const ClientGeneralReport = ({ dataResult, dateFrom, dateTo }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    return (
        <>
            <Box pb={1} pt={2} display="flex" justifyContent="flex-end">
                <Button size="small" variant="outlined" color="primary" href={`${settings.connectionString}/reports/clientsGeneral.php`}>
                    Exportar a excel</Button>

            </Box>
            <Paper variant="outlined" >
                <Box overflow="auto">
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Nombres y Apellidos</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Fecha de nacimiento</TableCell>
                                <TableCell >Telefono</TableCell>
                                <TableCell >Estado</TableCell>
                                <TableCell align="right">Total de ordenes</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {dataResult.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell >{row.firstName + " " + row.lastName}</TableCell>
                                    <TableCell >{row.email}</TableCell>
                                    <TableCell >{row.dob}</TableCell>
                                    <TableCell >{row.phone}</TableCell>
                                    <TableCell >{row.status ? "Activo" : "Inactivo"}</TableCell>
                                    <TableCell >{row.totalOrders}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            </Paper>
        </>
    )
}

export default ClientGeneralReport
